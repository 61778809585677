import { CampaignRewardSummary, ReviewSummaryOptions } from '@sasagase/types';
import ja from 'date-fns/locale/ja';
import dayjs from 'dayjs';
import * as React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from 'react-hook-form';
import { getReviewSummary, getReviewSummaryCsv } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import FormErrorMessage from '../FormErrorMessage';
import { saveCSVFile } from '../saveCSVFile';
registerLocale('ja', ja);

interface FormValues {
	periodType: 'period' | 'month' | 'day';
	selectPeriod: string;
	monthFrom: string;
	monthTo: string;
	dayFrom: string;
	dayTo: string;
}

interface AcquisitionProps { }
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Acquisition(props: AcquisitionProps): React.ReactElement {
	const { register, handleSubmit, getValues, errors, control, watch } = useForm<FormValues>({
		defaultValues: {
			periodType: 'period',
			selectPeriod: '12',
		},
	});
	const [state] = useAppState();
	const callAPI = useAPI();
	const [summary, setSummary] = React.useState<null | CampaignRewardSummary[]>(null);
	const [loading, setLoading] = React.useState(false);
	const [csvDownloading, setCsvDownloading] = React.useState(false);

	const periodType = watch('periodType');

	React.useEffect(() => {
		void handleClickTerm();
	}, []);

	const generateOptions = (values: FormValues, periodType: string): ReviewSummaryOptions => {
		const opt: ReviewSummaryOptions = {};
		if (periodType === 'period') {
			opt.selectPeriod = values.selectPeriod;
		}
		if (periodType === 'month') {
			opt.from = values.monthFrom ? dayjs(values.monthFrom).format('YYYY-MM-DD') : undefined;
			opt.to = values.monthTo ? dayjs(values.monthTo).format('YYYY-MM-DD') : undefined;
		} else if (periodType === 'day') {
			opt.from = values.dayFrom ? dayjs(values.dayFrom).format('YYYY-MM-DD') : undefined;
			opt.to = values.dayTo ? dayjs(values.dayTo).format('YYYY-MM-DD') : undefined;
		}
		return opt;
	};

	// レビュー獲得件数の取得
	const handleClickTerm = handleSubmit(async (values: FormValues) => {
		if (!periodType || loading) {
			return;
		}

		setLoading(true);
		const opt = generateOptions(values, periodType);
		callAPI(
			getReviewSummary(state.params.shopId, periodType, opt),
			(err, result) => {
				setLoading(false);
				if (err) {
					console.error('Error fetching review summary:', err);
					return;
				}
				setSummary(result.data);
			}
		);
	});

	const handleTransact = handleSubmit(async (values: FormValues) => {
		if (!periodType || csvDownloading) {
			return;
		}

		setCsvDownloading(true);
		const opt = generateOptions(values, periodType);

		let result;
		try {
			result = await callAPI(getReviewSummaryCsv(state.params.shopId, periodType, opt));
			setCsvDownloading(false);
		} catch (err) {
			setCsvDownloading(false);
			alert(`CSVのダウンロードに失敗しました`);
			return;
		}
		await saveCSVFile(result.data);
	});

	// 特典選択割合
	const getRate = (selectedNum: number, orderNum: number) => {
		return selectedNum > 0 && orderNum > 0 ? ((selectedNum / orderNum) * 100).toFixed(2) : 0;
	};

	return (
		<>
			<div>
				<h1 className="el_pageTtl">レビュー獲得件数</h1>
				<p className="el_pageDesc">指定した期間内でキャンペーンごとのレビュー数や対象注文数、特典未選択数を確認でき、特典ごとの選択数や選択割合も把握できます。</p>
				<div className="bl_row">
					<div className="bl_col bl_col__12 bl_col__mobileFullWidth">
						<div className="bl_panel bl_panel__bt">
							<div className="bl_panel_row">
								<h2 className="el_lv3Heading">対象の期間設定</h2>
								<label className="bl_label">
									<input type="radio" name="periodType" value="period" ref={register} /><span>表示期間</span>
								</label>
								<br />
								{periodType === 'period' && (
									<div className="bl_panel_row bl_panel_row__indent">
										<select name="selectPeriod" ref={register}>
											{[1, 3, 6, 12, 24].map(month =>
												<option key={month} value={month}>{month}ヶ月</option>
											)}
											<option value="99">全期間</option>
										</select>
									</div>
								)}
								<label className="bl_label">
									<input type="radio" name="periodType" value="month" ref={register} /><span>年月で期間を指定</span>
								</label>
								<br />
								{periodType === 'month' && (
									<>
										<div className="bl_inputFormWrap ml_26">
											<span>開始日</span>
											<Controller
												control={control}
												rules={{
													validate: (date) => {
														return (periodType != 'month' || date) || '開始日を入力してください';
													}
												}}
												name="monthFrom"
												render={({ onChange, value }) => (
													<ReactDatePicker
														popperClassName="datePickerPopper"
														locale="ja"
														onChange={onChange}
														selected={value}
														dateFormat="yyyy/MM"
														shouldCloseOnSelect={true}
														inline={false}
														showMonthYearPicker />
												)} />
											<FormErrorMessage errors={errors} name="monthFrom" />
										</div>
										<div className="bl_inputFormWrap ml_26">
											<span>終了日</span>
											<Controller
												control={control}
												rules={{
													validate: (date) => {
														if (periodType !== 'month') return true;
														if (!date) return '終了日を入力してください';
														const start = dayjs(getValues('monthFrom'));
														const end = dayjs(date);
														if (start.isAfter(end)) {
															return '終了日は開始日以降の日付を指定してください';
														}

														return true;
													}
												}}
												name="monthTo"
												render={({ onChange, value }) => (
													<ReactDatePicker
														popperClassName="datePickerPopper"
														locale="ja"
														onChange={onChange}
														selected={value}
														dateFormat="yyyy/MM"
														shouldCloseOnSelect={true}
														inline={false}
														showMonthYearPicker />
												)} />
											<FormErrorMessage errors={errors} name="monthTo" />
										</div>
									</>
								)}
								<label className="bl_label">
									<input type="radio" name="periodType" value="day" ref={register} /><span>年月日で期間を指定</span>
								</label>
								<br />
								{periodType === 'day' && (
									<>
										<div className="bl_inputFormWrap ml_26 mr_26">
											<span>開始日</span>
											<Controller
												control={control}
												rules={{
													validate: (date) => {
														return (periodType != 'day' || date) || '開始日を入力してください';
													}
												}}
												name="dayFrom"
												render={({ onChange, value }) => (
													<ReactDatePicker
														popperClassName="datePickerPopper"
														locale="ja"
														onChange={onChange}
														selected={value}
														dateFormat="yyyy/MM/dd"
														shouldCloseOnSelect={true}
														inline={false}
													/>
												)} />
											<FormErrorMessage errors={errors} name="dayFrom" />
										</div>
										<div className="bl_inputFormWrap ml_26">
											<span>終了日</span>
											<Controller
												control={control}
												rules={{
													validate: (date) => {
														if (periodType !== 'day') return true;
														if (!date) return '終了日を入力してください';
														const startDate = getValues('dayFrom');
														if (startDate) {
															const start = dayjs(startDate);
															const end = dayjs(date);
															if (start.isAfter(end)) {
																return '終了日は開始日以降の日付を指定してください';
															}

															// 期間が31日を超える場合のエラー
															const daysBetween = end.diff(start, 'days') + 1;
															if (daysBetween > 31) {
																return '期間は31日以内で指定してください';
															}
														}
														return true;
													}
												}}
												name="dayTo"
												render={({ onChange, value }) => (
													<ReactDatePicker
														popperClassName="datePickerPopper"
														locale="ja"
														onChange={onChange}
														selected={value}
														dateFormat="yyyy/MM/dd"
														shouldCloseOnSelect={true}
														inline={false}
													/>
												)} />
											<FormErrorMessage errors={errors} name="dayTo" />
										</div>
									</>
								)}
							</div>
							<div className="bl_panel_headerFooter">
								<button className="el_btnInv" type="button" onClick={handleClickTerm} disabled={loading}>{loading ? 'データ取得中' : '設定'}</button>
								<button className="el_btn mr_8" type="button" onClick={handleTransact} disabled={csvDownloading}>{csvDownloading ? 'ダウンロード中' : 'CSVダウンロード'}</button>
							</div>
						</div>
					</div>
				</div>
				{summary && summary.map(campaign => (
					<div className="bl_row" key={campaign.campaignId}>
						<div className="bl_col bl_col__12">
							<div className="bl_panel ">
								<div key={campaign.campaignId} className="bl_panel_row">
									<h2 className="el_panel_ttlTop el_panel_ttlTop__simple">{campaign.campaignName}</h2>
									<ul className="bl_reviewData mb_24">
										<li className="bl_reviewData_item">
											<h3 className="bl_reviewData_heading">商品レビュー数</h3>
											<p><span>{campaign.itemReviewed.toLocaleString()}件</span></p>
										</li>
										<li className="bl_reviewData_item">
											<h3 className="bl_reviewData_heading">ショップレビュー数</h3>
											<p><span>{campaign.shopReviewed.toLocaleString()}件</span></p>
										</li>
										<li className="bl_reviewData_item">
											<h3 className="bl_reviewData_heading">対象注文数</h3>
											<p><span>{campaign.orderNum.toLocaleString()}件</span></p>
										</li>
										<li className="bl_reviewData_item">
											<h3 className="bl_reviewData_heading">特典未選択数</h3>
											<p><span>{(campaign.orderNum - campaign.selectedTotal).toLocaleString()}件</span></p>
										</li>
									</ul>
									<table className="bl_table bl_analysisTable">
										<thead className="bl_table_head">
											<tr>
												<th>特典名</th>
												<th>特典選択数</th>
												<th>特典選択割合</th>
											</tr>
										</thead>
										<tbody className="bl_table_body">
											{campaign.rewards.map((reward, idx) => (
												<tr key={idx}>
													<td>{reward.rewardName}</td>
													<td>{reward.selectedNum.toLocaleString()}件</td>
													<td>{getRate(reward.selectedNum, campaign.orderNum)}%</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
}
export default Acquisition;
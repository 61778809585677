import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SideDrawerItem from './SideDrawerItem';
import { SideElement } from './SideElement';

interface SideProps {
	isOpen: boolean;
	isOpenMobile: boolean;
	basePath: string;
	onClose?: () => void;
}

export const PAGE_TITLES = new Map([
	["/reward/target", "レビュー待ち状況"],
	["/reward/request", "特典申込受付"],
	["/reward/reward", "レビュー特典登録"],
	["/reward/group", "対象商品グループ登録"],
	["/reward/campaign", "キャンペーン登録"],
	["/mail/setting", "メール送信設定"],
	["/mail/template", "送信メールテンプレート"],
	["/mail/not-covered", "特典対象外商品用メール"],
	["/mail/exclusion", "メール送信除外設定"],
	["/analysis/acquisition", "レビュー獲得件数"],
	["/analysis/reception-rate", "レビュー特典申込割合"],
	["/analysis/post-rate", "全体レビュー投稿率"],
	["/analysis/by-time", "レビュー投稿時間帯"],
	["/analysis/by-case", "キャンペーン別投稿率"],
	["/account/account-setting", "ユーザーアカウント設定"],
	["/account/rakuten-setting", "楽天システム設定"],
	["/account/shop-setting", "店舗設定"],
	["/account/system-alert", "システムアラート設定"],
	["/management", "ユーザー管理"]
]);

export const NOT_LOGIN_PAGE_TITLES = new Map([
	["/account/recover", "パスワード再設定"],
]);

function Side(props: SideProps): React.ReactElement {
	const classNameIsOpen = (props.isOpen ? ' is_open' : '') + (props.isOpenMobile ? ' is_open_mobile' : '');

	const setCapMap: { [index: string]: any } = {
		'privilege': ['review_reviewCheckTool_read', 'review_reviewWaiting_read', 'review_rewardApplying_read', 'review_rewardRegister_read', 'review_itemGroup_read', 'review_campaignRegister_read'],
		'reviewCheckTool_r': ['review_reviewCheckTool_read'],
		'reviewWaiting_r': ['review_reviewWaiting_read'],
		'rewardApplying_r': ['review_rewardApplying_read'],
		'rewardRegister_r': ['review_rewardRegister_read'],
		'itemGroup_r': ['review_itemGroup_read'],
		'campaignRegister_r': ['review_campaignRegister_read'],
		'mail': ['review_mailSetting_read', 'review_mailTemplate_read', 'review_mailExclude_read'],
		'mailSetting_r': ['review_mailSetting_read'],
		'mailTemplate_r': ['review_mailTemplate_read'],
		'mailExclude_r': ['review_mailExclude_read'],
		'setting': ['review_userSetting_read', 'review_shopSetting_read'],
		'userSetting_r': ['review_userSetting_read'],
		'shopSetting_r': ['review_shopSetting_read'],
		'systemAlert_r': ['review_userSetting_read', 'review_shopSetting_read'],
		'analysis': ['review_analysis_read'],
	}

	const [open, setOpen] = React.useState('');

	React.useEffect(() => {
		const docClickHandler = (ev: MouseEvent) => {
			if (!(ev.target instanceof HTMLElement)) {
				return;
			}
			let target = ev.target;
			let hasClass = target.classList.contains('menu');

			/*
				クリックイベントの範囲の判定
				hasClass == true
					・バーガーメニュー
					・サイドメニュー（サイドメニュー内のリストは除く）
			*/
			while (!hasClass && target.parentNode instanceof HTMLElement) {
				target = target.parentNode;

				if (target === null || target.parentNode === null) {
					break;
				} else {
					if (target.classList.contains('bl_drawer_subList')) {
						break;
					}
					hasClass = target.classList.contains('menu');
				}
			}

			// バーガーメニュー開閉時のメニューの動き
			if (ev.target.classList.contains('el_navToggle')) {
				if (props.isOpen) {
					setOpen('');
				}
			}
			if(!hasClass) {
				if (!props.isOpen) {
					setOpen('');
				}
				props.onClose?.();
			}
		};
		document.body.addEventListener('click', docClickHandler);
		return () => document.body.removeEventListener('click', docClickHandler);
	}, [props.isOpen]);

	const navigate = useNavigate();

	const handleClickMenu = (name: string) => () => {
		if (name === 'top') {
			// PC版：ハンバーガーメニューが閉じている状態でTOPをクリック
			if (!props.isOpen) {
				setOpen('');
			}
			// モバイル版：ハンバーガーメニューが開いている状態でTOPをクリック
			if (props.isOpenMobile) {
				props.onClose?.();
			}

			navigate(props.basePath);
			return;
		}

		if (open === name) {
			setOpen('');
		} else {
			setOpen(name);
		}
	}

	return (
		<aside className="ly_side">
			<ul className={"bl_drawer menu" + classNameIsOpen}>
				<SideDrawerItem className="bl_drawer_item__top" title="TOP" onClickMenu={ handleClickMenu('top') }/>
				<SideElement caps={ setCapMap.privilege }><SideDrawerItem className="bl_drawer_item__privilege" title="レビュー特典" isOpen={(open === 'privilege')} onClickMenu={ handleClickMenu('privilege') }>
					<ul className="bl_drawer_subList">
						<SideElement caps={ setCapMap.reviewCheckTool_r }><li><a href="https://review.rms.rakuten.co.jp" target="_blank" rel="noreferrer">レビューチェックツール</a></li></SideElement>
						<SideElement caps={ setCapMap.reviewWaiting_r }><li><Link to={props.basePath + '/reward/target'}>{PAGE_TITLES.get('/reward/target')}</Link></li></SideElement>
						<SideElement caps={ setCapMap.rewardApplying_r }><li><Link to={props.basePath + '/reward/request'}>{PAGE_TITLES.get('/reward/request')}</Link></li></SideElement>
						<SideElement caps={ setCapMap.rewardRegister_r }><li><Link to={props.basePath + '/reward/reward'}>{PAGE_TITLES.get('/reward/reward')}</Link></li></SideElement>
						<SideElement caps={ setCapMap.itemGroup_r }><li><Link to={props.basePath + '/reward/group'}>{PAGE_TITLES.get('/reward/group')}</Link></li></SideElement>
						<SideElement caps={ setCapMap.campaignRegister_r }><li><Link to={props.basePath + '/reward/campaign'}>{PAGE_TITLES.get('/reward/campaign')}</Link></li></SideElement>
					</ul>
				</SideDrawerItem></SideElement>
				<SideElement caps={ setCapMap.mail }><SideDrawerItem className="bl_drawer_item__mail" title="メール設定" isOpen={(open === 'mail')} onClickMenu={ handleClickMenu('mail') }>
					<ul className="bl_drawer_subList">
						<SideElement caps={ setCapMap.mailSetting_r }><li><Link to={props.basePath + '/mail/setting'}>{PAGE_TITLES.get('/mail/setting')}</Link></li></SideElement>
						<SideElement caps={ setCapMap.mailTemplate_r }><li><Link to={props.basePath + '/mail/template'}>{PAGE_TITLES.get('/mail/template')}</Link></li></SideElement>
						<SideElement caps={ setCapMap.mailSetting_r }><li><Link to={props.basePath + '/mail/not-covered'}>{PAGE_TITLES.get('/mail/not-covered')}</Link></li></SideElement>
						<SideElement caps={ setCapMap.mailExclude_r }><li><Link to={props.basePath + '/mail/exclusion'}>{PAGE_TITLES.get('/mail/exclusion')}</Link></li></SideElement>
					</ul>
				</SideDrawerItem></SideElement>
				<SideElement caps={ setCapMap.analysis }><SideDrawerItem className="bl_drawer_item__analysis" title="レビュー分析" isOpen={(open === 'analysis')} onClickMenu={ handleClickMenu('analysis') }>
					<ul className="bl_drawer_subList">
						<SideElement caps={ setCapMap.analysis }><li><Link to={props.basePath + '/analysis/acquisition'}>{PAGE_TITLES.get('/analysis/acquisition')}</Link></li></SideElement>
						<SideElement caps={ setCapMap.analysis }><li><Link to={props.basePath + '/analysis/post-rate'}>{PAGE_TITLES.get('/analysis/post-rate')}</Link></li></SideElement>
						{/* <li><Link to={props.basePath + '/analysis/reception-rate'}>{PAGE_TITLES.get('/analysis/reception-rate')}</Link></li>
						<li><Link to={props.basePath + '/analysis/by-time'}>{PAGE_TITLES.get('/analysis/by-time')}</Link></li>
						<li><Link to={props.basePath + '/analysis/by-case'}>{PAGE_TITLES.get('/analysis/by-case')}</Link></li> */}
					</ul>
				</SideDrawerItem></SideElement>
				<SideElement caps={ setCapMap.setting }><SideDrawerItem className="bl_drawer_item__setting" title="各種設定" isOpen={(open === 'setting')} onClickMenu={ handleClickMenu('setting') }>
					<ul className="bl_drawer_subList">
						<SideElement caps={ setCapMap.userSetting_r }><li><Link to={props.basePath + '/account/account-setting'}>{PAGE_TITLES.get('/account/account-setting')}</Link></li></SideElement>
						<SideElement caps={ setCapMap.shopSetting_r }><li><Link to={props.basePath + '/account/rakuten-setting'}>{PAGE_TITLES.get('/account/rakuten-setting')}</Link></li></SideElement>
						<SideElement caps={ setCapMap.shopSetting_r }><li><Link to={props.basePath + '/account/shop-setting'}>{PAGE_TITLES.get('/account/shop-setting')}</Link></li></SideElement>
						<SideElement caps={ setCapMap.systemAlert_r }><li><Link to={props.basePath + '/account/system-alert'}>{PAGE_TITLES.get('/account/system-alert')}</Link></li></SideElement>
					</ul>
				</SideDrawerItem></SideElement>
			</ul>
		</aside>
	);
}
export default Side;
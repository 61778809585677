import * as React from 'react';
import MarkDown from 'react-markdown';
import { Link } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface GuideNavigationProps {
	path: string,
	paramPath: string,
	folderPath: string,
	isMarkdown: boolean,
}
export function GuideNavigation(props: GuideNavigationProps): React.ReactElement {
	const [isNaviTopActive, setIsNaviTopActive] = React.useState<boolean>(true);
	const [isSettingActive, setIsSettingActive] = React.useState<boolean>(true);
	const [isUseGuideActive, setIsUseGuideActive] = React.useState<boolean>(true);

	const togleChange = (value: boolean, setFunc: (setVal:boolean) => void) => {
		setFunc(!value);
	}

	return (
		<div className="bl_panel bl_panel__bt bl_panel__guide_menu">
			<ul className="bl_guideNav">
				<li className={isNaviTopActive ? "is_active" : ""}>
					<p className="bl_guideNav_title" onClick={() => togleChange(isNaviTopActive, setIsNaviTopActive)}>利用ガイドTOP</p>
					<ul className="bl_guideNav_list">
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/use_guide/top"} className={"use_guide/top" == props.paramPath ? 'selectGuide' : ''}>TOP</Link>
							<GuideNavigationIndex naviPath='use_guide/top' {...props} />
						</li>
					</ul>
				</li>
				<li className={isSettingActive ? "is_active" : ""}>
					<p className="bl_guideNav_title" onClick={() => togleChange(isSettingActive, setIsSettingActive)}>登録・設定方法</p>
					<ul className="bl_guideNav_list">
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/use_flow"} className={"registration_setting/use_flow" == props.paramPath ? 'selectGuide' : ''}>はじめての方へ</Link>
							<GuideNavigationIndex naviPath='registration_setting/use_flow' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/preparation"} className={"registration_setting/preparation" == props.paramPath ? 'selectGuide' : ''}>事前準備</Link>
							<GuideNavigationIndex naviPath='registration_setting/preparation' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/rakuten_system_setting"} className={"registration_setting/rakuten_system_setting" == props.paramPath ? 'selectGuide' : ''}>楽天システム設定</Link>
							<GuideNavigationIndex naviPath='registration_setting/rakuten_system_setting' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/shop_setting"} className={"registration_setting/shop_setting" == props.paramPath ? 'selectGuide' : ''}>店舗設定</Link>
							<GuideNavigationIndex naviPath='registration_setting/shop_setting' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/mail_setting"} className={"registration_setting/mail_setting" == props.paramPath ? 'selectGuide' : ''}>メール設定</Link>
							<GuideNavigationIndex naviPath='registration_setting/mail_setting' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/from_review_privilege_up_to_campaign_register"} className={"registration_setting/from_review_privilege_up_to_campaign_register" == props.paramPath ? 'selectGuide' : ''}>レビュー特典〜キャンペーン登録</Link>
							<GuideNavigationIndex naviPath='registration_setting/from_review_privilege_up_to_campaign_register' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/user_account_setting"} className={"registration_setting/user_account_setting" == props.paramPath ? 'selectGuide' : ''}>ユーザーアカウント設定</Link>
							<GuideNavigationIndex naviPath='registration_setting/user_account_setting' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/password_setting"} className={"registration_setting/password_setting" == props.paramPath ? 'selectGuide' : ''}>パスワード設定</Link>
							<GuideNavigationIndex naviPath='registration_setting/password_setting' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/system_alert"} className={"registration_setting/system_alert" == props.paramPath ? 'selectGuide' : ''}>システムアラート設定</Link>
							<GuideNavigationIndex naviPath='registration_setting/system_alert' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/registration_setting/two_factor_auth"} className={"registration_setting/two_factor_auth" == props.paramPath ? 'selectGuide' : ''}>2段階認証設定</Link>
							<GuideNavigationIndex naviPath='registration_setting/two_factor_auth' {...props} />
						</li>
					</ul>
				</li>
				<li className={isUseGuideActive ? "is_active" : ""}>
					<p className="bl_guideNav_title" onClick={() => togleChange(isUseGuideActive, setIsUseGuideActive)}>操作ガイド</p>
					<ul className="bl_guideNav_list">
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/operation/top"} className={"operation/top" == props.paramPath ? 'selectGuide' : ''}>TOP</Link>
							<GuideNavigationIndex naviPath='operation/top' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/operation/review_privilege"} className={"operation/review_privilege" == props.paramPath ? 'selectGuide' : ''}>レビュー特典</Link>
							<GuideNavigationIndex naviPath='operation/review_privilege' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/operation/mail_setting"} className={"operation/mail_setting" == props.paramPath ? 'selectGuide' : ''}>メール設定</Link>
							<GuideNavigationIndex naviPath='operation/mail_setting' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/operation/analysis"} className={"operation/analysis" == props.paramPath ? 'selectGuide' : ''}>レビュー分析</Link>
							<GuideNavigationIndex naviPath='operation/analysis' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/operation/various_setup"} className={"operation/various_setup" == props.paramPath ? 'selectGuide' : ''}>各種設定</Link>
							<GuideNavigationIndex naviPath='operation/various_setup' {...props} />
						</li>
						<li className="bl_guideNav_item">
							<Link to={props.folderPath + "/operation/csv_format"} className={"operation/csv_format" == props.paramPath ? 'selectGuide' : ''}>送り先CSV形式</Link>
							<GuideNavigationIndex naviPath='operation/csv_format' {...props} />
						</li>
					</ul>
				</li>
			</ul>

			{/* <a className="el_btnWideBorderInv" href={props.folderPath + "/top"}>よくあるご質問</a> */}
		</div>
	);
}

interface GuideNavigationIndexProps extends GuideNavigationProps {
	naviPath: string;
}

function GuideNavigationIndex(props: GuideNavigationIndexProps): React.ReactElement {
	if (!props.isMarkdown || props.naviPath != props.paramPath) {
		return <></>;
	}

	const [md, setMd] = React.useState('');

	React.useEffect(() => {
		async function getMd() {
			const res = await fetch(props.path);
			return res.text();
		}
		void getMd()
			.then(md => {
				setMd(md);
			});
	}, [props.path]);

	const modifyH3Element = React.useCallback(({ children }: {children?: React.ReactNode}) => {
		const el = React.Children.map(children, (child) => {
			return <li className="bl_guideNav_item_index index_h3"><Link to={props.folderPath + "/" + props.naviPath + "#" + child?.toString()}>{child}</Link></li>
		});

		return <>{el}</>
	}, []);

	const modifyH4Element = React.useCallback(({ children }: {children?: React.ReactNode}) => {
		const el = React.Children.map(children, (child) => {
			return <li className="bl_guideNav_item_index index_h4"><Link to={props.folderPath + "/" + props.naviPath + "#" + child?.toString()}>{child}</Link></li>
		});

		return <>{el}</>
	}, []);

	return (
		<ul>
			<MarkDown
				rehypePlugins={[rehypeRaw]}
				allowedElements={['h3', 'h4']}
				components={{
					h3: modifyH3Element,
					h4: modifyH4Element,
				}}>{md}</MarkDown>
		</ul>
	);
}
export default GuideNavigation;
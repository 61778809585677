interface KamonInfo {
	id: string;
	type: 'illust' | 'kamon' | 'maru' | 'koi';
	name: string;
}

export const KAMON_INFO: readonly KamonInfo[] = [
	{ type: 'koi', id: 'ky', name: '矢車' },
	{ type: 'maru', id: 'ふじさん', name: '丸型ふじさん' },
	{ type: 'maru', id: 'こいのぼり', name: '丸型こいのぼり' },
	{ type: 'maru', id: 'かぶと', name: '丸型かぶと' },
	{ type: 'maru', id: '桜道', name: '丸型桜道' },
	{ type: 'maru', id: '小桜', name: '丸型小桜' },
	{ type: 'illust', id: 'A', name: '子' },
	{ type: 'illust', id: 'B', name: '丑' },
	{ type: 'illust', id: 'C', name: '寅' },
	{ type: 'illust', id: 'D', name: '卯' },
	{ type: 'illust', id: 'E', name: '辰' },
	{ type: 'illust', id: 'F', name: '巳' },
	{ type: 'illust', id: 'G', name: '午' },
	{ type: 'illust', id: 'H', name: '未' },
	{ type: 'illust', id: 'I', name: '申' },
	{ type: 'illust', id: 'J', name: '酉' },
	{ type: 'illust', id: 'K', name: '戌' },
	{ type: 'illust', id: 'L', name: '亥' },
	{ type: 'illust', id: 'N', name: '兜' },
	{ type: 'illust', id: 'O', name: '鯉のぼり' },
	{ type: 'illust', id: 'P', name: '桜' },
	{ type: 'illust', id: 'さくら', name: '名前旗さくら' },
	{ type: 'illust', id: 'たんご', name: '名前旗たんご' },
	{ type: 'illust', id: '02', name: 'まる干支-子' },
	{ type: 'illust', id: '03', name: 'まる干支-丑' },
	{ type: 'illust', id: '04', name: 'まる干支-寅' },
	{ type: 'illust', id: '05', name: 'まる干支-卯' },
	{ type: 'illust', id: '06', name: 'まる干支-辰' },
	{ type: 'illust', id: '07', name: 'まる干支-巳' },
	{ type: 'illust', id: '08', name: 'まる干支-午' },
	{ type: 'illust', id: '09', name: 'まる干支-未' },
	{ type: 'illust', id: '10', name: 'まる干支-申' },
	{ type: 'illust', id: '11', name: 'まる干支-酉' },
	{ type: 'illust', id: '12', name: 'まる干支-戌' },
	{ type: 'illust', id: '13', name: 'まる干支-亥' },
	{ type: 'illust', id: '14', name: 'しん干支-子' },
	{ type: 'illust', id: '15', name: 'しん干支-丑' },
	{ type: 'illust', id: '16', name: 'しん干支-寅' },
	{ type: 'illust', id: '17', name: 'しん干支-卯' },
	{ type: 'illust', id: '18', name: 'しん干支-辰' },
	{ type: 'illust', id: '19', name: 'しん干支-巳' },
	{ type: 'illust', id: '20', name: 'しん干支-午' },
	{ type: 'illust', id: '21', name: 'しん干支-未' },
	{ type: 'illust', id: '22', name: 'しん干支-申' },
	{ type: 'illust', id: '23', name: 'しん干支-酉' },
	{ type: 'illust', id: '24', name: 'しん干支-戌' },
	{ type: 'illust', id: '25', name: 'しん干支-亥' },
	{ type: 'illust', id: '26', name: 'イラスト-ひな桜A' },
	{ type: 'illust', id: '27', name: 'イラスト-ひな桜B' },
	{ type: 'illust', id: '28', name: 'イラスト-梅と鶯' },
	{ type: 'illust', id: '29', name: 'イラスト-兜A' },
	{ type: 'illust', id: '30', name: 'イラスト-兜B' },
	{ type: 'illust', id: '31', name: 'イラスト-兜C' },
	{ type: 'illust', id: '32', name: 'イラスト-鯉のぼりA' },
	{ type: 'illust', id: '33', name: 'イラスト-鯉のぼりB' },
	{ type: 'illust', id: '34', name: 'イラスト-鯉のぼりC' },
	{ type: 'illust', id: '35', name: 'イラスト-破魔弓' },
	{ type: 'kamon', "id": "0001", "name": "筥形に総角" },
	{ type: 'kamon', "id": "0002", "name": "総角" },
	{ type: 'kamon', "id": "0003", "name": "赤鳥" },
	{ type: 'kamon', "id": "0004", "name": "今川赤鳥" },
	{ type: 'kamon', "id": "0005", "name": "赤鳥菱" },
	{ type: 'kamon', "id": "0006", "name": "丸に二つ干網" },
	{ type: 'kamon', "id": "0007", "name": "中輪に二つ網干に水" },
	{ type: 'kamon', "id": "0008", "name": "糸輪に三つ網干" },
	{ type: 'kamon', "id": "0009", "name": "陰三つ網目" },
	{ type: 'kamon', "id": "0010", "name": "四つ網目" },
	{ type: 'kamon', "id": "0011", "name": "陰四つ網目菱" },
	{ type: 'kamon', "id": "0012", "name": "徳川葵" },
	{ type: 'kamon', "id": "0013", "name": "蔓一つ葵の丸" },
	{ type: 'kamon', "id": "0014", "name": "右離れ立ち葵" },
	{ type: 'kamon', "id": "0015", "name": "左離れ立ち葵" },
	{ type: 'kamon', "id": "0016", "name": "花立ち葵" },
	{ type: 'kamon', "id": "0017", "name": "丸に右離れ立ち葵" },
	{ type: 'kamon', "id": "0018", "name": "丸に左離れ立ち葵" },
	{ type: 'kamon', "id": "0019", "name": "尻合わせ三つ葵" },
	{ type: 'kamon', "id": "0020", "name": "剣三つ葵" },
	{ type: 'kamon', "id": "0021", "name": "葵桐" },
	{ type: 'kamon', "id": "0022", "name": "五つ裏葵" },
	{ type: 'kamon', "id": "0023", "name": "近江日吉神社" },
	{ type: 'kamon', "id": "0024", "name": "京都加茂御祖神社" },
	{ type: 'kamon', "id": "0025", "name": "京都松尾神社" },
	{ type: 'kamon', "id": "0026", "name": "紀州三つ葵" },
	{ type: 'kamon', "id": "0027", "name": "変わり剣二つ葵" },
	{ type: 'kamon', "id": "0028", "name": "三つ蔓葵の丸" },
	{ type: 'kamon', "id": "0029", "name": "五つ葵" },
	{ type: 'kamon', "id": "0030", "name": "水に立ち葵" },
	{ type: 'kamon', "id": "0031", "name": "東京芝増上寺" },
	{ type: 'kamon', "id": "0032", "name": "会津三つ葵" },
	{ type: 'kamon', "id": "0033", "name": "尾張三つ葵" },
	{ type: 'kamon', "id": "0034", "name": "水戸三つ葵" },
	{ type: 'kamon', "id": "0035", "name": "出羽三つ葵" },
	{ type: 'kamon', "id": "0036", "name": "西条三つ葵" },
	{ type: 'kamon', "id": "0037", "name": "丸に三つ裏葵" },
	{ type: 'kamon', "id": "0038", "name": "細輪に五つ裏葵" },
	{ type: 'kamon', "id": "0039", "name": "二つ割り葵" },
	{ type: 'kamon', "id": "0040", "name": "四つ割り葵" },
	{ type: 'kamon', "id": "0041", "name": "中輪に立ち葵" },
	{ type: 'kamon', "id": "0042", "name": "本多分家立ち葵" },
	{ type: 'kamon', "id": "0043", "name": "石持ち地抜き立ち葵" },
	{ type: 'kamon', "id": "0044", "name": "丸に一つ葵" },
	{ type: 'kamon', "id": "0045", "name": "蔓一つ葵" },
	{ type: 'kamon', "id": "0046", "name": "陰一つ葵" },
	{ type: 'kamon', "id": "0047", "name": "裏葵" },
	{ type: 'kamon', "id": "0048", "name": "糸輪に豆葵" },
	{ type: 'kamon', "id": "0049", "name": "立ち葵菱" },
	{ type: 'kamon', "id": "0050", "name": "立ち葵に水" },
	{ type: 'kamon', "id": "0051", "name": "糸輪に水葵" },
	{ type: 'kamon', "id": "0052", "name": "束ね葵" },
	{ type: 'kamon', "id": "0053", "name": "三つ割り立ち葵" },
	{ type: 'kamon', "id": "0054", "name": "丸に三つ葵" },
	{ type: 'kamon', "id": "0055", "name": "丸に陰三つ葵" },
	{ type: 'kamon', "id": "0056", "name": "守山三つ葵" },
	{ type: 'kamon', "id": "0057", "name": "越前三つ葵" },
	{ type: 'kamon', "id": "0058", "name": "平隈切り鉄砲角に三つ葵" },
	{ type: 'kamon', "id": "0059", "name": "菊輪に三つ葵" },
	{ type: 'kamon', "id": "0060", "name": "三つ軸違い葵" },
	{ type: 'kamon', "id": "0061", "name": "蔓三つ葵" },
	{ type: 'kamon', "id": "0062", "name": "蔓葵片喰" },
	{ type: 'kamon', "id": "0063", "name": "花付き三つ葵" },
	{ type: 'kamon', "id": "0064", "name": "蔓三つ葵に抱き茗荷" },
	{ type: 'kamon', "id": "0065", "name": "片手蔓三つ葵" },
	{ type: 'kamon', "id": "0066", "name": "蔓違い三つ葵" },
	{ type: 'kamon', "id": "0067", "name": "変わり蔓三つ葵" },
	{ type: 'kamon', "id": "0068", "name": "丸に中陰三つ葵" },
	{ type: 'kamon', "id": "0069", "name": "細輪に四つ葵" },
	{ type: 'kamon', "id": "0070", "name": "中輪に四つ剣葵" },
	{ type: 'kamon', "id": "0071", "name": "幼剣五つ葵" },
	{ type: 'kamon', "id": "0072", "name": "五つ葵に檜扇" },
	{ type: 'kamon', "id": "0073", "name": "五つ葵剣葵" },
	{ type: 'kamon', "id": "0074", "name": "花付き五つ葵" },
	{ type: 'kamon', "id": "0075", "name": "水戸六つ葵" },
	{ type: 'kamon', "id": "0076", "name": "紀州六つ葵" },
	{ type: 'kamon', "id": "0077", "name": "高松六つ葵" },
	{ type: 'kamon', "id": "0078", "name": "松平六つ葵" },
	{ type: 'kamon', "id": "0079", "name": "播州六つ葵" },
	{ type: 'kamon', "id": "0080", "name": "津軽六つ葵" },
	{ type: 'kamon', "id": "0081", "name": "六つ葵梅鉢" },
	{ type: 'kamon', "id": "0082", "name": "六つ葵車" },
	{ type: 'kamon', "id": "0083", "name": "二つ割り違い葵" },
	{ type: 'kamon', "id": "0084", "name": "花付き割り葵" },
	{ type: 'kamon', "id": "0085", "name": "浮線葵" },
	{ type: 'kamon', "id": "0086", "name": "変わり浮線葵" },
	{ type: 'kamon', "id": "0087", "name": "三つ割り葵" },
	{ type: 'kamon', "id": "0088", "name": "蔓付き三つ割り葵" },
	{ type: 'kamon', "id": "0089", "name": "二つ剣葵" },
	{ type: 'kamon', "id": "0090", "name": "糸輪に花菱葵" },
	{ type: 'kamon', "id": "0091", "name": "二葉葵" },
	{ type: 'kamon', "id": "0092", "name": "花付き二葉葵" },
	{ type: 'kamon', "id": "0093", "name": "二つ蔓葵丸" },
	{ type: 'kamon', "id": "0094", "name": "変わり葵丸" },
	{ type: 'kamon', "id": "0095", "name": "花付き追い葵" },
	{ type: 'kamon', "id": "0096", "name": "三つ葵の丸" },
	{ type: 'kamon', "id": "0097", "name": "葵の丸" },
	{ type: 'kamon', "id": "0098", "name": "蔓三つ葵の丸" },
	{ type: 'kamon', "id": "0099", "name": "葵の草丸" },
	{ type: 'kamon', "id": "0100", "name": "変わり葵の草丸" },
	{ type: 'kamon', "id": "0101", "name": "向こう花葵" },
	{ type: 'kamon', "id": "0102", "name": "中陰五つ捻じ葵" },
	{ type: 'kamon', "id": "0103", "name": "変わり葵桐" },
	{ type: 'kamon', "id": "0104", "name": "葵蝶" },
	{ type: 'kamon', "id": "0105", "name": "麻の葉" },
	{ type: 'kamon', "id": "0106", "name": "丸に麻の葉" },
	{ type: 'kamon', "id": "0107", "name": "持ち合い麻の葉" },
	{ type: 'kamon', "id": "0108", "name": "麻の葉桔梗" },
	{ type: 'kamon', "id": "0109", "name": "向こう真麻の葉" },
	{ type: 'kamon', "id": "0110", "name": "麻の花" },
	{ type: 'kamon', "id": "0111", "name": "丸に真麻の花" },
	{ type: 'kamon', "id": "0112", "name": "三つ割り麻の葉" },
	{ type: 'kamon', "id": "0113", "name": "丸に麻の葉桐" },
	{ type: 'kamon', "id": "0114", "name": "陰麻の葉" },
	{ type: 'kamon', "id": "0115", "name": "雪輪に麻の葉" },
	{ type: 'kamon', "id": "0116", "name": "三つ麻の葉" },
	{ type: 'kamon', "id": "0117", "name": "外三つ割り麻の葉" },
	{ type: 'kamon', "id": "0118", "name": "麻の葉車" },
	{ type: 'kamon', "id": "0119", "name": "細麻の葉" },
	{ type: 'kamon', "id": "0120", "name": "比翼麻の葉" },
	{ type: 'kamon', "id": "0121", "name": "中輪に一つ朝顔" },
	{ type: 'kamon', "id": "0122", "name": "朝顔の丸" },
	{ type: 'kamon', "id": "0123", "name": "五つ朝顔" },
	{ type: 'kamon', "id": "0124", "name": "細輪に五つ朝顔" },
	{ type: 'kamon', "id": "0125", "name": "竹丸に朝顔" },
	{ type: 'kamon', "id": "0126", "name": "一つ葦の葉" },
	{ type: 'kamon', "id": "0127", "name": "違い葦の葉" },
	{ type: 'kamon', "id": "0128", "name": "石川葦" },
	{ type: 'kamon', "id": "0129", "name": "葦の丸" },
	{ type: 'kamon', "id": "0130", "name": "抱き割り葦" },
	{ type: 'kamon', "id": "0131", "name": "並び葦の葉" },
	{ type: 'kamon', "id": "0132", "name": "三つ葦の葉" },
	{ type: 'kamon', "id": "0133", "name": "新見葦" },
	{ type: 'kamon', "id": "0134", "name": "雪輪に葦に水" },
	{ type: 'kamon', "id": "0135", "name": "糸輪に陰違い葦の葉" },
	{ type: 'kamon', "id": "0136", "name": "三つ割り芭蕉" },
	{ type: 'kamon', "id": "0137", "name": "抱き芭蕉" },
	{ type: 'kamon', "id": "0138", "name": "折れ芭蕉" },
	{ type: 'kamon', "id": "0139", "name": "一つ巴芭蕉" },
	{ type: 'kamon', "id": "0140", "name": "違い芭蕉" },
	{ type: 'kamon', "id": "0141", "name": "三つ芭蕉" },
	{ type: 'kamon', "id": "0142", "name": "三つ巴芭蕉" },
	{ type: 'kamon', "id": "0143", "name": "折れ芭蕉の丸" },
	{ type: 'kamon', "id": "0144", "name": "二つ折れ芭蕉巴" },
	{ type: 'kamon', "id": "0145", "name": "丸に並び芭蕉" },
	{ type: 'kamon', "id": "0146", "name": "三つ抱き芭蕉" },
	{ type: 'kamon', "id": "0147", "name": "三つ追い芭蕉" },
	{ type: 'kamon', "id": "0148", "name": "大割り牡丹" },
	{ type: 'kamon', "id": "0149", "name": "落牡丹" },
	{ type: 'kamon', "id": "0150", "name": "杏葉牡丹" },
	{ type: 'kamon', "id": "0151", "name": "総陰杏葉牡丹" },
	{ type: 'kamon', "id": "0152", "name": "石持ち地抜き杏葉牡丹" },
	{ type: 'kamon', "id": "0153", "name": "抱き牡丹" },
	{ type: 'kamon', "id": "0154", "name": "変わり杏葉牡丹" },
	{ type: 'kamon', "id": "0155", "name": "津軽牡丹" },
	{ type: 'kamon', "id": "0156", "name": "島津牡丹" },
	{ type: 'kamon', "id": "0157", "name": "鬼牡丹" },
	{ type: 'kamon', "id": "0158", "name": "秋田牡丹" },
	{ type: 'kamon', "id": "0159", "name": "利休牡丹" },
	{ type: 'kamon', "id": "0160", "name": "変わり大割り牡丹" },
	{ type: 'kamon', "id": "0161", "name": "日蓮宗甲斐見延山久遠寺" },
	{ type: 'kamon', "id": "0162", "name": "上野牡丹" },
	{ type: 'kamon', "id": "0163", "name": "三つ葉牡丹" },
	{ type: 'kamon', "id": "0164", "name": "五つ葉牡丹" },
	{ type: 'kamon', "id": "0165", "name": "唐草牡丹菱" },
	{ type: 'kamon', "id": "0166", "name": "蟹牡丹" },
	{ type: 'kamon', "id": "0167", "name": "薩摩牡丹" },
	{ type: 'kamon', "id": "0168", "name": "乱れ牡丹" },
	{ type: 'kamon', "id": "0169", "name": "鍋島牡丹" },
	{ type: 'kamon', "id": "0170", "name": "枝牡丹" },
	{ type: 'kamon', "id": "0171", "name": "細輪に葉牡丹" },
	{ type: 'kamon', "id": "0172", "name": "下がり葡萄" },
	{ type: 'kamon', "id": "0173", "name": "葡萄の丸" },
	{ type: 'kamon', "id": "0174", "name": "糸輪に葡萄棚" },
	{ type: 'kamon', "id": "0175", "name": "一房葡萄" },
	{ type: 'kamon', "id": "0176", "name": "葡萄桐" },
	{ type: 'kamon', "id": "0177", "name": "葡萄枝丸" },
	{ type: 'kamon', "id": "0178", "name": "尻合わせ三つ葡萄の葉" },
	{ type: 'kamon', "id": "0179", "name": "茶の実" },
	{ type: 'kamon', "id": "0180", "name": "丸に茶の実" },
	{ type: 'kamon', "id": "0181", "name": "菱に茶の実" },
	{ type: 'kamon', "id": "0182", "name": "陰茶の実" },
	{ type: 'kamon', "id": "0183", "name": "五つ茶の実" },
	{ type: 'kamon', "id": "0184", "name": "丸に三つ茶の実" },
	{ type: 'kamon', "id": "0185", "name": "亀甲三つ茶の実" },
	{ type: 'kamon', "id": "0186", "name": "三つ葉茶の実" },
	{ type: 'kamon', "id": "0187", "name": "浮線茶の実" },
	{ type: 'kamon', "id": "0188", "name": "糸輪に三つ剣茶の実" },
	{ type: 'kamon', "id": "0189", "name": "雪輪に茶の実" },
	{ type: 'kamon', "id": "0190", "name": "四つ茶の実" },
	{ type: 'kamon', "id": "0191", "name": "五つ茶の実" },
	{ type: 'kamon', "id": "0192", "name": "茶の実鶴" },
	{ type: 'kamon', "id": "0193", "name": "向こう茶の実" },
	{ type: 'kamon', "id": "0194", "name": "違い茶の実" },
	{ type: 'kamon', "id": "0195", "name": "三つ盛り茶の実" },
	{ type: 'kamon', "id": "0196", "name": "茶の実菱" },
	{ type: 'kamon', "id": "0197", "name": "茶の実桐" },
	{ type: 'kamon', "id": "0198", "name": "細輪に三つ葉茶の実" },
	{ type: 'kamon', "id": "0199", "name": "中輪に一つ丁字" },
	{ type: 'kamon', "id": "0200", "name": "丸に並び丁字" },
	{ type: 'kamon', "id": "0201", "name": "丸に違い丁子" },
	{ type: 'kamon', "id": "0202", "name": "三つ盛り違い丁字" },
	{ type: 'kamon', "id": "0203", "name": "丸に五つ丁字" },
	{ type: 'kamon', "id": "0204", "name": "六つ丁字" },
	{ type: 'kamon', "id": "0205", "name": "八つ丁字" },
	{ type: 'kamon', "id": "0206", "name": "八つ丁字菱" },
	{ type: 'kamon', "id": "0207", "name": "右一つ丁字巴" },
	{ type: 'kamon', "id": "0208", "name": "左一つ丁字巴" },
	{ type: 'kamon', "id": "0209", "name": "右二つ丁字巴" },
	{ type: 'kamon', "id": "0210", "name": "左二つ丁字巴" },
	{ type: 'kamon', "id": "0211", "name": "右三つ丁字巴" },
	{ type: 'kamon', "id": "0212", "name": "丸に右三つ丁字巴" },
	{ type: 'kamon', "id": "0213", "name": "左三つ丁字巴" },
	{ type: 'kamon', "id": "0214", "name": "丸に左三つ丁字巴" },
	{ type: 'kamon', "id": "0215", "name": "折れ丁字" },
	{ type: 'kamon', "id": "0216", "name": "子持ち抱き丁字" },
	{ type: 'kamon', "id": "0217", "name": "三つ丁字" },
	{ type: 'kamon', "id": "0218", "name": "丁字井桁" },
	{ type: 'kamon', "id": "0219", "name": "三つ組み合わせ丁字" },
	{ type: 'kamon', "id": "0220", "name": "丸に花丁字" },
	{ type: 'kamon', "id": "0221", "name": "丸に陰陽食い違い丁字" },
	{ type: 'kamon', "id": "0222", "name": "三つ追い丁字" },
	{ type: 'kamon', "id": "0223", "name": "二股丁字の丸" },
	{ type: 'kamon', "id": "0224", "name": "野津丁字" },
	{ type: 'kamon', "id": "0225", "name": "揚羽蝶" },
	{ type: 'kamon', "id": "0226", "name": "丸に揚羽蝶" },
	{ type: 'kamon', "id": "0227", "name": "陰揚羽蝶" },
	{ type: 'kamon', "id": "0228", "name": "石持ち地抜き揚羽蝶" },
	{ type: 'kamon', "id": "0229", "name": "松平因州蝶" },
	{ type: 'kamon', "id": "0230", "name": "浮線蝶" },
	{ type: 'kamon', "id": "0231", "name": "浮線蝶に花菱" },
	{ type: 'kamon', "id": "0232", "name": "備前蝶" },
	{ type: 'kamon', "id": "0233", "name": "対かい揚羽蝶" },
	{ type: 'kamon', "id": "0234", "name": "対かい蝶" },
	{ type: 'kamon', "id": "0235", "name": "三つ蝶" },
	{ type: 'kamon', "id": "0236", "name": "光琳蝶" },
	{ type: 'kamon', "id": "0237", "name": "揚羽蝶の丸" },
	{ type: 'kamon', "id": "0238", "name": "源氏蝶" },
	{ type: 'kamon', "id": "0239", "name": "雪輪に浮線蝶" },
	{ type: 'kamon', "id": "0240", "name": "北条対い蝶" },
	{ type: 'kamon', "id": "0241", "name": "保倉蝶" },
	{ type: 'kamon', "id": "0242", "name": "陰光琳蝶" },
	{ type: 'kamon', "id": "0243", "name": "変わり源氏蝶" },
	{ type: 'kamon', "id": "0244", "name": "反り胡蝶" },
	{ type: 'kamon', "id": "0245", "name": "蝶花形" },
	{ type: 'kamon', "id": "0246", "name": "中陰揚羽蝶" },
	{ type: 'kamon', "id": "0247", "name": "播州蝶" },
	{ type: 'kamon', "id": "0248", "name": "織田蝶" },
	{ type: 'kamon', "id": "0249", "name": "鎧揚羽蝶" },
	{ type: 'kamon', "id": "0250", "name": "陰大割り揚羽蝶" },
	{ type: 'kamon', "id": "0251", "name": "中陰光琳蝶" },
	{ type: 'kamon', "id": "0252", "name": "変わり揚羽蝶" },
	{ type: 'kamon', "id": "0253", "name": "丸に変わり揚羽蝶" },
	{ type: 'kamon', "id": "0254", "name": "右向き変わり揚羽蝶" },
	{ type: 'kamon', "id": "0255", "name": "真向き揚羽蝶" },
	{ type: 'kamon', "id": "0256", "name": "因州蝶" },
	{ type: 'kamon', "id": "0257", "name": "糸輪に覗き揚羽蝶" },
	{ type: 'kamon', "id": "0258", "name": "ひげ丸揚羽蝶" },
	{ type: 'kamon', "id": "0259", "name": "雪輪菱に揚羽蝶" },
	{ type: 'kamon', "id": "0260", "name": "糸輪に豆浮線蝶" },
	{ type: 'kamon', "id": "0261", "name": "中陰浮線蝶" },
	{ type: 'kamon', "id": "0262", "name": "石持ち地抜き変わり浮線蝶" },
	{ type: 'kamon', "id": "0263", "name": "変わり備前蝶" },
	{ type: 'kamon', "id": "0264", "name": "月形浮線蝶" },
	{ type: 'kamon', "id": "0265", "name": "月星形浮線蝶" },
	{ type: 'kamon', "id": "0266", "name": "浮線蝶に松川菱" },
	{ type: 'kamon', "id": "0267", "name": "浮線蝶に横見桜" },
	{ type: 'kamon', "id": "0268", "name": "浮線蝶に武田菱" },
	{ type: 'kamon', "id": "0269", "name": "浮線蝶に桔梗" },
	{ type: 'kamon', "id": "0270", "name": "陰大割り浮線蝶" },
	{ type: 'kamon', "id": "0271", "name": "上下対い蝶" },
	{ type: 'kamon', "id": "0272", "name": "上下対い変わり蝶" },
	{ type: 'kamon', "id": "0273", "name": "陰対い蝶" },
	{ type: 'kamon', "id": "0274", "name": "中輪に変わり対い蝶" },
	{ type: 'kamon', "id": "0275", "name": "雪輪に対い蝶" },
	{ type: 'kamon', "id": "0276", "name": "対い蝶に三つ割り桔梗" },
	{ type: 'kamon', "id": "0277", "name": "対い蝶に蔦" },
	{ type: 'kamon', "id": "0278", "name": "池田対い蝶" },
	{ type: 'kamon', "id": "0279", "name": "変わり対い鎧蝶" },
	{ type: 'kamon', "id": "0280", "name": "日向変わり対い蝶" },
	{ type: 'kamon', "id": "0281", "name": "変わり対い揚羽蝶" },
	{ type: 'kamon', "id": "0282", "name": "斜め向かい胡蝶" },
	{ type: 'kamon', "id": "0283", "name": "三つ蝶に桔梗" },
	{ type: 'kamon', "id": "0284", "name": "三つ蝶に落ち牡丹" },
	{ type: 'kamon', "id": "0285", "name": "伊豆蝶" },
	{ type: 'kamon', "id": "0286", "name": "吉田蝶" },
	{ type: 'kamon', "id": "0287", "name": "頭合わせ変わり三つ蝶" },
	{ type: 'kamon', "id": "0288", "name": "頭合わせ三つ寄せ蝶" },
	{ type: 'kamon', "id": "0289", "name": "池田三つ蝶" },
	{ type: 'kamon', "id": "0290", "name": "三つ揚羽蝶" },
	{ type: 'kamon', "id": "0291", "name": "三つ追い揚羽蝶" },
	{ type: 'kamon', "id": "0292", "name": "三つ割り揚羽蝶" },
	{ type: 'kamon', "id": "0293", "name": "三つ浮線蝶巴" },
	{ type: 'kamon', "id": "0294", "name": "胡蝶" },
	{ type: 'kamon', "id": "0295", "name": "変わり胡蝶" },
	{ type: 'kamon', "id": "0296", "name": "三つ反り胡蝶" },
	{ type: 'kamon', "id": "0297", "name": "光琳胡蝶" },
	{ type: 'kamon', "id": "0298", "name": "中陰光琳胡蝶" },
	{ type: 'kamon', "id": "0299", "name": "糸輪に胡蝶" },
	{ type: 'kamon', "id": "0300", "name": "揚羽飛び蝶" },
	{ type: 'kamon', "id": "0301", "name": "変わり蝶花形" },
	{ type: 'kamon', "id": "0302", "name": "飛び蝶花形" },
	{ type: 'kamon', "id": "0303", "name": "三つ蝶花形" },
	{ type: 'kamon', "id": "0304", "name": "変わり三つ蝶花形" },
	{ type: 'kamon', "id": "0305", "name": "結び蝶" },
	{ type: 'kamon', "id": "0306", "name": "中陰光琳飛び蝶" },
	{ type: 'kamon', "id": "0307", "name": "揚羽蝶菱" },
	{ type: 'kamon', "id": "0308", "name": "陰揚羽蝶菱" },
	{ type: 'kamon', "id": "0309", "name": "中陰対い蝶菱" },
	{ type: 'kamon', "id": "0310", "name": "鎧蝶菱" },
	{ type: 'kamon', "id": "0311", "name": "変わり蝶菱" },
	{ type: 'kamon', "id": "0312", "name": "浮線蝶菱" },
	{ type: 'kamon', "id": "0313", "name": "上下対い蝶菱" },
	{ type: 'kamon', "id": "0314", "name": "建部蝶" },
	{ type: 'kamon', "id": "0315", "name": "変わり対い蝶菱" },
	{ type: 'kamon', "id": "0316", "name": "揚羽蝶車" },
	{ type: 'kamon', "id": "0317", "name": "変わり二つ蝶" },
	{ type: 'kamon', "id": "0318", "name": "三つ飛び胡蝶" },
	{ type: 'kamon', "id": "0319", "name": "兜蝶" },
	{ type: 'kamon', "id": "0320", "name": "蝶の星" },
	{ type: 'kamon', "id": "0321", "name": "変わり提盤" },
	{ type: 'kamon', "id": "0322", "name": "提盤" },
	{ type: 'kamon', "id": "0323", "name": "三つ盛り提盤" },
	{ type: 'kamon', "id": "0324", "name": "三つ割り提盤" },
	{ type: 'kamon', "id": "0325", "name": "提盤菱" },
	{ type: 'kamon', "id": "0326", "name": "浮線提盤" },
	{ type: 'kamon', "id": "0327", "name": "丸に提盤" },
	{ type: 'kamon', "id": "0328", "name": "小菅提盤" },
	{ type: 'kamon', "id": "0329", "name": "頭合わせ三つ提盤" },
	{ type: 'kamon', "id": "0330", "name": "提盤桐" },
	{ type: 'kamon', "id": "0331", "name": "丸に提盤菱" },
	{ type: 'kamon', "id": "0332", "name": "真向き大根" },
	{ type: 'kamon', "id": "0333", "name": "違い大根" },
	{ type: 'kamon', "id": "0334", "name": "対い割り大根" },
	{ type: 'kamon', "id": "0335", "name": "大根の丸" },
	{ type: 'kamon', "id": "0336", "name": "代わり大根の丸" },
	{ type: 'kamon', "id": "0337", "name": "丸に二つ串団子" },
	{ type: 'kamon', "id": "0338", "name": "三つ串団子" },
	{ type: 'kamon', "id": "0339", "name": "田字草" },
	{ type: 'kamon', "id": "0340", "name": "変わり田字草" },
	{ type: 'kamon', "id": "0341", "name": "中陰菱形変わり田字草" },
	{ type: 'kamon', "id": "0342", "name": "丸に蔓田字草" },
	{ type: 'kamon', "id": "0343", "name": "田字草の丸" },
	{ type: 'kamon', "id": "0344", "name": "海老" },
	{ type: 'kamon', "id": "0345", "name": "海老の丸" },
	{ type: 'kamon', "id": "0346", "name": "対い海老" },
	{ type: 'kamon', "id": "0347", "name": "二つ追い海老の丸" },
	{ type: 'kamon', "id": "0348", "name": "三つ追い海老" },
	{ type: 'kamon', "id": "0349", "name": "伊勢海老の丸" },
	{ type: 'kamon', "id": "0350", "name": "立ち烏帽子" },
	{ type: 'kamon', "id": "0351", "name": "大将烏帽子" },
	{ type: 'kamon', "id": "0352", "name": "公家烏帽子" },
	{ type: 'kamon', "id": "0353", "name": "三位烏帽子" },
	{ type: 'kamon', "id": "0354", "name": "武家烏帽子" },
	{ type: 'kamon', "id": "0355", "name": "下がり藤" },
	{ type: 'kamon', "id": "0356", "name": "丸に下がり藤" },
	{ type: 'kamon', "id": "0357", "name": "陰下がり藤" },
	{ type: 'kamon', "id": "0358", "name": "石持ち地抜き下がり藤" },
	{ type: 'kamon', "id": "0359", "name": "割り下がり藤" },
	{ type: 'kamon', "id": "0360", "name": "九条藤" },
	{ type: 'kamon', "id": "0361", "name": "仏光寺藤" },
	{ type: 'kamon', "id": "0362", "name": "下がり散藤" },
	{ type: 'kamon', "id": "0363", "name": "下がり葉出散藤" },
	{ type: 'kamon', "id": "0364", "name": "下がり藤に三つ巴" },
	{ type: 'kamon', "id": "0365", "name": "柴田藤" },
	{ type: 'kamon', "id": "0366", "name": "内藤藤" },
	{ type: 'kamon', "id": "0367", "name": "上がり藤" },
	{ type: 'kamon', "id": "0368", "name": "丸に上がり藤" },
	{ type: 'kamon', "id": "0369", "name": "石持ち地抜き上がり藤" },
	{ type: 'kamon', "id": "0370", "name": "軸付き上がり藤" },
	{ type: 'kamon', "id": "0371", "name": "石持ち地抜き安藤藤" },
	{ type: 'kamon', "id": "0372", "name": "上がり散藤" },
	{ type: 'kamon', "id": "0373", "name": "加藤藤" },
	{ type: 'kamon', "id": "0374", "name": "大久保藤" },
	{ type: 'kamon', "id": "0375", "name": "那須藤" },
	{ type: 'kamon', "id": "0376", "name": "右回り片手藤" },
	{ type: 'kamon', "id": "0377", "name": "片手藤" },
	{ type: 'kamon', "id": "0378", "name": "右回り三つ巴藤" },
	{ type: 'kamon', "id": "0379", "name": "三つ巴藤" },
	{ type: 'kamon', "id": "0380", "name": "軸付き右三つ巴藤" },
	{ type: 'kamon', "id": "0381", "name": "軸付き藤輪" },
	{ type: 'kamon', "id": "0382", "name": "三つ追い藤" },
	{ type: 'kamon', "id": "0383", "name": "八つ藤" },
	{ type: 'kamon', "id": "0384", "name": "下がり藤菱" },
	{ type: 'kamon', "id": "0385", "name": "上がり藤菱" },
	{ type: 'kamon', "id": "0386", "name": "三つ葉藤" },
	{ type: 'kamon', "id": "0387", "name": "丸に三つ葉藤" },
	{ type: 'kamon', "id": "0388", "name": "奈良春日神社" },
	{ type: 'kamon', "id": "0389", "name": "甲斐向嶽寺" },
	{ type: 'kamon', "id": "0390", "name": "三河園福寺" },
	{ type: 'kamon', "id": "0391", "name": "近江錦織寺" },
	{ type: 'kamon', "id": "0392", "name": "上がり藤に桔梗" },
	{ type: 'kamon', "id": "0393", "name": "二つ藤" },
	{ type: 'kamon', "id": "0394", "name": "二つ藤巴" },
	{ type: 'kamon', "id": "0395", "name": "藤輪に剣酢漿草" },
	{ type: 'kamon', "id": "0396", "name": "藤輪に四方剣花菱" },
	{ type: 'kamon', "id": "0397", "name": "一条藤" },
	{ type: 'kamon', "id": "0398", "name": "安藤藤" },
	{ type: 'kamon', "id": "0399", "name": "遠山藤" },
	{ type: 'kamon', "id": "0400", "name": "藤の花" },
	{ type: 'kamon', "id": "0401", "name": "藤胡蝶" },
	{ type: 'kamon', "id": "0402", "name": "丸に三つ追い藤" },
	{ type: 'kamon', "id": "0403", "name": "袋" },
	{ type: 'kamon', "id": "0404", "name": "三つ袋" },
	{ type: 'kamon', "id": "0405", "name": "金袋" },
	{ type: 'kamon', "id": "0406", "name": "聖天袋" },
	{ type: 'kamon', "id": "0407", "name": "丸に宝袋" },
	{ type: 'kamon', "id": "0408", "name": "棗形袋" },
	{ type: 'kamon', "id": "0409", "name": "丸に二つ宝袋" },
	{ type: 'kamon', "id": "0410", "name": "変わり宝袋" },
	{ type: 'kamon', "id": "0411", "name": "細輪に袋菱" },
	{ type: 'kamon', "id": "0412", "name": "中輪に角宝袋" },
	{ type: 'kamon', "id": "0413", "name": "細輪に唐草袋" },
	{ type: 'kamon', "id": "0414", "name": "細輪に尻合わせ三つ宝袋" },
	{ type: 'kamon', "id": "0415", "name": "対かい文菱" },
	{ type: 'kamon', "id": "0416", "name": "開き文" },
	{ type: 'kamon', "id": "0417", "name": "結び文" },
	{ type: 'kamon', "id": "0418", "name": "組み文" },
	{ type: 'kamon', "id": "0419", "name": "丸に結び文" },
	{ type: 'kamon', "id": "0420", "name": "恋文" },
	{ type: 'kamon', "id": "0421", "name": "丸に文車" },
	{ type: 'kamon', "id": "0422", "name": "丸に分銅" },
	{ type: 'kamon', "id": "0423", "name": "細輪に三つ分銅" },
	{ type: 'kamon', "id": "0424", "name": "分銅" },
	{ type: 'kamon', "id": "0425", "name": "並び分銅" },
	{ type: 'kamon', "id": "0426", "name": "比翼分銅" },
	{ type: 'kamon', "id": "0427", "name": "子持ち分銅" },
	{ type: 'kamon', "id": "0428", "name": "分銅梅鉢" },
	{ type: 'kamon', "id": "0429", "name": "帆掛船" },
	{ type: 'kamon', "id": "0430", "name": "丸に変わり帆掛船" },
	{ type: 'kamon', "id": "0431", "name": "一つ左帆の丸" },
	{ type: 'kamon', "id": "0432", "name": "丸に一つ帆" },
	{ type: 'kamon', "id": "0433", "name": "鳥取名和神社" },
	{ type: 'kamon', "id": "0434", "name": "糸輪に真向き帆" },
	{ type: 'kamon', "id": "0435", "name": "二つ帆の丸" },
	{ type: 'kamon', "id": "0436", "name": "抱き帆" },
	{ type: 'kamon', "id": "0437", "name": "四つ帆の丸" },
	{ type: 'kamon', "id": "0438", "name": "二つ帆菱" },
	{ type: 'kamon', "id": "0439", "name": "飾り舟" },
	{ type: 'kamon', "id": "0440", "name": "宝舟" },
	{ type: 'kamon', "id": "0441", "name": "木の葉舟" },
	{ type: 'kamon', "id": "0442", "name": "丸に帆懸舟" },
	{ type: 'kamon', "id": "0443", "name": "真向き帆懸船" },
	{ type: 'kamon', "id": "0444", "name": "園部額" },
	{ type: 'kamon', "id": "0445", "name": "丸に額" },
	{ type: 'kamon', "id": "0446", "name": "小出額" },
	{ type: 'kamon', "id": "0447", "name": "懸魚に十六菊" },
	{ type: 'kamon', "id": "0448", "name": "六葉懸魚" },
	{ type: 'kamon', "id": "0449", "name": "六葉" },
	{ type: 'kamon', "id": "0450", "name": "源氏車" },
	{ type: 'kamon', "id": "0451", "name": "陰源氏車" },
	{ type: 'kamon', "id": "0452", "name": "六つ源氏車" },
	{ type: 'kamon', "id": "0453", "name": "七つ源氏車" },
	{ type: 'kamon', "id": "0454", "name": "榊原源氏車" },
	{ type: 'kamon', "id": "0455", "name": "十二本骨源氏車" },
	{ type: 'kamon', "id": "0456", "name": "生駒車" },
	{ type: 'kamon', "id": "0457", "name": "源氏車輪" },
	{ type: 'kamon', "id": "0458", "name": "源氏輪に三つ星" },
	{ type: 'kamon', "id": "0459", "name": "源氏輪に並び矢" },
	{ type: 'kamon', "id": "0460", "name": "据え五徳" },
	{ type: 'kamon', "id": "0461", "name": "真向き五徳" },
	{ type: 'kamon', "id": "0462", "name": "石持ち地抜き据え五徳" },
	{ type: 'kamon', "id": "0463", "name": "変わり五徳" },
	{ type: 'kamon', "id": "0464", "name": "丸五徳" },
	{ type: 'kamon', "id": "0465", "name": "五徳菱" },
	{ type: 'kamon', "id": "0466", "name": "中輪に角五徳" },
	{ type: 'kamon', "id": "0467", "name": "合子に箸" },
	{ type: 'kamon', "id": "0468", "name": "二つ合子に箸" },
	{ type: 'kamon', "id": "0469", "name": "丸に一つ花杏葉" },
	{ type: 'kamon', "id": "0470", "name": "太輪に違い花杏葉" },
	{ type: 'kamon', "id": "0471", "name": "鍋島花杏葉" },
	{ type: 'kamon', "id": "0472", "name": "丸に抱き花杏葉" },
	{ type: 'kamon', "id": "0473", "name": "花なし抱き杏葉" },
	{ type: 'kamon', "id": "0474", "name": "一の字に抱き花杏葉" },
	{ type: 'kamon', "id": "0475", "name": "小城花杏葉" },
	{ type: 'kamon', "id": "0476", "name": "牧山花杏葉" },
	{ type: 'kamon', "id": "0477", "name": "三つ盛り抱き杏葉" },
	{ type: 'kamon', "id": "0478", "name": "花杏葉巴" },
	{ type: 'kamon', "id": "0479", "name": "石持ち地抜き杏葉" },
	{ type: 'kamon', "id": "0480", "name": "丸に八つ割り抱き杏葉" },
	{ type: 'kamon', "id": "0481", "name": "抱き杏葉菱" },
	{ type: 'kamon', "id": "0482", "name": "羽箒" },
	{ type: 'kamon', "id": "0483", "name": "羽箒の丸" },
	{ type: 'kamon', "id": "0484", "name": "外向き羽箒" },
	{ type: 'kamon', "id": "0485", "name": "違い羽箒" },
	{ type: 'kamon', "id": "0486", "name": "二つ追い羽箒" },
	{ type: 'kamon', "id": "0487", "name": "抱き萩" },
	{ type: 'kamon', "id": "0488", "name": "割り抱き萩" },
	{ type: 'kamon', "id": "0489", "name": "萩の丸" },
	{ type: 'kamon', "id": "0490", "name": "立萩" },
	{ type: 'kamon', "id": "0491", "name": "名付き抱き萩" },
	{ type: 'kamon', "id": "0492", "name": "丸に九枚萩" },
	{ type: 'kamon', "id": "0493", "name": "萩の丸（２）" },
	{ type: 'kamon', "id": "0494", "name": "羽子板" },
	{ type: 'kamon', "id": "0495", "name": "丸に並び羽子板" },
	{ type: 'kamon', "id": "0496", "name": "中輪に違い羽子板" },
	{ type: 'kamon', "id": "0497", "name": "違い羽子板" },
	{ type: 'kamon', "id": "0498", "name": "雪輪に羽子板" },
	{ type: 'kamon', "id": "0499", "name": "三つ重ね羽子板" },
	{ type: 'kamon', "id": "0500", "name": "五つ追い羽子板" },
	{ type: 'kamon', "id": "0501", "name": "六つ羽子板に羽" },
	{ type: 'kamon', "id": "0502", "name": "八つ羽子板車" },
	{ type: 'kamon', "id": "0503", "name": "一つ蛤" },
	{ type: 'kamon', "id": "0504", "name": "尻合わせ三つ蛤" },
	{ type: 'kamon', "id": "0505", "name": "細輪に顔合わせ三つ蛤" },
	{ type: 'kamon', "id": "0506", "name": "糸輪に尻合わせ三つ蛤" },
	{ type: 'kamon', "id": "0507", "name": "五つ蛤" },
	{ type: 'kamon', "id": "0508", "name": "蛤胡蝶" },
	{ type: 'kamon', "id": "0509", "name": "陰三つ蛤" },
	{ type: 'kamon', "id": "0510", "name": "三つ盛り蛤" },
	{ type: 'kamon', "id": "0511", "name": "細輪に三つ盛り蛤" },
	{ type: 'kamon', "id": "0512", "name": "丸に三つ蛤" },
	{ type: 'kamon', "id": "0513", "name": "変わり五つ蛤" },
	{ type: 'kamon', "id": "0514", "name": "変わり一つ蛤" },
	{ type: 'kamon', "id": "0515", "name": "丸に真向き蛤" },
	{ type: 'kamon', "id": "0516", "name": "尻合わせ二つ蛤" },
	{ type: 'kamon', "id": "0517", "name": "中輪に二つ蛤" },
	{ type: 'kamon', "id": "0518", "name": "中輪に変わり蛤" },
	{ type: 'kamon', "id": "0519", "name": "糸輪に変わり尻合わせ三つ蛤" },
	{ type: 'kamon', "id": "0520", "name": "四つ蛤" },
	{ type: 'kamon', "id": "0521", "name": "三つ蛤に海草" },
	{ type: 'kamon', "id": "0522", "name": "花菱" },
	{ type: 'kamon', "id": "0523", "name": "丸に花菱" },
	{ type: 'kamon', "id": "0524", "name": "糸輪に豆花菱" },
	{ type: 'kamon', "id": "0525", "name": "雪輪に花菱" },
	{ type: 'kamon', "id": "0526", "name": "中陰菱に花菱" },
	{ type: 'kamon', "id": "0527", "name": "陰花菱" },
	{ type: 'kamon', "id": "0528", "name": "中陰花菱" },
	{ type: 'kamon', "id": "0529", "name": "丸に中陰花菱" },
	{ type: 'kamon', "id": "0530", "name": "太陰花菱" },
	{ type: 'kamon', "id": "0531", "name": "糸輪に陰豆花菱" },
	{ type: 'kamon', "id": "0532", "name": "石持ち地抜き花菱" },
	{ type: 'kamon', "id": "0533", "name": "蔓花菱" },
	{ type: 'kamon', "id": "0534", "name": "剣花菱" },
	{ type: 'kamon', "id": "0535", "name": "丸に剣花菱" },
	{ type: 'kamon', "id": "0536", "name": "中陰剣花菱" },
	{ type: 'kamon', "id": "0537", "name": "三つ割り花菱" },
	{ type: 'kamon', "id": "0538", "name": "丸に三つ割り花菱" },
	{ type: 'kamon', "id": "0539", "name": "中陰三つ割り花菱" },
	{ type: 'kamon', "id": "0540", "name": "三つ盛り花菱" },
	{ type: 'kamon', "id": "0541", "name": "四つ花菱" },
	{ type: 'kamon', "id": "0542", "name": "丸に四つ花菱" },
	{ type: 'kamon', "id": "0543", "name": "三つ寄せ花菱" },
	{ type: 'kamon', "id": "0544", "name": "丸に三つ寄せ花菱" },
	{ type: 'kamon', "id": "0545", "name": "鬼花菱" },
	{ type: 'kamon', "id": "0546", "name": "丸に鬼花菱" },
	{ type: 'kamon', "id": "0547", "name": "利休花菱" },
	{ type: 'kamon', "id": "0548", "name": "むくみ花菱" },
	{ type: 'kamon', "id": "0549", "name": "大阪住吉神社" },
	{ type: 'kamon', "id": "0550", "name": "変わり三つ蔓花菱" },
	{ type: 'kamon', "id": "0551", "name": "変わり蔓花菱" },
	{ type: 'kamon', "id": "0552", "name": "郡山花菱" },
	{ type: 'kamon', "id": "0553", "name": "割り花菱車" },
	{ type: 'kamon', "id": "0554", "name": "吉田花菱" },
	{ type: 'kamon', "id": "0555", "name": "浮線花菱" },
	{ type: 'kamon', "id": "0556", "name": "鬼花菱鶴" },
	{ type: 'kamon', "id": "0557", "name": "雪輪に覗き花菱" },
	{ type: 'kamon', "id": "0558", "name": "糸輪に覗き花菱" },
	{ type: 'kamon', "id": "0559", "name": "陰鬼花菱" },
	{ type: 'kamon', "id": "0560", "name": "痩せ鬼花菱" },
	{ type: 'kamon', "id": "0561", "name": "中陰尖り花菱" },
	{ type: 'kamon', "id": "0562", "name": "変わり花菱" },
	{ type: 'kamon', "id": "0563", "name": "有職花菱" },
	{ type: 'kamon', "id": "0564", "name": "朧花菱" },
	{ type: 'kamon', "id": "0565", "name": "二つ剣花菱" },
	{ type: 'kamon', "id": "0566", "name": "中陰変わり花菱" },
	{ type: 'kamon', "id": "0567", "name": "雲形花菱" },
	{ type: 'kamon', "id": "0568", "name": "丸に雪持ち花菱" },
	{ type: 'kamon', "id": "0569", "name": "石持ち地抜き剣花菱" },
	{ type: 'kamon', "id": "0570", "name": "三つ蝶花菱" },
	{ type: 'kamon', "id": "0571", "name": "花菱胡蝶" },
	{ type: 'kamon', "id": "0572", "name": "花菱蝶" },
	{ type: 'kamon', "id": "0573", "name": "松皮菱に花菱" },
	{ type: 'kamon', "id": "0574", "name": "石持ち地抜き松皮菱に花菱" },
	{ type: 'kamon', "id": "0575", "name": "菱持ち地抜き花菱" },
	{ type: 'kamon', "id": "0576", "name": "菱分銅に花菱" },
	{ type: 'kamon', "id": "0577", "name": "鞠挟みに花菱" },
	{ type: 'kamon', "id": "0578", "name": "二つ割り花菱" },
	{ type: 'kamon', "id": "0579", "name": "細菱に覗き花菱" },
	{ type: 'kamon', "id": "0580", "name": "糸菱に覗き陰花菱" },
	{ type: 'kamon', "id": "0581", "name": "隅切り菱に剣花菱" },
	{ type: 'kamon', "id": "0582", "name": "対い割り花菱" },
	{ type: 'kamon', "id": "0583", "name": "陰対い割り花菱" },
	{ type: 'kamon', "id": "0584", "name": "柳沢花菱" },
	{ type: 'kamon', "id": "0585", "name": "三つ寄せ盛り花菱" },
	{ type: 'kamon', "id": "0586", "name": "杏葉花菱" },
	{ type: 'kamon', "id": "0587", "name": "変わり杏葉花菱" },
	{ type: 'kamon', "id": "0588", "name": "二つ割り花菱" },
	{ type: 'kamon', "id": "0589", "name": "乱れ割り花菱" },
	{ type: 'kamon', "id": "0590", "name": "三つ割り剣花菱" },
	{ type: 'kamon', "id": "0591", "name": "三つ割り剣花菱崩し" },
	{ type: 'kamon', "id": "0592", "name": "花菱崩し巴" },
	{ type: 'kamon', "id": "0593", "name": "三つ割り花菱に剣片喰" },
	{ type: 'kamon', "id": "0594", "name": "三つ割り花菱に木瓜" },
	{ type: 'kamon', "id": "0595", "name": "三つ割り六角花菱" },
	{ type: 'kamon', "id": "0596", "name": "角四つ割り花菱" },
	{ type: 'kamon', "id": "0597", "name": "平四つ割り角に花菱" },
	{ type: 'kamon', "id": "0598", "name": "横見花菱" },
	{ type: 'kamon', "id": "0599", "name": "葉付き横見花菱" },
	{ type: 'kamon', "id": "0600", "name": "三つ横見花菱" },
	{ type: 'kamon', "id": "0601", "name": "外三つ割り花菱" },
	{ type: 'kamon', "id": "0602", "name": "三方花菱" },
	{ type: 'kamon', "id": "0603", "name": "三つ割り花菱崩し" },
	{ type: 'kamon', "id": "0604", "name": "五つ割り花菱車" },
	{ type: 'kamon', "id": "0605", "name": "枝花菱" },
	{ type: 'kamon', "id": "0606", "name": "花菱枝丸" },
	{ type: 'kamon', "id": "0607", "name": "蔓結び割り花崩し" },
	{ type: 'kamon', "id": "0608", "name": "中陰浮線花菱" },
	{ type: 'kamon', "id": "0609", "name": "変わり花菱浮線綾" },
	{ type: 'kamon', "id": "0610", "name": "変わり花菱浮線綾（２）" },
	{ type: 'kamon', "id": "0611", "name": "丸に浮線綾花菱" },
	{ type: 'kamon', "id": "0612", "name": "多々羅花菱" },
	{ type: 'kamon', "id": "0613", "name": "雲形花菱（２）" },
	{ type: 'kamon', "id": "0614", "name": "朧花菱（２）" },
	{ type: 'kamon', "id": "0615", "name": "花角" },
	{ type: 'kamon', "id": "0616", "name": "丸に花角" },
	{ type: 'kamon', "id": "0617", "name": "中陰花角" },
	{ type: 'kamon', "id": "0618", "name": "糸輪に蔓花角" },
	{ type: 'kamon', "id": "0619", "name": "剣花角" },
	{ type: 'kamon', "id": "0620", "name": "丸に剣花角" },
	{ type: 'kamon', "id": "0621", "name": "浮線剣花角" },
	{ type: 'kamon', "id": "0622", "name": "徳大寺花角" },
	{ type: 'kamon', "id": "0623", "name": "太陰花角" },
	{ type: 'kamon', "id": "0624", "name": "糸輪に豆花角" },
	{ type: 'kamon', "id": "0625", "name": "尖り花角" },
	{ type: 'kamon', "id": "0626", "name": "四つ目形花角" },
	{ type: 'kamon', "id": "0627", "name": "陰剣花角" },
	{ type: 'kamon', "id": "0628", "name": "石持ち地抜き剣花角" },
	{ type: 'kamon', "id": "0629", "name": "三木花角" },
	{ type: 'kamon', "id": "0630", "name": "八重花角" },
	{ type: 'kamon', "id": "0631", "name": "滋野井花角" },
	{ type: 'kamon', "id": "0632", "name": "三条花角" },
	{ type: 'kamon', "id": "0633", "name": "花角崩し" },
	{ type: 'kamon', "id": "0634", "name": "三須賀花角" },
	{ type: 'kamon', "id": "0635", "name": "三つ盛り花角" },
	{ type: 'kamon', "id": "0636", "name": "四つ尖り花角" },
	{ type: 'kamon', "id": "0637", "name": "角四つ割り花角" },
	{ type: 'kamon', "id": "0638", "name": "四つ割り鬼花角" },
	{ type: 'kamon', "id": "0639", "name": "変わり蔓花角" },
	{ type: 'kamon', "id": "0640", "name": "丸に鋏" },
	{ type: 'kamon', "id": "0641", "name": "丸に違い鋏" },
	{ type: 'kamon', "id": "0642", "name": "丸に並び鋏" },
	{ type: 'kamon', "id": "0643", "name": "糸輪に三つ重ね鋏" },
	{ type: 'kamon', "id": "0644", "name": "三段梯子" },
	{ type: 'kamon', "id": "0645", "name": "牧野梯子" },
	{ type: 'kamon', "id": "0646", "name": "六角笹に三段梯子" },
	{ type: 'kamon', "id": "0647", "name": "蓮の花" },
	{ type: 'kamon', "id": "0648", "name": "丸に蓮の花" },
	{ type: 'kamon', "id": "0649", "name": "葉付き立ち蓮の花" },
	{ type: 'kamon', "id": "0650", "name": "抱き割り蓮の花" },
	{ type: 'kamon', "id": "0651", "name": "蓮の丸" },
	{ type: 'kamon', "id": "0652", "name": "丸に蓮の葉" },
	{ type: 'kamon', "id": "0653", "name": "糸輪に五つ蓮の実" },
	{ type: 'kamon', "id": "0654", "name": "鳩" },
	{ type: 'kamon', "id": "0655", "name": "対かい鳩" },
	{ type: 'kamon', "id": "0656", "name": "鳥居に対かい鳩" },
	{ type: 'kamon', "id": "0657", "name": "抱きほやに対かい鳩" },
	{ type: 'kamon', "id": "0658", "name": "丸に杖鳩" },
	{ type: 'kamon', "id": "0659", "name": "対い鳩に三つ石" },
	{ type: 'kamon', "id": "0660", "name": "鳩の丸" },
	{ type: 'kamon', "id": "0661", "name": "萬生に鳩" },
	{ type: 'kamon', "id": "0662", "name": "抱き萬生の丸に対い鳩" },
	{ type: 'kamon', "id": "0663", "name": "孔雀鳩" },
	{ type: 'kamon', "id": "0664", "name": "糸輪に対い鳩" },
	{ type: 'kamon', "id": "0665", "name": "小島対い鳩" },
	{ type: 'kamon', "id": "0666", "name": "朽木鳩" },
	{ type: 'kamon', "id": "0667", "name": "親子鳩" },
	{ type: 'kamon', "id": "0668", "name": "寓生に対い鳩" },
	{ type: 'kamon', "id": "0669", "name": "変わり抱き寓生に対い鳩" },
	{ type: 'kamon', "id": "0670", "name": "鳩に三十万字" },
	{ type: 'kamon', "id": "0671", "name": "鳥居に対かい鳩（２）" },
	{ type: 'kamon', "id": "0672", "name": "丸に幤" },
	{ type: 'kamon', "id": "0673", "name": "御払幣" },
	{ type: 'kamon', "id": "0674", "name": "違い幣" },
	{ type: 'kamon', "id": "0675", "name": "鈴違い幣" },
	{ type: 'kamon', "id": "0676", "name": "丸に鈴違い幣" },
	{ type: 'kamon', "id": "0677", "name": "神宮御幣" },
	{ type: 'kamon', "id": "0678", "name": "中輪に変わり御幣" },
	{ type: 'kamon', "id": "0679", "name": "祭礼御幣" },
	{ type: 'kamon', "id": "0680", "name": "榊に御幣" },
	{ type: 'kamon', "id": "0681", "name": "三つ盛り御幣" },
	{ type: 'kamon', "id": "0682", "name": "瓶子" },
	{ type: 'kamon', "id": "0683", "name": "丸に瓶子" },
	{ type: 'kamon', "id": "0684", "name": "並び瓶子" },
	{ type: 'kamon', "id": "0685", "name": "丸に並び瓶子" },
	{ type: 'kamon', "id": "0686", "name": "陰瓶子" },
	{ type: 'kamon', "id": "0687", "name": "石持ち地抜き瓶子" },
	{ type: 'kamon', "id": "0688", "name": "割り瓶子" },
	{ type: 'kamon', "id": "0689", "name": "変わり瓶子" },
	{ type: 'kamon', "id": "0690", "name": "丸に神社瓶子" },
	{ type: 'kamon', "id": "0691", "name": "三つ盛り瓶子" },
	{ type: 'kamon', "id": "0692", "name": "三つ割り追い瓶子" },
	{ type: 'kamon', "id": "0693", "name": "尻合わせ三つ瓶子" },
	{ type: 'kamon', "id": "0694", "name": "丸に一つ柊" },
	{ type: 'kamon', "id": "0695", "name": "抱き柊" },
	{ type: 'kamon', "id": "0696", "name": "丸に抱き柊" },
	{ type: 'kamon', "id": "0697", "name": "違い柊" },
	{ type: 'kamon', "id": "0698", "name": "割り柊" },
	{ type: 'kamon', "id": "0699", "name": "大関柊" },
	{ type: 'kamon', "id": "0700", "name": "三つ柊" },
	{ type: 'kamon', "id": "0701", "name": "市の橋柊" },
	{ type: 'kamon', "id": "0702", "name": "丸に三つ剣柊" },
	{ type: 'kamon', "id": "0703", "name": "三つ柊巴" },
	{ type: 'kamon', "id": "0704", "name": "一つ柊" },
	{ type: 'kamon', "id": "0705", "name": "折れ柊" },
	{ type: 'kamon', "id": "0706", "name": "三つ追い柊" },
	{ type: 'kamon', "id": "0707", "name": "四つ追い柊" },
	{ type: 'kamon', "id": "0708", "name": "丸に枝柊" },
	{ type: 'kamon', "id": "0709", "name": "丸に一つ引き" },
	{ type: 'kamon', "id": "0710", "name": "丸の内に一つ引き" },
	{ type: 'kamon', "id": "0711", "name": "丸の内に太一つ引き" },
	{ type: 'kamon', "id": "0712", "name": "丸に二つ引き" },
	{ type: 'kamon', "id": "0713", "name": "丸の内に二つ引き" },
	{ type: 'kamon', "id": "0714", "name": "丸の内に太二つ引き" },
	{ type: 'kamon', "id": "0715", "name": "七つ割二つ引き" },
	{ type: 'kamon', "id": "0716", "name": "丸に出二つ引き" },
	{ type: 'kamon', "id": "0717", "name": "丸に弾き二つ引き" },
	{ type: 'kamon', "id": "0718", "name": "丸に三つ引き" },
	{ type: 'kamon', "id": "0719", "name": "丸の内に三つ引き" },
	{ type: 'kamon', "id": "0720", "name": "三浦三つ引き" },
	{ type: 'kamon', "id": "0721", "name": "細輪の内に三つ引き" },
	{ type: 'kamon', "id": "0722", "name": "九つ割り三つ引き" },
	{ type: 'kamon', "id": "0723", "name": "菱に二つ引き" },
	{ type: 'kamon', "id": "0724", "name": "岩城たて引き" },
	{ type: 'kamon', "id": "0725", "name": "越前藤島神社" },
	{ type: 'kamon', "id": "0726", "name": "丸に竪一つ引" },
	{ type: 'kamon', "id": "0727", "name": "丸に竪二つ引" },
	{ type: 'kamon', "id": "0728", "name": "丸に竪三つ引" },
	{ type: 'kamon', "id": "0729", "name": "丸の内に竪三つ引" },
	{ type: 'kamon', "id": "0730", "name": "分部三つ引き" },
	{ type: 'kamon', "id": "0731", "name": "新田一つ引き" },
	{ type: 'kamon', "id": "0732", "name": "丸に揃い二つ引" },
	{ type: 'kamon', "id": "0733", "name": "六角に二つ引" },
	{ type: 'kamon', "id": "0734", "name": "菱に三つ引" },
	{ type: 'kamon', "id": "0735", "name": "糸輪に寄せ三つ引" },
	{ type: 'kamon', "id": "0736", "name": "丸の内に五つ引" },
	{ type: 'kamon', "id": "0737", "name": "丸に出一つ引" },
	{ type: 'kamon', "id": "0738", "name": "陰丸の内に二つ引" },
	{ type: 'kamon', "id": "0739", "name": "細輪に太二つ引" },
	{ type: 'kamon', "id": "0740", "name": "足利二つ引" },
	{ type: 'kamon', "id": "0741", "name": "丸の内に放し二つ引" },
	{ type: 'kamon', "id": "0742", "name": "揃い二つ引" },
	{ type: 'kamon', "id": "0743", "name": "陰菱に二つ引" },
	{ type: 'kamon', "id": "0744", "name": "田村竪引" },
	{ type: 'kamon', "id": "0745", "name": "丸の内に放し三つ引" },
	{ type: 'kamon', "id": "0746", "name": "中陰菱" },
	{ type: 'kamon', "id": "0747", "name": "子持ち菱" },
	{ type: 'kamon', "id": "0748", "name": "入れ子菱" },
	{ type: 'kamon', "id": "0749", "name": "違い菱" },
	{ type: 'kamon', "id": "0750", "name": "武田菱" },
	{ type: 'kamon', "id": "0751", "name": "丸に武田菱" },
	{ type: 'kamon', "id": "0752", "name": "陰武田菱" },
	{ type: 'kamon', "id": "0753", "name": "松皮菱" },
	{ type: 'kamon', "id": "0754", "name": "中輪に松皮菱" },
	{ type: 'kamon', "id": "0755", "name": "中陰松皮菱" },
	{ type: 'kamon', "id": "0756", "name": "石持ち地抜き松皮菱" },
	{ type: 'kamon', "id": "0757", "name": "三階菱" },
	{ type: 'kamon', "id": "0758", "name": "丸に三階菱" },
	{ type: 'kamon', "id": "0759", "name": "溝口菱" },
	{ type: 'kamon', "id": "0760", "name": "丸に重ね菱" },
	{ type: 'kamon', "id": "0761", "name": "丸に三つ菱" },
	{ type: 'kamon', "id": "0762", "name": "イ菱" },
	{ type: 'kamon', "id": "0763", "name": "三つ松皮菱" },
	{ type: 'kamon', "id": "0764", "name": "五つ松皮菱" },
	{ type: 'kamon', "id": "0765", "name": "五つ折り入り菱" },
	{ type: 'kamon', "id": "0766", "name": "幸菱" },
	{ type: 'kamon', "id": "0767", "name": "内田菱" },
	{ type: 'kamon', "id": "0768", "name": "山口菱" },
	{ type: 'kamon', "id": "0769", "name": "大内菱" },
	{ type: 'kamon', "id": "0770", "name": "兵庫広田神社" },
	{ type: 'kamon', "id": "0771", "name": "鉄砲菱" },
	{ type: 'kamon', "id": "0772", "name": "久慈菱" },
	{ type: 'kamon', "id": "0773", "name": "中陰松皮菱に片喰" },
	{ type: 'kamon', "id": "0774", "name": "京極菱桐" },
	{ type: 'kamon', "id": "0775", "name": "米倉菱" },
	{ type: 'kamon', "id": "0776", "name": "鉄砲松皮菱" },
	{ type: 'kamon', "id": "0777", "name": "鬼松皮菱" },
	{ type: 'kamon', "id": "0778", "name": "六つ内に三つ松皮菱" },
	{ type: 'kamon', "id": "0779", "name": "松皮菱に蔓柏" },
	{ type: 'kamon', "id": "0780", "name": "陰組み菱" },
	{ type: 'kamon', "id": "0781", "name": "総中陰松皮菱に蔦" },
	{ type: 'kamon', "id": "0782", "name": "三階菱に二つ太菱" },
	{ type: 'kamon', "id": "0783", "name": "四つ松皮菱" },
	{ type: 'kamon', "id": "0784", "name": "桔梗五つ菱" },
	{ type: 'kamon', "id": "0785", "name": "丸に変わり三つ菱" },
	{ type: 'kamon', "id": "0786", "name": "寄せ三つ菱" },
	{ type: 'kamon', "id": "0787", "name": "松皮菱桐" },
	{ type: 'kamon', "id": "0788", "name": "変わり松皮菱桐" },
	{ type: 'kamon', "id": "0789", "name": "中陰松皮菱に蔦" },
	{ type: 'kamon', "id": "0790", "name": "柳沢菱の葉" },
	{ type: 'kamon', "id": "0791", "name": "雪輪に覗き中陰三階菱" },
	{ type: 'kamon', "id": "0792", "name": "菱持ち" },
	{ type: 'kamon', "id": "0793", "name": "石持ち地抜き菱" },
	{ type: 'kamon', "id": "0794", "name": "太菱" },
	{ type: 'kamon', "id": "0795", "name": "糸菱" },
	{ type: 'kamon', "id": "0796", "name": "反り中菱" },
	{ type: 'kamon', "id": "0797", "name": "中輪に反り中菱" },
	{ type: 'kamon', "id": "0798", "name": "尨み菱" },
	{ type: 'kamon', "id": "0799", "name": "隅入り菱" },
	{ type: 'kamon', "id": "0800", "name": "楕円菱" },
	{ type: 'kamon', "id": "0801", "name": "四つ割り菱" },
	{ type: 'kamon', "id": "0802", "name": "石持ち地抜き武田菱" },
	{ type: 'kamon', "id": "0803", "name": "丸に中陰武田菱" },
	{ type: 'kamon', "id": "0804", "name": "丸に出武田菱" },
	{ type: 'kamon', "id": "0805", "name": "四つ反り菱" },
	{ type: 'kamon', "id": "0806", "name": "三つ盛り菱" },
	{ type: 'kamon', "id": "0807", "name": "四つ重ね菱" },
	{ type: 'kamon', "id": "0808", "name": "丸に四つ重ね菱" },
	{ type: 'kamon', "id": "0809", "name": "五つ重ね菱" },
	{ type: 'kamon', "id": "0810", "name": "陰四つ菱に松皮菱" },
	{ type: 'kamon', "id": "0811", "name": "九つ菱" },
	{ type: 'kamon', "id": "0812", "name": "五つ菱" },
	{ type: 'kamon', "id": "0813", "name": "市松菱" },
	{ type: 'kamon', "id": "0814", "name": "隅切り菱に五つ菱" },
	{ type: 'kamon', "id": "0815", "name": "折り入り菱" },
	{ type: 'kamon', "id": "0816", "name": "菱に梅" },
	{ type: 'kamon', "id": "0817", "name": "菱に花菱" },
	{ type: 'kamon', "id": "0818", "name": "菱に木瓜" },
	{ type: 'kamon', "id": "0819", "name": "四つ割り菱" },
	{ type: 'kamon', "id": "0820", "name": "三つ星" },
	{ type: 'kamon', "id": "0821", "name": "丸に三つ星" },
	{ type: 'kamon', "id": "0822", "name": "石持ち地抜き三つ星" },
	{ type: 'kamon', "id": "0823", "name": "剣三つ星" },
	{ type: 'kamon', "id": "0824", "name": "丸に剣三つ星" },
	{ type: 'kamon', "id": "0825", "name": "つなぎ剣三つ星" },
	{ type: 'kamon', "id": "0826", "name": "三つ星に一つ引き" },
	{ type: 'kamon', "id": "0827", "name": "渡辺星" },
	{ type: 'kamon', "id": "0828", "name": "丸に渡辺星" },
	{ type: 'kamon', "id": "0829", "name": "長門三つ星" },
	{ type: 'kamon', "id": "0830", "name": "四つ星" },
	{ type: 'kamon', "id": "0831", "name": "五つ星" },
	{ type: 'kamon', "id": "0832", "name": "六つ星" },
	{ type: 'kamon', "id": "0833", "name": "七曜" },
	{ type: 'kamon', "id": "0834", "name": "丸に七曜" },
	{ type: 'kamon', "id": "0835", "name": "陰七曜" },
	{ type: 'kamon', "id": "0836", "name": "八曜" },
	{ type: 'kamon', "id": "0837", "name": "九曜" },
	{ type: 'kamon', "id": "0838", "name": "丸に九曜" },
	{ type: 'kamon', "id": "0839", "name": "細川九曜" },
	{ type: 'kamon', "id": "0840", "name": "八曜に月" },
	{ type: 'kamon', "id": "0841", "name": "剣四つ星" },
	{ type: 'kamon', "id": "0842", "name": "中輪に八曜に巴" },
	{ type: 'kamon', "id": "0843", "name": "雪輪に九曜" },
	{ type: 'kamon', "id": "0844", "name": "十曜" },
	{ type: 'kamon', "id": "0845", "name": "糸輪に北斗星" },
	{ type: 'kamon', "id": "0846", "name": "陰三つ星" },
	{ type: 'kamon', "id": "0847", "name": "重ね三つ星" },
	{ type: 'kamon', "id": "0848", "name": "角八曜" },
	{ type: 'kamon', "id": "0849", "name": "銀星" },
	{ type: 'kamon', "id": "0850", "name": "糸輪に豆三つ星" },
	{ type: 'kamon', "id": "0851", "name": "雪輪に三つ星" },
	{ type: 'kamon', "id": "0852", "name": "松浦星" },
	{ type: 'kamon', "id": "0853", "name": "陰長門三つ星" },
	{ type: 'kamon', "id": "0854", "name": "府中三つ星" },
	{ type: 'kamon', "id": "0855", "name": "徳山三つ星" },
	{ type: 'kamon', "id": "0856", "name": "二文字に三つ星" },
	{ type: 'kamon', "id": "0857", "name": "陰渡辺星" },
	{ type: 'kamon', "id": "0859", "name": "三つ割り剣三つ星" },
	{ type: 'kamon', "id": "0860", "name": "重ね四つ星" },
	{ type: 'kamon', "id": "0861", "name": "重ね五つ星" },
	{ type: 'kamon', "id": "0862", "name": "丸に六つ星" },
	{ type: 'kamon', "id": "0863", "name": "離れ六つ星" },
	{ type: 'kamon', "id": "0864", "name": "重ね六つ星" },
	{ type: 'kamon', "id": "0865", "name": "石持ち地抜き七曜" },
	{ type: 'kamon', "id": "0866", "name": "石持ち地抜き離れ七曜" },
	{ type: 'kamon', "id": "0867", "name": "七曜に木文字" },
	{ type: 'kamon', "id": "0868", "name": "割り七曜" },
	{ type: 'kamon', "id": "0869", "name": "陰陽七曜" },
	{ type: 'kamon', "id": "0870", "name": "陰九曜" },
	{ type: 'kamon', "id": "0871", "name": "石持ち地抜き九曜" },
	{ type: 'kamon', "id": "0872", "name": "陰重ね九曜" },
	{ type: 'kamon', "id": "0873", "name": "結び九曜" },
	{ type: 'kamon', "id": "0874", "name": "割り九曜" },
	{ type: 'kamon', "id": "0875", "name": "抜け九曜" },
	{ type: 'kamon', "id": "0876", "name": "九曜車" },
	{ type: 'kamon', "id": "0877", "name": "角九曜" },
	{ type: 'kamon', "id": "0878", "name": "九曜菱" },
	{ type: 'kamon', "id": "0879", "name": "十一曜" },
	{ type: 'kamon', "id": "0880", "name": "丸に重ね星" },
	{ type: 'kamon', "id": "0881", "name": "土星" },
	{ type: 'kamon', "id": "0882", "name": "鳳凰の丸" },
	{ type: 'kamon', "id": "0883", "name": "舞い鳳凰丸" },
	{ type: 'kamon', "id": "0884", "name": "飛び鳳凰丸" },
	{ type: 'kamon', "id": "0885", "name": "鳳凰に桐" },
	{ type: 'kamon', "id": "0886", "name": "有職鳳凰" },
	{ type: 'kamon', "id": "0887", "name": "白鳳の丸" },
	{ type: 'kamon', "id": "0888", "name": "関鳳凰丸" },
	{ type: 'kamon', "id": "0889", "name": "鳳凰に変わり桐" },
	{ type: 'kamon', "id": "0890", "name": "寓生" },
	{ type: 'kamon', "id": "0891", "name": "三つ割り寓生" },
	{ type: 'kamon', "id": "0892", "name": "八つ割り寓生車" },
	{ type: 'kamon', "id": "0893", "name": "十六割り寓生花" },
	{ type: 'kamon', "id": "0894", "name": "寓生に対い鳩" },
	{ type: 'kamon', "id": "0895", "name": "丸に三つ割り寓生" },
	{ type: 'kamon', "id": "0896", "name": "三つ外寄せ寓生" },
	{ type: 'kamon', "id": "0897", "name": "熊谷抱き寓生" },
	{ type: 'kamon', "id": "0898", "name": "寓生枝丸" },
	{ type: 'kamon', "id": "0899", "name": "熊谷寓生" },
	{ type: 'kamon', "id": "0900", "name": "丸に瓢箪" },
	{ type: 'kamon', "id": "0901", "name": "二つ抱き瓢箪" },
	{ type: 'kamon', "id": "0902", "name": "丸に三つ盛り瓢箪" },
	{ type: 'kamon', "id": "0903", "name": "八つ瓢箪車" },
	{ type: 'kamon', "id": "0904", "name": "千成瓢箪" },
	{ type: 'kamon', "id": "0905", "name": "抱き瓢箪" },
	{ type: 'kamon', "id": "0906", "name": "割り対い瓢箪" },
	{ type: 'kamon', "id": "0907", "name": "三つ寄せ瓢箪" },
	{ type: 'kamon', "id": "0908", "name": "割り瓢箪菱" },
	{ type: 'kamon', "id": "0909", "name": "八つ捻じ瓢箪" },
	{ type: 'kamon', "id": "0910", "name": "一つ瓢箪の丸" },
	{ type: 'kamon', "id": "0911", "name": "並び瓢箪" },
	{ type: 'kamon', "id": "0912", "name": "陰三つ寄せ瓢箪" },
	{ type: 'kamon', "id": "0913", "name": "四つ追い瓢箪菱" },
	{ type: 'kamon', "id": "0914", "name": "五つ瓢箪" },
	{ type: 'kamon', "id": "0915", "name": "三つ銀杏" },
	{ type: 'kamon', "id": "0916", "name": "丸に三つ銀杏" },
	{ type: 'kamon', "id": "0917", "name": "糸輪に豆三つ銀杏" },
	{ type: 'kamon', "id": "0918", "name": "陰三つ銀杏" },
	{ type: 'kamon', "id": "0919", "name": "軸違い三つ銀杏" },
	{ type: 'kamon', "id": "0920", "name": "丸に剣三つ銀杏" },
	{ type: 'kamon', "id": "0921", "name": "三つ割銀杏" },
	{ type: 'kamon', "id": "0922", "name": "六角三つ銀杏" },
	{ type: 'kamon', "id": "0923", "name": "中輪に立ち銀杏" },
	{ type: 'kamon', "id": "0924", "name": "糸輪に一つ銀杏" },
	{ type: 'kamon', "id": "0925", "name": "違い銀杏" },
	{ type: 'kamon', "id": "0926", "name": "二つ追いかけ銀杏" },
	{ type: 'kamon', "id": "0927", "name": "抱き銀杏" },
	{ type: 'kamon', "id": "0928", "name": "比翼銀杏" },
	{ type: 'kamon', "id": "0929", "name": "糸輪に比翼銀杏" },
	{ type: 'kamon', "id": "0930", "name": "四つ銀杏" },
	{ type: 'kamon', "id": "0931", "name": "五つ銀杏" },
	{ type: 'kamon', "id": "0932", "name": "三つ組み銀杏" },
	{ type: 'kamon', "id": "0933", "name": "抱き割り銀杏" },
	{ type: 'kamon', "id": "0934", "name": "銀杏枝菱" },
	{ type: 'kamon', "id": "0935", "name": "銀杏揚羽蝶" },
	{ type: 'kamon', "id": "0936", "name": "変わり銀杏枝丸" },
	{ type: 'kamon', "id": "0937", "name": "丸に銀杏鶴" },
	{ type: 'kamon', "id": "0938", "name": "銀杏鶴" },
	{ type: 'kamon', "id": "0939", "name": "入れ違い銀杏" },
	{ type: 'kamon', "id": "0940", "name": "変わり三つ銀杏" },
	{ type: 'kamon', "id": "0941", "name": "違い銀杏（２）" },
	{ type: 'kamon', "id": "0942", "name": "中村銀杏" },
	{ type: 'kamon', "id": "0943", "name": "立ち銀杏の丸" },
	{ type: 'kamon', "id": "0944", "name": "丸に変わり一つ銀杏" },
	{ type: 'kamon', "id": "0945", "name": "糸菱に覗き銀杏" },
	{ type: 'kamon', "id": "0946", "name": "一つ巴銀杏" },
	{ type: 'kamon', "id": "0947", "name": "入れ違え銀杏（２）" },
	{ type: 'kamon', "id": "0948", "name": "銀杏蝶" },
	{ type: 'kamon', "id": "0949", "name": "銀杏胡蝶" },
	{ type: 'kamon', "id": "0950", "name": "二葉枝銀杏" },
	{ type: 'kamon', "id": "0951", "name": "二つ銀杏菱" },
	{ type: 'kamon', "id": "0952", "name": "蔓銀杏菱" },
	{ type: 'kamon', "id": "0953", "name": "変わり二つ銀杏" },
	{ type: 'kamon', "id": "0954", "name": "対かい銀杏菱" },
	{ type: 'kamon', "id": "0955", "name": "対かい銀杏" },
	{ type: 'kamon', "id": "0956", "name": "浮線銀杏に花菱" },
	{ type: 'kamon', "id": "0957", "name": "浮線銀杏" },
	{ type: 'kamon', "id": "0958", "name": "二つ剣銀杏" },
	{ type: 'kamon', "id": "0959", "name": "丸に重ね銀杏" },
	{ type: 'kamon', "id": "0960", "name": "三つ銀杏菱" },
	{ type: 'kamon', "id": "0961", "name": "三つ立ち銀杏" },
	{ type: 'kamon', "id": "0962", "name": "三つ立ち軸違い銀杏" },
	{ type: 'kamon', "id": "0963", "name": "三つ落ち銀杏" },
	{ type: 'kamon', "id": "0964", "name": "三つ寄せ銀杏" },
	{ type: 'kamon', "id": "0965", "name": "四つ割り追い銀杏" },
	{ type: 'kamon', "id": "0966", "name": "軸違い三つ寄せ蔓銀杏" },
	{ type: 'kamon', "id": "0967", "name": "六角三つ割り銀杏" },
	{ type: 'kamon', "id": "0968", "name": "変わり組み合わせ三つ銀杏" },
	{ type: 'kamon', "id": "0969", "name": "三つ銀杏崩し" },
	{ type: 'kamon', "id": "0970", "name": "三つ割り追い銀杏に花菱" },
	{ type: 'kamon', "id": "0971", "name": "頭合わせ三つ銀杏" },
	{ type: 'kamon', "id": "0972", "name": "三つ割り重ね銀杏" },
	{ type: 'kamon', "id": "0973", "name": "三つ追い銀杏" },
	{ type: 'kamon', "id": "0974", "name": "中陰三つ銀杏" },
	{ type: 'kamon', "id": "0975", "name": "三つ銀杏に片喰" },
	{ type: 'kamon', "id": "0976", "name": "糸輪に三つ蔓銀杏" },
	{ type: 'kamon', "id": "0977", "name": "丸に三つ銀杏崩し" },
	{ type: 'kamon', "id": "0978", "name": "子持ち三つ銀杏" },
	{ type: 'kamon', "id": "0979", "name": "雪輪に三つ銀杏" },
	{ type: 'kamon', "id": "0980", "name": "三つ追い銀杏に剣片喰" },
	{ type: 'kamon', "id": "0981", "name": "三つ追い銀杏に片喰" },
	{ type: 'kamon', "id": "0982", "name": "三つ扇銀杏" },
	{ type: 'kamon', "id": "0983", "name": "剣銀杏" },
	{ type: 'kamon', "id": "0984", "name": "二つ葉枝銀杏" },
	{ type: 'kamon', "id": "0985", "name": "石持ち地抜き三つ銀杏" },
	{ type: 'kamon', "id": "0986", "name": "菱に三つ銀杏" },
	{ type: 'kamon', "id": "0987", "name": "銀杏枝丸" },
	{ type: 'kamon', "id": "0988", "name": "五つ銀杏車" },
	{ type: 'kamon', "id": "0989", "name": "軸違い五つ銀杏車" },
	{ type: 'kamon', "id": "0990", "name": "五つ追い銀杏に花菱" },
	{ type: 'kamon', "id": "0991", "name": "六つ鐶に陰立ち銀杏" },
	{ type: 'kamon', "id": "0992", "name": "六つ銀杏車" },
	{ type: 'kamon', "id": "0993", "name": "飛鳥井銀杏" },
	{ type: 'kamon', "id": "0994", "name": "十六銀杏" },
	{ type: 'kamon', "id": "0995", "name": "細輪に三つ割り井桁" },
	{ type: 'kamon', "id": "0996", "name": "井桁" },
	{ type: 'kamon', "id": "0997", "name": "丸に井桁" },
	{ type: 'kamon', "id": "0998", "name": "糸輪に井桁" },
	{ type: 'kamon', "id": "0999", "name": "陰井桁" },
	{ type: 'kamon', "id": "1000", "name": "太井桁" },
	{ type: 'kamon', "id": "1001", "name": "組み井桁" },
	{ type: 'kamon', "id": "1002", "name": "子持ち組み井桁" },
	{ type: 'kamon', "id": "1003", "name": "三角井桁" },
	{ type: 'kamon', "id": "1004", "name": "井桁に木瓜" },
	{ type: 'kamon', "id": "1005", "name": "三つ寄せ井桁" },
	{ type: 'kamon', "id": "1006", "name": "重ね井桁" },
	{ type: 'kamon', "id": "1007", "name": "違い井桁" },
	{ type: 'kamon', "id": "1008", "name": "唐井桁" },
	{ type: 'kamon', "id": "1009", "name": "三つ盛り井桁" },
	{ type: 'kamon', "id": "1010", "name": "結び井桁菱" },
	{ type: 'kamon', "id": "1011", "name": "反り井桁" },
	{ type: 'kamon', "id": "1012", "name": "腰低細井桁" },
	{ type: 'kamon', "id": "1013", "name": "石持ち地抜き井桁" },
	{ type: 'kamon', "id": "1014", "name": "太井桁に花菱" },
	{ type: 'kamon', "id": "1015", "name": "井桁に覗き桔梗" },
	{ type: 'kamon', "id": "1016", "name": "井桁に三つ巴" },
	{ type: 'kamon', "id": "1017", "name": "上下組み合わせ井桁" },
	{ type: 'kamon', "id": "1018", "name": "三つ持ち合い組み井桁" },
	{ type: 'kamon', "id": "1019", "name": "中輪に持ち合い三つ井桁" },
	{ type: 'kamon', "id": "1020", "name": "中輪に反り結び井桁" },
	{ type: 'kamon', "id": "1021", "name": "細輪に井桁崩し" },
	{ type: 'kamon', "id": "1022", "name": "糸輪に籠目井桁" },
	{ type: 'kamon', "id": "1023", "name": "糸輪に五つ井桁" },
	{ type: 'kamon', "id": "1024", "name": "八角井筒に井桁" },
	{ type: 'kamon', "id": "1025", "name": "井桁桐" },
	{ type: 'kamon', "id": "1026", "name": "四つイ菱" },
	{ type: 'kamon', "id": "1027", "name": "庵井桁星" },
	{ type: 'kamon', "id": "1028", "name": "中輪に汽船錨" },
	{ type: 'kamon', "id": "1029", "name": "四つ錨" },
	{ type: 'kamon', "id": "1030", "name": "三つ綱付き錨" },
	{ type: 'kamon', "id": "1031", "name": "碇" },
	{ type: 'kamon', "id": "1032", "name": "二つ碇" },
	{ type: 'kamon', "id": "1033", "name": "二つ割り追い碇" },
	{ type: 'kamon', "id": "1034", "name": "碇片喰" },
	{ type: 'kamon', "id": "1035", "name": "碇桜" },
	{ type: 'kamon', "id": "1036", "name": "碇桐" },
	{ type: 'kamon', "id": "1037", "name": "団扇碇" },
	{ type: 'kamon', "id": "1038", "name": "二つ子持ち碇" },
	{ type: 'kamon', "id": "1039", "name": "汽船碇" },
	{ type: 'kamon', "id": "1040", "name": "海軍碇" },
	{ type: 'kamon', "id": "1041", "name": "丸に海軍碇" },
	{ type: 'kamon', "id": "1042", "name": "綱付き碇" },
	{ type: 'kamon', "id": "1043", "name": "三つ割り追い碇" },
	{ type: 'kamon', "id": "1044", "name": "変わり三つ碇" },
	{ type: 'kamon', "id": "1045", "name": "浮線碇" },
	{ type: 'kamon', "id": "1046", "name": "平稲妻" },
	{ type: 'kamon', "id": "1047", "name": "隅立て稲妻" },
	{ type: 'kamon', "id": "1048", "name": "丸に隅立て稲妻" },
	{ type: 'kamon', "id": "1049", "name": "隅立て絡み稲妻" },
	{ type: 'kamon', "id": "1050", "name": "稲妻菱" },
	{ type: 'kamon', "id": "1051", "name": "鱗稲妻" },
	{ type: 'kamon', "id": "1052", "name": "糸輪に立鼓稲妻" },
	{ type: 'kamon', "id": "1053", "name": "稲妻鶴" },
	{ type: 'kamon', "id": "1054", "name": "三つ寄り稲妻菱" },
	{ type: 'kamon', "id": "1055", "name": "四つ稲妻菱" },
	{ type: 'kamon', "id": "1056", "name": "中輪に稲妻菱" },
	{ type: 'kamon', "id": "1057", "name": "隅立て紗綾" },
	{ type: 'kamon', "id": "1058", "name": "三つ鱗稲妻" },
	{ type: 'kamon', "id": "1059", "name": "稲妻三つ巴" },
	{ type: 'kamon', "id": "1060", "name": "八つ稲妻" },
	{ type: 'kamon', "id": "1061", "name": "変わり稲妻" },
	{ type: 'kamon', "id": "1062", "name": "地紙形稲妻" },
	{ type: 'kamon', "id": "1063", "name": "三つ盛り平稲妻" },
	{ type: 'kamon', "id": "1064", "name": "五つ角稲妻" },
	{ type: 'kamon', "id": "1065", "name": "電光稲妻" },
	{ type: 'kamon', "id": "1066", "name": "丸に平稲妻" },
	{ type: 'kamon', "id": "1067", "name": "石持ち地抜き稲妻菱" },
	{ type: 'kamon', "id": "1069", "name": "釘抜き形稲妻" },
	{ type: 'kamon', "id": "1070", "name": "反り稲妻菱" },
	{ type: 'kamon', "id": "1071", "name": "鱗入り三角稲妻" },
	{ type: 'kamon', "id": "1072", "name": "稲妻崩し" },
	{ type: 'kamon', "id": "1073", "name": "隅立て結び稲妻" },
	{ type: 'kamon', "id": "1074", "name": "細輪に四つ稲妻" },
	{ type: 'kamon', "id": "1075", "name": "重ね稲妻" },
	{ type: 'kamon', "id": "1076", "name": "三つ稲妻" },
	{ type: 'kamon', "id": "1077", "name": "三つ稲妻菱" },
	{ type: 'kamon', "id": "1078", "name": "三つ石稲妻" },
	{ type: 'kamon', "id": "1079", "name": "隅合わせ三つ稲妻" },
	{ type: 'kamon', "id": "1080", "name": "五つ稲妻" },
	{ type: 'kamon', "id": "1081", "name": "五つ市松稲妻" },
	{ type: 'kamon', "id": "1082", "name": "釘抜き稲妻" },
	{ type: 'kamon', "id": "1083", "name": "重ね稲妻菱" },
	{ type: 'kamon', "id": "1084", "name": "三階稲妻菱" },
	{ type: 'kamon', "id": "1085", "name": "電光四つ稲妻" },
	{ type: 'kamon', "id": "1086", "name": "電光稲妻車" },
	{ type: 'kamon', "id": "1087", "name": "糸輪に五つ稲妻菱" },
	{ type: 'kamon', "id": "1088", "name": "六角稲妻" },
	{ type: 'kamon', "id": "1089", "name": "七角稲妻" },
	{ type: 'kamon', "id": "1090", "name": "尨み稲妻" },
	{ type: 'kamon', "id": "1091", "name": "四つ寄せ稲妻" },
	{ type: 'kamon', "id": "1092", "name": "六つ稲妻菱" },
	{ type: 'kamon', "id": "1093", "name": "捻じ傘稲妻菱" },
	{ type: 'kamon', "id": "1094", "name": "稲妻松皮" },
	{ type: 'kamon', "id": "1095", "name": "稲妻蝶" },
	{ type: 'kamon', "id": "1096", "name": "稲妻桐" },
	{ type: 'kamon', "id": "1097", "name": "右回り稲の丸" },
	{ type: 'kamon', "id": "1098", "name": "左回り稲の丸" },
	{ type: 'kamon', "id": "1099", "name": "稲荷抱き稲（２）" },
	{ type: 'kamon', "id": "1100", "name": "抱き稲" },
	{ type: 'kamon', "id": "1101", "name": "丸に変わり結び稲" },
	{ type: 'kamon', "id": "1102", "name": "二つ追い掛け稲の丸" },
	{ type: 'kamon', "id": "1103", "name": "変わり包み抱き稲" },
	{ type: 'kamon', "id": "1104", "name": "抱き稲に三つ星" },
	{ type: 'kamon', "id": "1105", "name": "稲鶴" },
	{ type: 'kamon', "id": "1106", "name": "二つ追い稲菱" },
	{ type: 'kamon', "id": "1107", "name": "糸輪に立稲" },
	{ type: 'kamon', "id": "1108", "name": "稲荷抱き稲" },
	{ type: 'kamon', "id": "1109", "name": "一本稲の丸" },
	{ type: 'kamon', "id": "1110", "name": "抱き稲に武田菱" },
	{ type: 'kamon', "id": "1111", "name": "井桁に二つ稲穂の丸" },
	{ type: 'kamon', "id": "1112", "name": "稲揚羽蝶" },
	{ type: 'kamon', "id": "1113", "name": "二つ稲穂の丸" },
	{ type: 'kamon', "id": "1114", "name": "二つ穂変わり抱き稲" },
	{ type: 'kamon', "id": "1115", "name": "垂れ穂抱き稲" },
	{ type: 'kamon', "id": "1116", "name": "抱き稲に鎌" },
	{ type: 'kamon', "id": "1117", "name": "抱き稲に桔梗" },
	{ type: 'kamon', "id": "1118", "name": "変わり抱き稲" },
	{ type: 'kamon', "id": "1119", "name": "包み違い稲" },
	{ type: 'kamon', "id": "1120", "name": "包み抱き変わり稲" },
	{ type: 'kamon', "id": "1121", "name": "変わり包み稲" },
	{ type: 'kamon', "id": "1122", "name": "抱き結び稲" },
	{ type: 'kamon', "id": "1123", "name": "結び付き抱き稲" },
	{ type: 'kamon', "id": "1124", "name": "中輪に変わり違い稲" },
	{ type: 'kamon', "id": "1125", "name": "丸に一本稲" },
	{ type: 'kamon', "id": "1126", "name": "中輪に結び付き変わり稲" },
	{ type: 'kamon', "id": "1127", "name": "抱き稲苗" },
	{ type: 'kamon', "id": "1128", "name": "糸輪に束ね稲" },
	{ type: 'kamon', "id": "1129", "name": "一本稲の丸に雀" },
	{ type: 'kamon', "id": "1130", "name": "丸に変わり抱き稲の笠" },
	{ type: 'kamon', "id": "1131", "name": "抱き稲に対かい雀" },
	{ type: 'kamon', "id": "1132", "name": "抱き稲に片喰" },
	{ type: 'kamon', "id": "1133", "name": "抱き稲に花菱" },
	{ type: 'kamon', "id": "1134", "name": "抱き稲に三つ銀杏" },
	{ type: 'kamon', "id": "1135", "name": "抱き稲に梅鉢" },
	{ type: 'kamon', "id": "1136", "name": "抱き稲に橘" },
	{ type: 'kamon', "id": "1137", "name": "違い稲" },
	{ type: 'kamon', "id": "1138", "name": "対い稲" },
	{ type: 'kamon', "id": "1139", "name": "一本稲" },
	{ type: 'kamon', "id": "1140", "name": "庵" },
	{ type: 'kamon', "id": "1141", "name": "盃庵" },
	{ type: 'kamon', "id": "1142", "name": "利休庵" },
	{ type: 'kamon', "id": "1143", "name": "花形庵" },
	{ type: 'kamon', "id": "1144", "name": "丸に変わり庵" },
	{ type: 'kamon', "id": "1145", "name": "大草庵" },
	{ type: 'kamon', "id": "1146", "name": "頭合わせ三つ庵" },
	{ type: 'kamon', "id": "1147", "name": "三つ石" },
	{ type: 'kamon', "id": "1148", "name": "細輪に文様" },
	{ type: 'kamon', "id": "1149", "name": "立て三つ石" },
	{ type: 'kamon', "id": "1150", "name": "三つ寄せ石" },
	{ type: 'kamon', "id": "1151", "name": "細輪に隅合わせ三つ石" },
	{ type: 'kamon', "id": "1152", "name": "四つ石" },
	{ type: 'kamon', "id": "1153", "name": "丸に四つ石" },
	{ type: 'kamon', "id": "1154", "name": "四つ喰入れ石" },
	{ type: 'kamon', "id": "1155", "name": "丸に四つ割り石" },
	{ type: 'kamon', "id": "1156", "name": "中輪に五つ寄せ石" },
	{ type: 'kamon', "id": "1157", "name": "つなぎ九つ石" },
	{ type: 'kamon', "id": "1158", "name": "丸に一つ石" },
	{ type: 'kamon', "id": "1159", "name": "四つ捻じ石" },
	{ type: 'kamon', "id": "1160", "name": "丸に平五つ石" },
	{ type: 'kamon', "id": "1161", "name": "糸輪に隅立て居五つ星" },
	{ type: 'kamon', "id": "1162", "name": "繋ぎ平九つ石" },
	{ type: 'kamon', "id": "1163", "name": "陰四つ石" },
	{ type: 'kamon', "id": "1164", "name": "石車" },
	{ type: 'kamon', "id": "1165", "name": "中輪に六つ積み石" },
	{ type: 'kamon', "id": "1166", "name": "陰竪三つ石" },
	{ type: 'kamon', "id": "1167", "name": "石持ち地抜き四つ石" },
	{ type: 'kamon', "id": "1168", "name": "糸輪に三つ割りの三つ石" },
	{ type: 'kamon', "id": "1169", "name": "糸輪に三つ石" },
	{ type: 'kamon', "id": "1170", "name": "丸に角に地抜き竪三つ石" },
	{ type: 'kamon', "id": "1171", "name": "総陰丸に隅立て四つ石" },
	{ type: 'kamon', "id": "1172", "name": "糸巻き" },
	{ type: 'kamon', "id": "1173", "name": "組み糸巻き" },
	{ type: 'kamon', "id": "1174", "name": "枠糸巻き" },
	{ type: 'kamon', "id": "1175", "name": "違い枠糸巻き" },
	{ type: 'kamon', "id": "1176", "name": "三つ枠糸巻き" },
	{ type: 'kamon', "id": "1177", "name": "尨糸巻" },
	{ type: 'kamon', "id": "1178", "name": "重ね糸巻板" },
	{ type: 'kamon', "id": "1179", "name": "組み糸巻板" },
	{ type: 'kamon', "id": "1180", "name": "三つ重ね糸巻板" },
	{ type: 'kamon', "id": "1181", "name": "三つ盛り糸巻" },
	{ type: 'kamon', "id": "1182", "name": "平井筒" },
	{ type: 'kamon', "id": "1183", "name": "丸に平井筒" },
	{ type: 'kamon', "id": "1184", "name": "隅立て井筒" },
	{ type: 'kamon', "id": "1185", "name": "丸に隅立て井筒" },
	{ type: 'kamon', "id": "1186", "name": "丸に隅立て太井筒" },
	{ type: 'kamon', "id": "1187", "name": "隅立て組み井筒" },
	{ type: 'kamon', "id": "1188", "name": "違い井筒" },
	{ type: 'kamon', "id": "1189", "name": "五角井筒に星" },
	{ type: 'kamon', "id": "1190", "name": "七角井筒に左三つ巴" },
	{ type: 'kamon', "id": "1191", "name": "隅立て撫で井筒" },
	{ type: 'kamon', "id": "1192", "name": "比翼井筒" },
	{ type: 'kamon', "id": "1193", "name": "組み合わせ井筒に四つ目" },
	{ type: 'kamon', "id": "1194", "name": "陰井筒" },
	{ type: 'kamon', "id": "1195", "name": "丸に隅立て四つ井筒" },
	{ type: 'kamon', "id": "1196", "name": "彦根井筒" },
	{ type: 'kamon', "id": "1197", "name": "石持ち地抜き井筒" },
	{ type: 'kamon', "id": "1198", "name": "組み井筒" },
	{ type: 'kamon', "id": "1199", "name": "陰隅立て井筒" },
	{ type: 'kamon', "id": "1200", "name": "丸に持ち合い隅立て井筒" },
	{ type: 'kamon', "id": "1201", "name": "丸に持ち合い井筒" },
	{ type: 'kamon', "id": "1202", "name": "三つ寄せ井筒" },
	{ type: 'kamon', "id": "1203", "name": "糸輪に隅立て折り入り井筒" },
	{ type: 'kamon', "id": "1204", "name": "丸に隅立て結び井筒" },
	{ type: 'kamon', "id": "1205", "name": "変わり折り込み井筒" },
	{ type: 'kamon', "id": "1206", "name": "隅立て折り込み井筒" },
	{ type: 'kamon', "id": "1207", "name": "隅立て七つ割り井筒崩し" },
	{ type: 'kamon', "id": "1208", "name": "輪違い井筒" },
	{ type: 'kamon', "id": "1209", "name": "折り込み井筒" },
	{ type: 'kamon', "id": "1210", "name": "変わり折り井筒" },
	{ type: 'kamon', "id": "1211", "name": "唐井筒" },
	{ type: 'kamon', "id": "1212", "name": "重ね井筒" },
	{ type: 'kamon', "id": "1213", "name": "六角花井筒" },
	{ type: 'kamon', "id": "1214", "name": "平井筒崩し" },
	{ type: 'kamon', "id": "1215", "name": "井筒崩し" },
	{ type: 'kamon', "id": "1216", "name": "石持ち井筒崩し" },
	{ type: 'kamon', "id": "1217", "name": "変わり井筒崩し" },
	{ type: 'kamon', "id": "1218", "name": "組み合わせ井筒" },
	{ type: 'kamon', "id": "1219", "name": "七宝井筒" },
	{ type: 'kamon', "id": "1220", "name": "七宝崩しに井筒" },
	{ type: 'kamon', "id": "1221", "name": "丸に折れ井筒" },
	{ type: 'kamon', "id": "1222", "name": "糸輪に五角井筒に轡" },
	{ type: 'kamon', "id": "1223", "name": "六角井筒に蛇の目" },
	{ type: 'kamon', "id": "1224", "name": "六角井筒に一つ左巴" },
	{ type: 'kamon', "id": "1225", "name": "重ね六角井筒" },
	{ type: 'kamon', "id": "1226", "name": "業平井筒" },
	{ type: 'kamon', "id": "1227", "name": "陰蛇の目" },
	{ type: 'kamon', "id": "1228", "name": "蛇の目七曜" },
	{ type: 'kamon', "id": "1229", "name": "蛇の目九曜" },
	{ type: 'kamon', "id": "1230", "name": "剣三つ蛇の目" },
	{ type: 'kamon', "id": "1231", "name": "蛇の目" },
	{ type: 'kamon', "id": "1232", "name": "比翼蛇の目" },
	{ type: 'kamon', "id": "1233", "name": "三つ盛り蛇の目" },
	{ type: 'kamon', "id": "1234", "name": "剣五つ蛇の目" },
	{ type: 'kamon', "id": "1235", "name": "糸輪に蛇の目崩し" },
	{ type: 'kamon', "id": "1236", "name": "丸に蛇の目" },
	{ type: 'kamon', "id": "1237", "name": "四つ蛇の目" },
	{ type: 'kamon', "id": "1238", "name": "糸輪に覗き蛇の目" },
	{ type: 'kamon', "id": "1239", "name": "中輪に地紙" },
	{ type: 'kamon', "id": "1240", "name": "丸に陰の地紙" },
	{ type: 'kamon', "id": "1241", "name": "三つ地紙" },
	{ type: 'kamon', "id": "1242", "name": "丸に三つ地紙" },
	{ type: 'kamon', "id": "1243", "name": "丸に重ね地紙" },
	{ type: 'kamon', "id": "1244", "name": "陰陽地紙" },
	{ type: 'kamon', "id": "1245", "name": "三つ重ね地紙" },
	{ type: 'kamon', "id": "1246", "name": "三つ捻じ地紙" },
	{ type: 'kamon', "id": "1247", "name": "三つ地紙に釘抜" },
	{ type: 'kamon', "id": "1248", "name": "地紙に三階菱" },
	{ type: 'kamon', "id": "1249", "name": "中輪に地紙に地抜き違い松葉" },
	{ type: 'kamon', "id": "1250", "name": "中陰地紙に桔梗" },
	{ type: 'kamon', "id": "1251", "name": "重ね地紙" },
	{ type: 'kamon', "id": "1252", "name": "丸に陰重ね地紙" },
	{ type: 'kamon', "id": "1253", "name": "巻き地紙" },
	{ type: 'kamon', "id": "1254", "name": "丸に頭合わせ三つ地紙" },
	{ type: 'kamon', "id": "1255", "name": "三つ地紙に九曜" },
	{ type: 'kamon', "id": "1256", "name": "鳥兜" },
	{ type: 'kamon', "id": "1257", "name": "鍬形兜" },
	{ type: 'kamon', "id": "1258", "name": "後ろ向き兜" },
	{ type: 'kamon', "id": "1259", "name": "破軍星立て兜" },
	{ type: 'kamon', "id": "1260", "name": "箙兜" },
	{ type: 'kamon', "id": "1261", "name": "竜頭兜" },
	{ type: 'kamon', "id": "1262", "name": "飛龍兜" },
	{ type: 'kamon', "id": "1263", "name": "日月兜" },
	{ type: 'kamon', "id": "1264", "name": "柏前立て兜" },
	{ type: 'kamon', "id": "1265", "name": "新田兜" },
	{ type: 'kamon', "id": "1266", "name": "御幣兜" },
	{ type: 'kamon', "id": "1267", "name": "加藤兜" },
	{ type: 'kamon', "id": "1268", "name": "立烏帽子兜" },
	{ type: 'kamon', "id": "1269", "name": "丸に楓" },
	{ type: 'kamon', "id": "1270", "name": "三つ割楓" },
	{ type: 'kamon', "id": "1271", "name": "尻合わせ三つ楓" },
	{ type: 'kamon', "id": "1272", "name": "楓に水" },
	{ type: 'kamon', "id": "1273", "name": "一つ楓" },
	{ type: 'kamon', "id": "1274", "name": "抱き枝楓" },
	{ type: 'kamon', "id": "1275", "name": "実付き三つ楓" },
	{ type: 'kamon', "id": "1276", "name": "杏葉楓" },
	{ type: 'kamon', "id": "1277", "name": "楓揚羽蝶" },
	{ type: 'kamon', "id": "1278", "name": "陰楓" },
	{ type: 'kamon', "id": "1279", "name": "違い楓" },
	{ type: 'kamon', "id": "1280", "name": "丸に立ち楓" },
	{ type: 'kamon', "id": "1281", "name": "散り楓" },
	{ type: 'kamon', "id": "1282", "name": "楓の枝丸" },
	{ type: 'kamon', "id": "1283", "name": "石持ち地抜き楓" },
	{ type: 'kamon', "id": "1284", "name": "雪輪に楓" },
	{ type: 'kamon', "id": "1285", "name": "糸輪に覗き楓" },
	{ type: 'kamon', "id": "1286", "name": "糸菱に総覗き楓" },
	{ type: 'kamon', "id": "1287", "name": "菱楓" },
	{ type: 'kamon', "id": "1288", "name": "糸輪に三つ楓" },
	{ type: 'kamon', "id": "1289", "name": "四つ楓菱" },
	{ type: 'kamon', "id": "1290", "name": "抱き割り楓" },
	{ type: 'kamon', "id": "1291", "name": "抱き楓" },
	{ type: 'kamon', "id": "1292", "name": "変わり抱き楓" },
	{ type: 'kamon', "id": "1293", "name": "楓に角" },
	{ type: 'kamon', "id": "1294", "name": "楓に菱" },
	{ type: 'kamon', "id": "1295", "name": "楓桐" },
	{ type: 'kamon', "id": "1296", "name": "楓胡蝶" },
	{ type: 'kamon', "id": "1297", "name": "変わり楓蝶" },
	{ type: 'kamon', "id": "1298", "name": "抱き楓蝶" },
	{ type: 'kamon', "id": "1299", "name": "枝楓" },
	{ type: 'kamon', "id": "1300", "name": "楓丸" },
	{ type: 'kamon', "id": "1301", "name": "丸に三つ鏡" },
	{ type: 'kamon', "id": "1302", "name": "三種神器" },
	{ type: 'kamon', "id": "1303", "name": "鍵桐" },
	{ type: 'kamon', "id": "1304", "name": "二つ鍵菱" },
	{ type: 'kamon', "id": "1305", "name": "丸に鍵" },
	{ type: 'kamon', "id": "1306", "name": "中輪に瓢箪鍵" },
	{ type: 'kamon', "id": "1307", "name": "丸に房付き鍵" },
	{ type: 'kamon', "id": "1308", "name": "糸輪に違い鍵" },
	{ type: 'kamon', "id": "1309", "name": "籠目" },
	{ type: 'kamon', "id": "1310", "name": "丸にいたら貝" },
	{ type: 'kamon', "id": "1311", "name": "二葉いたら貝" },
	{ type: 'kamon', "id": "1314", "name": "糸菱に覗きいたら貝" },
	{ type: 'kamon', "id": "1315", "name": "六角地抜きいたら貝" },
	{ type: 'kamon', "id": "1316", "name": "浮線いたら貝" },
	{ type: 'kamon', "id": "1317", "name": "いたら貝車" },
	{ type: 'kamon', "id": "1318", "name": "二ついたら貝" },
	{ type: 'kamon', "id": "1320", "name": "三ついたら貝" },
	{ type: 'kamon', "id": "1321", "name": "頭合わせ三ついたら貝" },
	{ type: 'kamon', "id": "1322", "name": "三つ割り追いいたら貝" },
	{ type: 'kamon', "id": "1323", "name": "糸輪に四ついたら貝" },
	{ type: 'kamon', "id": "1324", "name": "五ついたら貝" },
	{ type: 'kamon', "id": "1325", "name": "入れ違い割りいたら貝" },
	{ type: 'kamon', "id": "1326", "name": "いたら貝蝶" },
	{ type: 'kamon', "id": "1327", "name": "兜貝" },
	{ type: 'kamon', "id": "1328", "name": "違い巻貝" },
	{ type: 'kamon', "id": "1329", "name": "丸に二つ巻貝" },
	{ type: 'kamon', "id": "1330", "name": "三つ追い巻貝" },
	{ type: 'kamon', "id": "1331", "name": "立ち梶の葉" },
	{ type: 'kamon', "id": "1332", "name": "丸に立ち梶の葉" },
	{ type: 'kamon', "id": "1333", "name": "丸に立ち鬼梶の葉" },
	{ type: 'kamon', "id": "1334", "name": "抱き梶の葉" },
	{ type: 'kamon', "id": "1335", "name": "丸に抱き梶の葉" },
	{ type: 'kamon', "id": "1336", "name": "諏訪梶の葉" },
	{ type: 'kamon', "id": "1337", "name": "信濃諏訪神社" },
	{ type: 'kamon', "id": "1338", "name": "平戸梶の葉" },
	{ type: 'kamon', "id": "1339", "name": "鬼梶の葉" },
	{ type: 'kamon', "id": "1340", "name": "梶の花" },
	{ type: 'kamon', "id": "1341", "name": "杏葉梶" },
	{ type: 'kamon', "id": "1342", "name": "梶の葉桐" },
	{ type: 'kamon', "id": "1343", "name": "陰立ち梶の葉" },
	{ type: 'kamon', "id": "1344", "name": "入れ違い梶の葉" },
	{ type: 'kamon', "id": "1345", "name": "三つ割り梶の葉" },
	{ type: 'kamon', "id": "1346", "name": "枝梶の葉" },
	{ type: 'kamon', "id": "1347", "name": "二枚葉蔓鬼梶" },
	{ type: 'kamon', "id": "1348", "name": "変わり立ち梶の葉" },
	{ type: 'kamon', "id": "1349", "name": "細平戸梶の葉" },
	{ type: 'kamon', "id": "1350", "name": "変わり平戸梶の葉" },
	{ type: 'kamon', "id": "1351", "name": "太輪に梶の葉" },
	{ type: 'kamon', "id": "1352", "name": "石持ち地抜き立ち梶の葉" },
	{ type: 'kamon', "id": "1353", "name": "中陰立ち梶の葉" },
	{ type: 'kamon', "id": "1354", "name": "鬼梶の葉菱" },
	{ type: 'kamon', "id": "1355", "name": "中輪に変わり梶の葉" },
	{ type: 'kamon', "id": "1356", "name": "丸に平戸梶の葉" },
	{ type: 'kamon', "id": "1357", "name": "丸に変わり梶の葉" },
	{ type: 'kamon', "id": "1358", "name": "安寧梶の葉" },
	{ type: 'kamon', "id": "1359", "name": "毛輪に豆梶の葉" },
	{ type: 'kamon', "id": "1360", "name": "細輪に覗き梶の葉" },
	{ type: 'kamon', "id": "1361", "name": "雪輪に覗き梶の葉" },
	{ type: 'kamon', "id": "1362", "name": "抱き鬼梶の葉" },
	{ type: 'kamon', "id": "1363", "name": "陰抱き梶の葉" },
	{ type: 'kamon', "id": "1364", "name": "外割り梶の葉" },
	{ type: 'kamon', "id": "1365", "name": "外割り鬼梶の葉" },
	{ type: 'kamon', "id": "1366", "name": "違い割り梶の葉" },
	{ type: 'kamon', "id": "1367", "name": "違い梶の葉" },
	{ type: 'kamon', "id": "1368", "name": "割り梶の葉菱" },
	{ type: 'kamon', "id": "1369", "name": "三つ追い梶の葉" },
	{ type: 'kamon', "id": "1370", "name": "三つ立ち梶の葉" },
	{ type: 'kamon', "id": "1371", "name": "三つ梶の葉" },
	{ type: 'kamon', "id": "1372", "name": "三つ盛り梶の葉" },
	{ type: 'kamon', "id": "1373", "name": "尻合わせ三つ梶の葉" },
	{ type: 'kamon', "id": "1374", "name": "丸に頭合わせ三つ梶の葉" },
	{ type: 'kamon', "id": "1375", "name": "割り平戸梶" },
	{ type: 'kamon', "id": "1376", "name": "割り梶の葉" },
	{ type: 'kamon', "id": "1377", "name": "割り鬼梶の葉" },
	{ type: 'kamon', "id": "1378", "name": "三つ割り鬼梶の葉" },
	{ type: 'kamon', "id": "1379", "name": "丸に頭合わせ三つ割り梶の葉" },
	{ type: 'kamon', "id": "1380", "name": "丸に一の字に違い梶の葉" },
	{ type: 'kamon', "id": "1381", "name": "五つ梶の葉車" },
	{ type: 'kamon', "id": "1382", "name": "梶の葉枝丸" },
	{ type: 'kamon', "id": "1383", "name": "梶の枝丸" },
	{ type: 'kamon', "id": "1384", "name": "雪輪に枝梶" },
	{ type: 'kamon', "id": "1385", "name": "梶の葉蝶" },
	{ type: 'kamon', "id": "1386", "name": "梶の葉飛び蝶" },
	{ type: 'kamon', "id": "1387", "name": "浮線梶の葉" },
	{ type: 'kamon', "id": "1388", "name": "乱れ梶の葉" },
	{ type: 'kamon', "id": "1389", "name": "梶の葉鶴" },
	{ type: 'kamon', "id": "1390", "name": "色紙に梶の葉" },
	{ type: 'kamon', "id": "1391", "name": "玉垣" },
	{ type: 'kamon', "id": "1392", "name": "大岡玉垣" },
	{ type: 'kamon', "id": "1393", "name": "玉垣に轡" },
	{ type: 'kamon', "id": "1394", "name": "竹垣" },
	{ type: 'kamon', "id": "1395", "name": "常磐垣" },
	{ type: 'kamon', "id": "1396", "name": "唐檜垣" },
	{ type: 'kamon', "id": "1397", "name": "丸に唐竹垣" },
	{ type: 'kamon', "id": "1398", "name": "杜若の花" },
	{ type: 'kamon', "id": "1399", "name": "重ね杜若" },
	{ type: 'kamon', "id": "1400", "name": "陰杜若の花" },
	{ type: 'kamon', "id": "1401", "name": "立ち杜若" },
	{ type: 'kamon', "id": "1402", "name": "丸に変わり抱き杜若" },
	{ type: 'kamon', "id": "1403", "name": "杏葉杜若" },
	{ type: 'kamon', "id": "1404", "name": "杜若に丸" },
	{ type: 'kamon', "id": "1405", "name": "結び杜若の花" },
	{ type: 'kamon', "id": "1406", "name": "糸菱に総覗き杜若の花" },
	{ type: 'kamon', "id": "1407", "name": "変わり杜若の花" },
	{ type: 'kamon', "id": "1408", "name": "二つ杜若の花" },
	{ type: 'kamon', "id": "1409", "name": "立ち杜若の花" },
	{ type: 'kamon', "id": "1410", "name": "入れ違い杜若" },
	{ type: 'kamon', "id": "1411", "name": "杜若に水" },
	{ type: 'kamon', "id": "1412", "name": "外向き杜若菱" },
	{ type: 'kamon', "id": "1413", "name": "入れ違い杜若菱" },
	{ type: 'kamon', "id": "1414", "name": "丸に頭合わせ三つ杜若の花" },
	{ type: 'kamon', "id": "1415", "name": "尻合わせ三つ杜若の花" },
	{ type: 'kamon', "id": "1416", "name": "今城杜若" },
	{ type: 'kamon', "id": "1417", "name": "根引き杜若" },
	{ type: 'kamon', "id": "1418", "name": "抱き杜若" },
	{ type: 'kamon', "id": "1419", "name": "三つ追い杜若" },
	{ type: 'kamon', "id": "1420", "name": "丸に向こう杜若の花" },
	{ type: 'kamon', "id": "1421", "name": "石山杜若" },
	{ type: 'kamon', "id": "1422", "name": "中山杜若" },
	{ type: 'kamon', "id": "1423", "name": "鉸具" },
	{ type: 'kamon', "id": "1424", "name": "鉸具に雁金" },
	{ type: 'kamon', "id": "1425", "name": "隅立て角持ち" },
	{ type: 'kamon', "id": "1426", "name": "隅立て撫角" },
	{ type: 'kamon', "id": "1427", "name": "隅入れ角" },
	{ type: 'kamon', "id": "1428", "name": "隅入れ角に星梅鉢" },
	{ type: 'kamon', "id": "1429", "name": "隅入れ角に四つ目" },
	{ type: 'kamon', "id": "1430", "name": "鉄砲角" },
	{ type: 'kamon', "id": "1431", "name": "隅切れ鉄砲角に万字" },
	{ type: 'kamon', "id": "1432", "name": "六角" },
	{ type: 'kamon', "id": "1433", "name": "八角" },
	{ type: 'kamon', "id": "1434", "name": "雁木角" },
	{ type: 'kamon', "id": "1435", "name": "隅立てと垂れ角違い" },
	{ type: 'kamon', "id": "1436", "name": "違い角" },
	{ type: 'kamon', "id": "1437", "name": "結び角" },
	{ type: 'kamon', "id": "1438", "name": "隅切り角に三木" },
	{ type: 'kamon', "id": "1439", "name": "隅切り角に雁木三木" },
	{ type: 'kamon', "id": "1440", "name": "隅切り角にユリ三木" },
	{ type: 'kamon', "id": "1441", "name": "隅切り角に梅鉢" },
	{ type: 'kamon', "id": "1442", "name": "隅切り角に立ち沢瀉" },
	{ type: 'kamon', "id": "1443", "name": "隅切り鉄砲角に片喰" },
	{ type: 'kamon', "id": "1444", "name": "隅切り角に桐" },
	{ type: 'kamon', "id": "1445", "name": "隅切り角に九枚笹" },
	{ type: 'kamon', "id": "1446", "name": "隅切り角に違い鷹の羽" },
	{ type: 'kamon', "id": "1447", "name": "隅切り角に橘" },
	{ type: 'kamon', "id": "1448", "name": "隅切り角に蔦" },
	{ type: 'kamon', "id": "1449", "name": "隅切り角に花菱" },
	{ type: 'kamon', "id": "1450", "name": "隅切り角に三つ引き" },
	{ type: 'kamon', "id": "1451", "name": "隅切り角に抱き茗荷" },
	{ type: 'kamon', "id": "1452", "name": "隅切り角に木瓜" },
	{ type: 'kamon', "id": "1453", "name": "隅切り角に違い矢" },
	{ type: 'kamon', "id": "1454", "name": "隅切り角に三の文字" },
	{ type: 'kamon', "id": "1455", "name": "組み合い角" },
	{ type: 'kamon', "id": "1456", "name": "組み合い角に左三つ巴" },
	{ type: 'kamon', "id": "1457", "name": "組み合い角に四つ目" },
	{ type: 'kamon', "id": "1458", "name": "組み合い角に丸に三つ引き" },
	{ type: 'kamon', "id": "1459", "name": "伊豆三島神社" },
	{ type: 'kamon', "id": "1460", "name": "時宗相模原清沢寺無料光寺" },
	{ type: 'kamon', "id": "1461", "name": "平角" },
	{ type: 'kamon', "id": "1462", "name": "中入り角" },
	{ type: 'kamon', "id": "1463", "name": "寄せ角" },
	{ type: 'kamon', "id": "1464", "name": "細違い隅切り角" },
	{ type: 'kamon', "id": "1465", "name": "角持ち" },
	{ type: 'kamon', "id": "1466", "name": "大平角" },
	{ type: 'kamon', "id": "1467", "name": "中平角" },
	{ type: 'kamon', "id": "1468", "name": "垂れ角" },
	{ type: 'kamon', "id": "1469", "name": "丸に垂門" },
	{ type: 'kamon', "id": "1470", "name": "反り平角" },
	{ type: 'kamon', "id": "1471", "name": "内隅入り平角" },
	{ type: 'kamon', "id": "1472", "name": "内隅切り平角" },
	{ type: 'kamon', "id": "1473", "name": "太夫角" },
	{ type: 'kamon', "id": "1474", "name": "隅切り平反り角" },
	{ type: 'kamon', "id": "1475", "name": "寄せ掛け隅切り角" },
	{ type: 'kamon', "id": "1476", "name": "三つ組み隅切り角" },
	{ type: 'kamon', "id": "1477", "name": "三つ盛り組み角" },
	{ type: 'kamon', "id": "1478", "name": "丸に三つ組み角" },
	{ type: 'kamon', "id": "1479", "name": "八つ組み角" },
	{ type: 'kamon', "id": "1480", "name": "糸輪に五つ組み角" },
	{ type: 'kamon', "id": "1481", "name": "隅切り角持ち" },
	{ type: 'kamon', "id": "1482", "name": "隅入り角持ち" },
	{ type: 'kamon', "id": "1483", "name": "六角持ち" },
	{ type: 'kamon', "id": "1484", "name": "重ね角持ち" },
	{ type: 'kamon', "id": "1485", "name": "丸に隅立て角持ち" },
	{ type: 'kamon', "id": "1486", "name": "持ち合い変わり隅入り角" },
	{ type: 'kamon', "id": "1487", "name": "隅切り角" },
	{ type: 'kamon', "id": "1488", "name": "隅立て角" },
	{ type: 'kamon', "id": "1489", "name": "隅切り角に三文字" },
	{ type: 'kamon', "id": "1490", "name": "隅切り角に三の字" },
	{ type: 'kamon', "id": "1491", "name": "隅切り角に右丁字巴" },
	{ type: 'kamon', "id": "1492", "name": "隅切り角に三つ柏" },
	{ type: 'kamon', "id": "1493", "name": "隅入り鉄砲角に抱き柏" },
	{ type: 'kamon', "id": "1494", "name": "隅切り鉄砲角に松皮菱" },
	{ type: 'kamon', "id": "1495", "name": "隅切り角に抱き沢瀉" },
	{ type: 'kamon', "id": "1496", "name": "隅切り角に杜若の花" },
	{ type: 'kamon', "id": "1497", "name": "隅切り角に三つ柏" },
	{ type: 'kamon', "id": "1498", "name": "隅切り角に笹龍胆" },
	{ type: 'kamon', "id": "1499", "name": "隅切り角に釘抜" },
	{ type: 'kamon', "id": "1500", "name": "隅切り角に三つ目の丸地紙" },
	{ type: 'kamon', "id": "1501", "name": "隅切り角に水車に水" },
	{ type: 'kamon', "id": "1502", "name": "隅切り角に鎚" },
	{ type: 'kamon', "id": "1503", "name": "隅切り角に平四つ目" },
	{ type: 'kamon', "id": "1504", "name": "子持ち隅切り角に立ち梶の葉" },
	{ type: 'kamon', "id": "1505", "name": "隅切り角に片喰" },
	{ type: 'kamon', "id": "1506", "name": "組み合わせ角に剣片喰" },
	{ type: 'kamon', "id": "1507", "name": "子持ち隅切り角に五三の銅" },
	{ type: 'kamon', "id": "1508", "name": "重ね角に茶の実" },
	{ type: 'kamon', "id": "1509", "name": "隅切り角に上がり藤" },
	{ type: 'kamon', "id": "1510", "name": "組み合わせ角に扇" },
	{ type: 'kamon', "id": "1511", "name": "炭入り角に七曜" },
	{ type: 'kamon', "id": "1512", "name": "組み合わせ角に梅鉢" },
	{ type: 'kamon', "id": "1513", "name": "隅切り角に一つ引" },
	{ type: 'kamon', "id": "1514", "name": "隅切り鉄砲角に三つ引" },
	{ type: 'kamon', "id": "1515", "name": "隅切り角に二つ引" },
	{ type: 'kamon', "id": "1516", "name": "入れ違い鎌" },
	{ type: 'kamon', "id": "1517", "name": "一つ鎌" },
	{ type: 'kamon', "id": "1518", "name": "中輪に違い鎌" },
	{ type: 'kamon', "id": "1519", "name": "三つ組み鎌" },
	{ type: 'kamon', "id": "1520", "name": "四つ鎌車" },
	{ type: 'kamon', "id": "1521", "name": "五つ鎌車" },
	{ type: 'kamon', "id": "1522", "name": "四つ鎌角" },
	{ type: 'kamon', "id": "1523", "name": "四つ追い鎌" },
	{ type: 'kamon', "id": "1524", "name": "六つ鎌車" },
	{ type: 'kamon', "id": "1525", "name": "釜敷き七曜" },
	{ type: 'kamon', "id": "1526", "name": "桔梗釜敷き" },
	{ type: 'kamon', "id": "1527", "name": "五つ結び釜敷き" },
	{ type: 'kamon', "id": "1528", "name": "六つ結び釜敷き" },
	{ type: 'kamon', "id": "1529", "name": "七つ結び釜敷き" },
	{ type: 'kamon', "id": "1530", "name": "釜敷き梅鉢" },
	{ type: 'kamon', "id": "1531", "name": "釜敷桜" },
	{ type: 'kamon', "id": "1532", "name": "結び釜敷九曜" },
	{ type: 'kamon', "id": "1533", "name": "釜敷九曜" },
	{ type: 'kamon', "id": "1534", "name": "雪形釜敷" },
	{ type: 'kamon', "id": "1535", "name": "上がり亀" },
	{ type: 'kamon', "id": "1536", "name": "三つ追い亀" },
	{ type: 'kamon', "id": "1537", "name": "三つ追い亀の丸" },
	{ type: 'kamon', "id": "1538", "name": "蓬莱亀" },
	{ type: 'kamon', "id": "1539", "name": "一つ亀の丸" },
	{ type: 'kamon', "id": "1540", "name": "丸に上り亀" },
	{ type: 'kamon', "id": "1541", "name": "水に亀" },
	{ type: 'kamon', "id": "1542", "name": "光琳亀" },
	{ type: 'kamon', "id": "1543", "name": "中陰蓬莱亀" },
	{ type: 'kamon', "id": "1544", "name": "一つ蓑亀の丸" },
	{ type: 'kamon', "id": "1545", "name": "向こう亀の丸" },
	{ type: 'kamon', "id": "1546", "name": "真向き亀" },
	{ type: 'kamon', "id": "1547", "name": "蓑亀" },
	{ type: 'kamon', "id": "1548", "name": "下り亀" },
	{ type: 'kamon', "id": "1549", "name": "親子亀" },
	{ type: 'kamon', "id": "1550", "name": "二つ入れ違い亀" },
	{ type: 'kamon', "id": "1551", "name": "水に光琳亀" },
	{ type: 'kamon', "id": "1552", "name": "唐鐶木瓜" },
	{ type: 'kamon', "id": "1553", "name": "唐鐶木瓜に花菱" },
	{ type: 'kamon', "id": "1554", "name": "鐶木瓜に花菱" },
	{ type: 'kamon', "id": "1555", "name": "鐶木瓜に四つ目" },
	{ type: 'kamon', "id": "1556", "name": "外三つ鐶" },
	{ type: 'kamon', "id": "1557", "name": "三つ唐鐶" },
	{ type: 'kamon', "id": "1558", "name": "丸に四つ鐶" },
	{ type: 'kamon', "id": "1559", "name": "太鐶輪に三つ柏" },
	{ type: 'kamon', "id": "1560", "name": "外六つ鐶" },
	{ type: 'kamon', "id": "1561", "name": "外四つ鐶" },
	{ type: 'kamon', "id": "1562", "name": "鐶桜" },
	{ type: 'kamon', "id": "1563", "name": "光琳鐶松" },
	{ type: 'kamon', "id": "1564", "name": "細輪に違い金輪" },
	{ type: 'kamon', "id": "1565", "name": "金輪崩し" },
	{ type: 'kamon', "id": "1566", "name": "三つ並び金輪" },
	{ type: 'kamon', "id": "1567", "name": "三つ金輪" },
	{ type: 'kamon', "id": "1568", "name": "細輪に三つ割金輪" },
	{ type: 'kamon', "id": "1569", "name": "三つ金輪に花形" },
	{ type: 'kamon', "id": "1570", "name": "四つ金輪" },
	{ type: 'kamon', "id": "1571", "name": "四つ組み金輪" },
	{ type: 'kamon', "id": "1572", "name": "五つ組み金輪" },
	{ type: 'kamon', "id": "1573", "name": "糸輪に十曜金輪" },
	{ type: 'kamon', "id": "1574", "name": "曲尺" },
	{ type: 'kamon', "id": "1575", "name": "中輪に曲尺" },
	{ type: 'kamon', "id": "1576", "name": "かに" },
	{ type: 'kamon', "id": "1577", "name": "踊りかに" },
	{ type: 'kamon', "id": "1578", "name": "川蟹" },
	{ type: 'kamon', "id": "1579", "name": "海蟹" },
	{ type: 'kamon', "id": "1580", "name": "蟹菱" },
	{ type: 'kamon', "id": "1581", "name": "糸輪に蟹" },
	{ type: 'kamon', "id": "1582", "name": "丸に真向き海蟹" },
	{ type: 'kamon', "id": "1583", "name": "石蟹" },
	{ type: 'kamon', "id": "1584", "name": "唐花" },
	{ type: 'kamon', "id": "1585", "name": "丸に唐花" },
	{ type: 'kamon', "id": "1586", "name": "中陰唐花" },
	{ type: 'kamon', "id": "1587", "name": "石持ち地抜き唐花" },
	{ type: 'kamon', "id": "1588", "name": "剣唐花" },
	{ type: 'kamon', "id": "1589", "name": "八重唐花" },
	{ type: 'kamon', "id": "1590", "name": "丸に八重唐花" },
	{ type: 'kamon', "id": "1591", "name": "三つ割り唐花" },
	{ type: 'kamon', "id": "1592", "name": "割り対かい唐花菱" },
	{ type: 'kamon', "id": "1593", "name": "台地抜き五つ唐花" },
	{ type: 'kamon', "id": "1594", "name": "丸に六つ唐花" },
	{ type: 'kamon', "id": "1595", "name": "唐花桐" },
	{ type: 'kamon', "id": "1596", "name": "中陰横見唐花" },
	{ type: 'kamon', "id": "1597", "name": "三つ盛り五つ唐花" },
	{ type: 'kamon', "id": "1598", "name": "浮線綾五つ唐花" },
	{ type: 'kamon', "id": "1599", "name": "三つ割り唐花に木瓜" },
	{ type: 'kamon', "id": "1600", "name": "唐花蝶" },
	{ type: 'kamon', "id": "1601", "name": "枝唐花" },
	{ type: 'kamon', "id": "1602", "name": "陰五つ唐花" },
	{ type: 'kamon', "id": "1603", "name": "痩せ五つ唐花" },
	{ type: 'kamon', "id": "1604", "name": "五つ離れ唐花" },
	{ type: 'kamon', "id": "1605", "name": "五つ鬼唐花" },
	{ type: 'kamon', "id": "1606", "name": "五つ匂い剣唐花" },
	{ type: 'kamon', "id": "1607", "name": "五つ変わり唐花" },
	{ type: 'kamon', "id": "1608", "name": "五つ結び唐花" },
	{ type: 'kamon', "id": "1609", "name": "裏五つ唐花" },
	{ type: 'kamon', "id": "1610", "name": "五つ唐花" },
	{ type: 'kamon', "id": "1611", "name": "陰裏五つ唐花" },
	{ type: 'kamon', "id": "1612", "name": "中輪に中陰五つ唐花" },
	{ type: 'kamon', "id": "1613", "name": "糸輪に六つ唐花" },
	{ type: 'kamon', "id": "1614", "name": "有馬唐花" },
	{ type: 'kamon', "id": "1615", "name": "外雪輪に唐花" },
	{ type: 'kamon', "id": "1616", "name": "細五鐶輪に唐花" },
	{ type: 'kamon', "id": "1617", "name": "雪輪に覗き唐花" },
	{ type: 'kamon', "id": "1618", "name": "変わり五つ唐花に巴" },
	{ type: 'kamon', "id": "1619", "name": "五つ蔓唐花" },
	{ type: 'kamon', "id": "1620", "name": "蔓唐花桜" },
	{ type: 'kamon', "id": "1621", "name": "織田唐花" },
	{ type: 'kamon', "id": "1622", "name": "変わり浮線綾五つ唐花" },
	{ type: 'kamon', "id": "1623", "name": "中輪に三つ割り唐花" },
	{ type: 'kamon', "id": "1624", "name": "三つ割り反り唐花" },
	{ type: 'kamon', "id": "1625", "name": "三つ割り唐花に剣片喰" },
	{ type: 'kamon', "id": "1626", "name": "三つ割り唐花に剣花菱" },
	{ type: 'kamon', "id": "1627", "name": "三つ割り唐花に丁字巴" },
	{ type: 'kamon', "id": "1628", "name": "横見唐花" },
	{ type: 'kamon', "id": "1629", "name": "三つ横見唐花" },
	{ type: 'kamon', "id": "1630", "name": "中輪に覗き唐花" },
	{ type: 'kamon', "id": "1631", "name": "唐花胡蝶" },
	{ type: 'kamon', "id": "1632", "name": "平唐花胡蝶" },
	{ type: 'kamon', "id": "1633", "name": "唐花揚羽蝶" },
	{ type: 'kamon', "id": "1634", "name": "唐花飛び蝶" },
	{ type: 'kamon', "id": "1635", "name": "中陰唐花飛び蝶" },
	{ type: 'kamon', "id": "1636", "name": "抱き蔓唐花" },
	{ type: 'kamon', "id": "1637", "name": "唐花枝丸" },
	{ type: 'kamon', "id": "1638", "name": "奈" },
	{ type: 'kamon', "id": "1639", "name": "永井奈" },
	{ type: 'kamon', "id": "1640", "name": "唐奈" },
	{ type: 'kamon', "id": "1641", "name": "三つ割り奈" },
	{ type: 'kamon', "id": "1642", "name": "葉の花" },
	{ type: 'kamon', "id": "1643", "name": "陰奈" },
	{ type: 'kamon', "id": "1644", "name": "石持ち地抜き奈" },
	{ type: 'kamon', "id": "1645", "name": "丸に三つ奈" },
	{ type: 'kamon', "id": "1646", "name": "丸に変わり菜の花" },
	{ type: 'kamon', "id": "1647", "name": "三つ盛り菜の花" },
	{ type: 'kamon', "id": "1648", "name": "烏" },
	{ type: 'kamon', "id": "1649", "name": "烏の丸" },
	{ type: 'kamon', "id": "1650", "name": "雁金" },
	{ type: 'kamon', "id": "1651", "name": "丸に雁金" },
	{ type: 'kamon', "id": "1652", "name": "陰雁金" },
	{ type: 'kamon', "id": "1653", "name": "石持ち地抜き雁金" },
	{ type: 'kamon', "id": "1654", "name": "結び雁金" },
	{ type: 'kamon', "id": "1655", "name": "丸に結び雁金" },
	{ type: 'kamon', "id": "1656", "name": "中輪に二つ雁金" },
	{ type: 'kamon', "id": "1657", "name": "増山雁金" },
	{ type: 'kamon', "id": "1658", "name": "輪違い雁金" },
	{ type: 'kamon', "id": "1659", "name": "向かい雁金菱" },
	{ type: 'kamon', "id": "1660", "name": "尻合わせ三つ雁金" },
	{ type: 'kamon', "id": "1661", "name": "頭合わせ三つ雁金" },
	{ type: 'kamon', "id": "1662", "name": "丸に頭合わせ三つ雁金" },
	{ type: 'kamon', "id": "1663", "name": "丸に嘴合わせ三つ雁金" },
	{ type: 'kamon', "id": "1664", "name": "尻合わせ三つ結び雁金" },
	{ type: 'kamon', "id": "1665", "name": "越前専照寺" },
	{ type: 'kamon', "id": "1666", "name": "糸菱に覗き雁金" },
	{ type: 'kamon', "id": "1667", "name": "飛び雁" },
	{ type: 'kamon', "id": "1668", "name": "二羽飛び雁" },
	{ type: 'kamon', "id": "1669", "name": "三羽追い雁" },
	{ type: 'kamon', "id": "1670", "name": "薄輪に四羽飛び雁" },
	{ type: 'kamon', "id": "1671", "name": "剣尻雁金" },
	{ type: 'kamon', "id": "1672", "name": "糸巻き雁金" },
	{ type: 'kamon', "id": "1673", "name": "三つ斜め雁金" },
	{ type: 'kamon', "id": "1674", "name": "三つ盛り雁金" },
	{ type: 'kamon', "id": "1675", "name": "月輪に豆雁金" },
	{ type: 'kamon', "id": "1676", "name": "向かい嘴合い雁金" },
	{ type: 'kamon', "id": "1677", "name": "雁金菱" },
	{ type: 'kamon', "id": "1678", "name": "違い雁金" },
	{ type: 'kamon', "id": "1679", "name": "三つ重ね雁金" },
	{ type: 'kamon', "id": "1680", "name": "金輪雁金" },
	{ type: 'kamon', "id": "1681", "name": "釜敷き雁金" },
	{ type: 'kamon', "id": "1682", "name": "鞠挟み三つ雁金" },
	{ type: 'kamon', "id": "1683", "name": "三つ雁金に巴" },
	{ type: 'kamon', "id": "1684", "name": "尻合わせ四つ雁金菱" },
	{ type: 'kamon', "id": "1685", "name": "五つ雁金車" },
	{ type: 'kamon', "id": "1686", "name": "六つ雁金に武田菱" },
	{ type: 'kamon', "id": "1687", "name": "三つ寄せ対い雁金菱" },
	{ type: 'kamon', "id": "1688", "name": "八つ雁金車" },
	{ type: 'kamon', "id": "1689", "name": "陰結び雁金" },
	{ type: 'kamon', "id": "1690", "name": "石持ち地抜き結び雁金" },
	{ type: 'kamon', "id": "1691", "name": "奴形雁金" },
	{ type: 'kamon', "id": "1692", "name": "菱に結び雁金" },
	{ type: 'kamon', "id": "1693", "name": "丸に三つ山結び雁金" },
	{ type: 'kamon', "id": "1694", "name": "中輪に対い結び雁金" },
	{ type: 'kamon', "id": "1695", "name": "尻合わせ二つ結び雁金" },
	{ type: 'kamon', "id": "1696", "name": "頭合わせ三つ結び雁金" },
	{ type: 'kamon', "id": "1697", "name": "尻合わせ四つ重ね結び雁金" },
	{ type: 'kamon', "id": "1698", "name": "四つ結び雁金に追い鷹の羽" },
	{ type: 'kamon', "id": "1699", "name": "結び雁金輪に豆雁金" },
	{ type: 'kamon', "id": "1700", "name": "石持ち地抜き飛び雁" },
	{ type: 'kamon', "id": "1701", "name": "三羽飛び雁" },
	{ type: 'kamon', "id": "1702", "name": "丸に笠" },
	{ type: 'kamon', "id": "1703", "name": "丸に陣笠" },
	{ type: 'kamon', "id": "1704", "name": "中輪に房付き笠" },
	{ type: 'kamon', "id": "1705", "name": "糸輪に二階笠" },
	{ type: 'kamon', "id": "1706", "name": "三階笠" },
	{ type: 'kamon', "id": "1707", "name": "四つ寄せ笠" },
	{ type: 'kamon', "id": "1708", "name": "柳生笠" },
	{ type: 'kamon', "id": "1709", "name": "笠" },
	{ type: 'kamon', "id": "1710", "name": "紐付き変わり陣笠" },
	{ type: 'kamon', "id": "1711", "name": "唐人笠" },
	{ type: 'kamon', "id": "1712", "name": "建部笠" },
	{ type: 'kamon', "id": "1713", "name": "対い笠" },
	{ type: 'kamon', "id": "1714", "name": "丸に足軽笠" },
	{ type: 'kamon', "id": "1715", "name": "三つ管笠" },
	{ type: 'kamon', "id": "1716", "name": "三つ寄せ陣笠" },
	{ type: 'kamon', "id": "1717", "name": "編み笠" },
	{ type: 'kamon', "id": "1718", "name": "深編み笠" },
	{ type: 'kamon', "id": "1719", "name": "深被り笠" },
	{ type: 'kamon', "id": "1720", "name": "花笠" },
	{ type: 'kamon', "id": "1721", "name": "丸に俯き陣笠" },
	{ type: 'kamon', "id": "1722", "name": "神宮笠" },
	{ type: 'kamon', "id": "1723", "name": "細輪に狩り陣笠" },
	{ type: 'kamon', "id": "1724", "name": "雪輪に陣笠" },
	{ type: 'kamon', "id": "1725", "name": "頭合わせ三つ笠" },
	{ type: 'kamon', "id": "1726", "name": "五つ市女笠" },
	{ type: 'kamon', "id": "1727", "name": "かせぎ" },
	{ type: 'kamon', "id": "1728", "name": "角かせぎ" },
	{ type: 'kamon', "id": "1729", "name": "三つ柏" },
	{ type: 'kamon', "id": "1730", "name": "丸に三つ柏" },
	{ type: 'kamon', "id": "1731", "name": "陰三つ柏" },
	{ type: 'kamon', "id": "1732", "name": "石持ち地抜き三つ柏" },
	{ type: 'kamon', "id": "1733", "name": "鬼三つ柏" },
	{ type: 'kamon', "id": "1734", "name": "中輪に三つ柏" },
	{ type: 'kamon', "id": "1735", "name": "蔓柏" },
	{ type: 'kamon', "id": "1736", "name": "丸に蔓柏" },
	{ type: 'kamon', "id": "1737", "name": "片手蔓柏" },
	{ type: 'kamon', "id": "1738", "name": "丸に片手柏" },
	{ type: 'kamon', "id": "1739", "name": "五徳柏" },
	{ type: 'kamon', "id": "1740", "name": "丸に剣三つ柏" },
	{ type: 'kamon', "id": "1741", "name": "中輪に中柏" },
	{ type: 'kamon', "id": "1742", "name": "丸に土佐柏" },
	{ type: 'kamon', "id": "1743", "name": "丸に細蔓柏" },
	{ type: 'kamon', "id": "1744", "name": "丸に一枚柏" },
	{ type: 'kamon', "id": "1745", "name": "丸に並び柏" },
	{ type: 'kamon', "id": "1746", "name": "丸に違い柏" },
	{ type: 'kamon', "id": "1747", "name": "抱き柏" },
	{ type: 'kamon', "id": "1748", "name": "丸に抱き柏" },
	{ type: 'kamon', "id": "1749", "name": "細抱き柏" },
	{ type: 'kamon', "id": "1750", "name": "丸に細抱き柏" },
	{ type: 'kamon', "id": "1751", "name": "三つ追い柏" },
	{ type: 'kamon', "id": "1752", "name": "三つ割三つ柏" },
	{ type: 'kamon', "id": "1753", "name": "中輪に五つ柏" },
	{ type: 'kamon', "id": "1754", "name": "丸に結び柏" },
	{ type: 'kamon', "id": "1755", "name": "筑前宗像神社" },
	{ type: 'kamon', "id": "1756", "name": "八重三つ桜" },
	{ type: 'kamon', "id": "1757", "name": "実付鬼結柏" },
	{ type: 'kamon', "id": "1758", "name": "抱き柏に四つ目" },
	{ type: 'kamon', "id": "1759", "name": "丸に出三つ柏" },
	{ type: 'kamon', "id": "1760", "name": "重ね三つ柏" },
	{ type: 'kamon', "id": "1761", "name": "三つ葉柏" },
	{ type: 'kamon', "id": "1762", "name": "四つ蔓柏" },
	{ type: 'kamon', "id": "1763", "name": "追い柏" },
	{ type: 'kamon', "id": "1764", "name": "柏鶴" },
	{ type: 'kamon', "id": "1765", "name": "三枚並び柏" },
	{ type: 'kamon', "id": "1766", "name": "割り違い柏" },
	{ type: 'kamon', "id": "1767", "name": "蔓巻き柏" },
	{ type: 'kamon', "id": "1768", "name": "久志木桜" },
	{ type: 'kamon', "id": "1769", "name": "丸に変わり三つ柏" },
	{ type: 'kamon', "id": "1770", "name": "牧野柏" },
	{ type: 'kamon', "id": "1771", "name": "細輪に中三つ柏" },
	{ type: 'kamon', "id": "1772", "name": "細字柏" },
	{ type: 'kamon', "id": "1773", "name": "中大輪に変わり土佐柏" },
	{ type: 'kamon', "id": "1774", "name": "丸に変わり三つ細鬼柏" },
	{ type: 'kamon', "id": "1775", "name": "三つ蔓柏" },
	{ type: 'kamon', "id": "1776", "name": "雪輪に三つ蔓柏" },
	{ type: 'kamon', "id": "1777", "name": "太輪に五徳柏" },
	{ type: 'kamon', "id": "1778", "name": "丸に変わり三つ細蔓柏" },
	{ type: 'kamon', "id": "1779", "name": "陰三つ鶴柏" },
	{ type: 'kamon', "id": "1780", "name": "実付き三つ柏" },
	{ type: 'kamon', "id": "1781", "name": "三つ蔓柏片喰" },
	{ type: 'kamon', "id": "1782", "name": "菱に三つ柏" },
	{ type: 'kamon', "id": "1783", "name": "毛輪に豆三つ柏" },
	{ type: 'kamon', "id": "1784", "name": "糸雪輪に三つ柏" },
	{ type: 'kamon', "id": "1785", "name": "糸輪に覗き三つ柏" },
	{ type: 'kamon', "id": "1786", "name": "三つ柏崩し" },
	{ type: 'kamon', "id": "1787", "name": "中陰三つ柏" },
	{ type: 'kamon', "id": "1788", "name": "揺り結び柏" },
	{ type: 'kamon', "id": "1789", "name": "結び柏" },
	{ type: 'kamon', "id": "1790", "name": "陰剣柏" },
	{ type: 'kamon', "id": "1791", "name": "丸に三つ熨斗剣柏" },
	{ type: 'kamon', "id": "1792", "name": "変わり五徳柏" },
	{ type: 'kamon', "id": "1793", "name": "丸に変わり蔓土佐柏" },
	{ type: 'kamon', "id": "1794", "name": "陰陽重ね柏" },
	{ type: 'kamon', "id": "1795", "name": "雪輪に蔓柏" },
	{ type: 'kamon', "id": "1796", "name": "三つ追い折れ柏" },
	{ type: 'kamon', "id": "1797", "name": "軸付き三つ追い折れ柏" },
	{ type: 'kamon', "id": "1798", "name": "檜巴" },
	{ type: 'kamon', "id": "1799", "name": "鬼柏巴" },
	{ type: 'kamon', "id": "1800", "name": "軸違い柏巴" },
	{ type: 'kamon', "id": "1801", "name": "三つ追い重ね柏" },
	{ type: 'kamon', "id": "1802", "name": "細輪に三枚重ね柏" },
	{ type: 'kamon', "id": "1803", "name": "変わり三つ並び柏" },
	{ type: 'kamon', "id": "1804", "name": "変わり三つ葉柏" },
	{ type: 'kamon', "id": "1805", "name": "三つ割り蔓柏" },
	{ type: 'kamon', "id": "1806", "name": "三つ持ち合い結び柏" },
	{ type: 'kamon', "id": "1807", "name": "丸に四つ柏" },
	{ type: 'kamon', "id": "1808", "name": "五つ捻じ柏" },
	{ type: 'kamon', "id": "1809", "name": "八つ柏菱" },
	{ type: 'kamon', "id": "1810", "name": "丸に七つ柏" },
	{ type: 'kamon', "id": "1811", "name": "九枚柏" },
	{ type: 'kamon', "id": "1812", "name": "折れ柏" },
	{ type: 'kamon', "id": "1813", "name": "中輪に折れ柏" },
	{ type: 'kamon', "id": "1814", "name": "陰細折れ柏" },
	{ type: 'kamon', "id": "1815", "name": "一つ蔓折れ柏" },
	{ type: 'kamon', "id": "1816", "name": "蔓一つ折れ柏" },
	{ type: 'kamon', "id": "1817", "name": "違い鬼柏" },
	{ type: 'kamon', "id": "1818", "name": "細違い柏" },
	{ type: 'kamon', "id": "1819", "name": "丸に並び軸付き柏" },
	{ type: 'kamon', "id": "1820", "name": "陰並び柏" },
	{ type: 'kamon', "id": "1821", "name": "石持ち地抜き違い柏" },
	{ type: 'kamon', "id": "1822", "name": "違い片折れ柏" },
	{ type: 'kamon', "id": "1823", "name": "丸に並び鬼柏" },
	{ type: 'kamon', "id": "1824", "name": "変わり違い鬼柏" },
	{ type: 'kamon', "id": "1825", "name": "光琳中陰抱き柏" },
	{ type: 'kamon', "id": "1826", "name": "石持ち地抜き抱き柏" },
	{ type: 'kamon', "id": "1827", "name": "陰抱き柏" },
	{ type: 'kamon', "id": "1828", "name": "陰陽抱き柏" },
	{ type: 'kamon', "id": "1829", "name": "菱抱き柏" },
	{ type: 'kamon', "id": "1830", "name": "外割り抱き柏" },
	{ type: 'kamon', "id": "1831", "name": "抱き鬼柏" },
	{ type: 'kamon', "id": "1832", "name": "中川柏" },
	{ type: 'kamon', "id": "1833", "name": "抱き柏に丸に立て三つ引き" },
	{ type: 'kamon', "id": "1834", "name": "抱き柏に違い鷹の羽" },
	{ type: 'kamon', "id": "1835", "name": "抱き柏に一つ鏑矢" },
	{ type: 'kamon', "id": "1836", "name": "抱き柏に矢尻付き一つ矢" },
	{ type: 'kamon', "id": "1837", "name": "三枚抱き柏" },
	{ type: 'kamon', "id": "1838", "name": "変わり柏桐" },
	{ type: 'kamon', "id": "1839", "name": "柏桐" },
	{ type: 'kamon', "id": "1840", "name": "蔓柏菱" },
	{ type: 'kamon', "id": "1841", "name": "対い折れ柏" },
	{ type: 'kamon', "id": "1842", "name": "浮線柏" },
	{ type: 'kamon', "id": "1843", "name": "三枚折れ柏" },
	{ type: 'kamon', "id": "1844", "name": "三つ割り追い柏に三つ巴" },
	{ type: 'kamon', "id": "1845", "name": "柏七宝に唐花" },
	{ type: 'kamon', "id": "1846", "name": "四つ鬼柏に四つ目" },
	{ type: 'kamon', "id": "1847", "name": "柏飛び蝶" },
	{ type: 'kamon', "id": "1848", "name": "二葉柏蝶" },
	{ type: 'kamon', "id": "1849", "name": "柏揚羽蝶" },
	{ type: 'kamon', "id": "1850", "name": "蔓柏蝶" },
	{ type: 'kamon', "id": "1851", "name": "糸輪に折れ柏蝶" },
	{ type: 'kamon', "id": "1852", "name": "陰蝙蝠柏" },
	{ type: 'kamon', "id": "1853", "name": "二つ蔓柏" },
	{ type: 'kamon', "id": "1854", "name": "二つ折れ蔓柏" },
	{ type: 'kamon', "id": "1855", "name": "変わり二つ蔓柏" },
	{ type: 'kamon', "id": "1856", "name": "実付き二つ蔓柏" },
	{ type: 'kamon', "id": "1857", "name": "中陰二つ蔓鬼柏" },
	{ type: 'kamon', "id": "1858", "name": "蔓源氏柏" },
	{ type: 'kamon', "id": "1859", "name": "変わり枝柏" },
	{ type: 'kamon', "id": "1860", "name": "枝折れ柏" },
	{ type: 'kamon', "id": "1861", "name": "三つ柏枝丸" },
	{ type: 'kamon', "id": "1862", "name": "蕾付き柏枝丸" },
	{ type: 'kamon', "id": "1863", "name": "変わり柏三つ枝丸" },
	{ type: 'kamon', "id": "1864", "name": "変わり柏枝丸" },
	{ type: 'kamon', "id": "1865", "name": "糸輪に枝柏" },
	{ type: 'kamon', "id": "1866", "name": "糸輪に離れ折れ柏" },
	{ type: 'kamon', "id": "1867", "name": "細抱き柏に花菱" },
	{ type: 'kamon', "id": "1868", "name": "霞" },
	{ type: 'kamon', "id": "1869", "name": "春霞" },
	{ type: 'kamon', "id": "1870", "name": "石持ち地抜き霞" },
	{ type: 'kamon', "id": "1871", "name": "中輪に総覗き霞" },
	{ type: 'kamon', "id": "1872", "name": "片喰" },
	{ type: 'kamon', "id": "1873", "name": "丸に片喰" },
	{ type: 'kamon', "id": "1874", "name": "細輪に豆片喰" },
	{ type: 'kamon', "id": "1875", "name": "菱に片喰" },
	{ type: 'kamon', "id": "1876", "name": "総陰片喰" },
	{ type: 'kamon', "id": "1877", "name": "中陰片喰" },
	{ type: 'kamon', "id": "1878", "name": "庄内片喰" },
	{ type: 'kamon', "id": "1879", "name": "蔓片喰" },
	{ type: 'kamon', "id": "1880", "name": "三つ片喰" },
	{ type: 'kamon', "id": "1881", "name": "蔓結び片喰" },
	{ type: 'kamon', "id": "1882", "name": "細松皮菱に剣片喰" },
	{ type: 'kamon', "id": "1883", "name": "丸に蔓結び片喰" },
	{ type: 'kamon', "id": "1884", "name": "鱗剣片喰" },
	{ type: 'kamon', "id": "1885", "name": "細鱗剣片喰" },
	{ type: 'kamon', "id": "1886", "name": "丸に鱗片喰" },
	{ type: 'kamon', "id": "1887", "name": "扇片喰" },
	{ type: 'kamon', "id": "1888", "name": "折れ片喰" },
	{ type: 'kamon', "id": "1889", "name": "盃片喰" },
	{ type: 'kamon', "id": "1890", "name": "変わり浮線片喰" },
	{ type: 'kamon', "id": "1891", "name": "浮線剣片喰" },
	{ type: 'kamon', "id": "1892", "name": "変わり浮線剣片喰" },
	{ type: 'kamon', "id": "1893", "name": "中陰浮線片喰" },
	{ type: 'kamon', "id": "1894", "name": "片喰飛び蝶" },
	{ type: 'kamon', "id": "1895", "name": "変わり片喰飛び蝶" },
	{ type: 'kamon', "id": "1896", "name": "剣片喰飛び蝶" },
	{ type: 'kamon', "id": "1897", "name": "片喰揚羽蝶" },
	{ type: 'kamon', "id": "1898", "name": "中陰剣片喰胡蝶" },
	{ type: 'kamon', "id": "1899", "name": "片喰桐" },
	{ type: 'kamon', "id": "1900", "name": "中陰片喰桐" },
	{ type: 'kamon', "id": "1901", "name": "枝片喰" },
	{ type: 'kamon', "id": "1902", "name": "片喰枝丸" },
	{ type: 'kamon', "id": "1903", "name": "三つ割片喰" },
	{ type: 'kamon', "id": "1904", "name": "変わり片喰枝丸" },
	{ type: 'kamon', "id": "1905", "name": "変わり片喰の実" },
	{ type: 'kamon', "id": "1906", "name": "実片喰桐菱" },
	{ type: 'kamon', "id": "1907", "name": "実片喰桐菱車" },
	{ type: 'kamon', "id": "1908", "name": "変わり姫片喰草" },
	{ type: 'kamon', "id": "1909", "name": "片喰枝菱" },
	{ type: 'kamon', "id": "1910", "name": "片喰に水" },
	{ type: 'kamon', "id": "1911", "name": "中陰幼剣片喰" },
	{ type: 'kamon', "id": "1912", "name": "丸に三つ割り片喰" },
	{ type: 'kamon', "id": "1913", "name": "亀甲形三つ割り片喰" },
	{ type: 'kamon', "id": "1914", "name": "三つ盛り片喰" },
	{ type: 'kamon', "id": "1915", "name": "四つ片喰" },
	{ type: 'kamon', "id": "1916", "name": "変わり重ね片喰" },
	{ type: 'kamon', "id": "1917", "name": "剣片喰" },
	{ type: 'kamon', "id": "1918", "name": "丸に剣片喰" },
	{ type: 'kamon', "id": "1919", "name": "総陰剣片喰" },
	{ type: 'kamon', "id": "1920", "name": "中陰剣片喰" },
	{ type: 'kamon', "id": "1921", "name": "離れ剣片喰" },
	{ type: 'kamon', "id": "1922", "name": "姫路剣片喰" },
	{ type: 'kamon', "id": "1923", "name": "若狭剣片喰" },
	{ type: 'kamon', "id": "1924", "name": "三つ割り剣片喰" },
	{ type: 'kamon', "id": "1925", "name": "丸に三つ割り剣片喰" },
	{ type: 'kamon', "id": "1926", "name": "丸に四つ剣片喰" },
	{ type: 'kamon', "id": "1927", "name": "太陰片喰" },
	{ type: 'kamon', "id": "1928", "name": "浮線片喰" },
	{ type: 'kamon', "id": "1929", "name": "軸違片喰" },
	{ type: 'kamon', "id": "1930", "name": "蔓片喰（２）" },
	{ type: 'kamon', "id": "1931", "name": "三つ頭合わせ片喰" },
	{ type: 'kamon', "id": "1932", "name": "菱に剣片喰" },
	{ type: 'kamon', "id": "1933", "name": "片喰蝶" },
	{ type: 'kamon', "id": "1934", "name": "隅入り平角に片喰" },
	{ type: 'kamon', "id": "1935", "name": "二つ剣片喰" },
	{ type: 'kamon', "id": "1936", "name": "比翼片喰" },
	{ type: 'kamon', "id": "1937", "name": "村山片喰" },
	{ type: 'kamon', "id": "1938", "name": "片喰の実" },
	{ type: 'kamon', "id": "1939", "name": "変わり片喰" },
	{ type: 'kamon', "id": "1940", "name": "細片喰" },
	{ type: 'kamon', "id": "1941", "name": "捻じ片喰" },
	{ type: 'kamon', "id": "1942", "name": "陰片喰" },
	{ type: 'kamon', "id": "1943", "name": "中陰片喰（２）" },
	{ type: 'kamon', "id": "1944", "name": "石持ち地抜き片喰" },
	{ type: 'kamon', "id": "1945", "name": "丸に陰片喰" },
	{ type: 'kamon', "id": "1946", "name": "隅切り角に地抜き片喰" },
	{ type: 'kamon', "id": "1947", "name": "裏片喰" },
	{ type: 'kamon', "id": "1948", "name": "八重片喰" },
	{ type: 'kamon', "id": "1949", "name": "石持ち地抜き剣片喰" },
	{ type: 'kamon', "id": "1950", "name": "石持ち地抜き棒剣片喰" },
	{ type: 'kamon', "id": "1951", "name": "丸に離れ剣片喰" },
	{ type: 'kamon', "id": "1952", "name": "三つ又剣片喰" },
	{ type: 'kamon', "id": "1953", "name": "丸に出剣片喰" },
	{ type: 'kamon', "id": "1954", "name": "丸に実付き片喰" },
	{ type: 'kamon', "id": "1955", "name": "三つ鐶輪に剣片喰" },
	{ type: 'kamon', "id": "1956", "name": "太輪に変わり剣片喰" },
	{ type: 'kamon', "id": "1957", "name": "熨斗剣片喰" },
	{ type: 'kamon', "id": "1958", "name": "六角太剣片喰" },
	{ type: 'kamon', "id": "1959", "name": "丸に四つ片喰" },
	{ type: 'kamon', "id": "1960", "name": "丸に星片喰" },
	{ type: 'kamon', "id": "1961", "name": "中陰二つ剣片喰" },
	{ type: 'kamon', "id": "1962", "name": "変わり二つ剣片喰" },
	{ type: 'kamon', "id": "1963", "name": "丸に二つ剣片喰" },
	{ type: 'kamon', "id": "1964", "name": "丸に上下二つ剣片喰" },
	{ type: 'kamon', "id": "1965", "name": "片手蔓片喰" },
	{ type: 'kamon', "id": "1966", "name": "変わり蔓片喰" },
	{ type: 'kamon', "id": "1967", "name": "光琳片喰" },
	{ type: 'kamon', "id": "1968", "name": "結び八重片喰" },
	{ type: 'kamon', "id": "1969", "name": "糸輪に蔓片喰" },
	{ type: 'kamon', "id": "1970", "name": "中輪に鐶片喰" },
	{ type: 'kamon', "id": "1971", "name": "丸に金輪片喰" },
	{ type: 'kamon', "id": "1972", "name": "丸に花剣片喰" },
	{ type: 'kamon', "id": "1973", "name": "丸に蔓剣片喰" },
	{ type: 'kamon', "id": "1974", "name": "蔓剣片喰" },
	{ type: 'kamon', "id": "1975", "name": "三つ盛り剣片喰" },
	{ type: 'kamon', "id": "1976", "name": "三つ頭合わせ片喰" },
	{ type: 'kamon', "id": "1977", "name": "三つ組み割り片喰" },
	{ type: 'kamon', "id": "1978", "name": "重ね三つ割り片喰" },
	{ type: 'kamon', "id": "1979", "name": "丸に三つ割り追い片喰" },
	{ type: 'kamon', "id": "1980", "name": "丸に三つ割り剣片喰（２）" },
	{ type: 'kamon', "id": "1981", "name": "中陰三つ割り剣片喰" },
	{ type: 'kamon', "id": "1982", "name": "六角三つ割り剣片喰" },
	{ type: 'kamon', "id": "1983", "name": "六角三つ割り追い剣片喰" },
	{ type: 'kamon', "id": "1984", "name": "外寄せ三つ割り片喰" },
	{ type: 'kamon', "id": "1985", "name": "剣一つ片喰" },
	{ type: 'kamon', "id": "1986", "name": "割り片喰菱" },
	{ type: 'kamon', "id": "1987", "name": "割り剣片喰菱" },
	{ type: 'kamon', "id": "1988", "name": "中陰割り剣片喰菱" },
	{ type: 'kamon', "id": "1989", "name": "鉄砲菱に片喰" },
	{ type: 'kamon', "id": "1990", "name": "石持ち地抜き菱に片喰" },
	{ type: 'kamon', "id": "1991", "name": "糸菱に覗き片喰" },
	{ type: 'kamon', "id": "1992", "name": "糸菱に覗き陰片喰" },
	{ type: 'kamon', "id": "1993", "name": "糸菱に陰陽出片喰" },
	{ type: 'kamon', "id": "1994", "name": "糸輪に覗き片喰" },
	{ type: 'kamon', "id": "1995", "name": "糸輪に豆片喰" },
	{ type: 'kamon', "id": "1996", "name": "雪輪に片喰" },
	{ type: 'kamon', "id": "1997", "name": "羯磨十字" },
	{ type: 'kamon', "id": "1998", "name": "真言宗羯磨" },
	{ type: 'kamon', "id": "1999", "name": "十字羯磨" },
	{ type: 'kamon', "id": "2000", "name": "違い羯磨" },
	{ type: 'kamon', "id": "2001", "name": "八本骨風車" },
	{ type: 'kamon', "id": "2002", "name": "風車に巴" },
	{ type: 'kamon', "id": "2003", "name": "三つ剣" },
	{ type: 'kamon', "id": "2004", "name": "変わり三つ剣" },
	{ type: 'kamon', "id": "2005", "name": "丸に五つ剣" },
	{ type: 'kamon', "id": "2006", "name": "丸に六つ剣" },
	{ type: 'kamon', "id": "2007", "name": "丸に八つ剣菱" },
	{ type: 'kamon', "id": "2008", "name": "剣形山の文字" },
	{ type: 'kamon', "id": "2009", "name": "違い剣" },
	{ type: 'kamon', "id": "2010", "name": "四つ剣菱" },
	{ type: 'kamon', "id": "2011", "name": "五つ剣" },
	{ type: 'kamon', "id": "2012", "name": "六つ剣" },
	{ type: 'kamon', "id": "2013", "name": "六つ親子剣" },
	{ type: 'kamon', "id": "2014", "name": "亀甲に梅鉢" },
	{ type: 'kamon', "id": "2015", "name": "亀甲に立ち沢瀉" },
	{ type: 'kamon', "id": "2016", "name": "亀甲に三つ柏" },
	{ type: 'kamon', "id": "2017", "name": "亀甲に剣片喰" },
	{ type: 'kamon', "id": "2018", "name": "亀甲に桔梗" },
	{ type: 'kamon', "id": "2019", "name": "亀甲に桐" },
	{ type: 'kamon', "id": "2020", "name": "亀甲に違い鷹の羽" },
	{ type: 'kamon', "id": "2021", "name": "亀甲に橘" },
	{ type: 'kamon', "id": "2022", "name": "亀甲に蔦" },
	{ type: 'kamon', "id": "2023", "name": "亀甲に左二つ巴" },
	{ type: 'kamon', "id": "2024", "name": "亀甲に左三つ巴" },
	{ type: 'kamon', "id": "2025", "name": "亀甲に花菱" },
	{ type: 'kamon', "id": "2026", "name": "亀甲に剣花菱" },
	{ type: 'kamon', "id": "2027", "name": "亀甲に下がり藤" },
	{ type: 'kamon', "id": "2028", "name": "亀甲に抱き茗荷" },
	{ type: 'kamon', "id": "2029", "name": "亀甲に並び矢" },
	{ type: 'kamon', "id": "2030", "name": "亀甲に三つ盛り亀甲花菱" },
	{ type: 'kamon', "id": "2031", "name": "広島厳島神社" },
	{ type: 'kamon', "id": "2032", "name": "糸輪に三つ盛り亀甲花角" },
	{ type: 'kamon', "id": "2033", "name": "丸に亀甲花菱" },
	{ type: 'kamon', "id": "2034", "name": "相馬亀甲" },
	{ type: 'kamon', "id": "2035", "name": "六郷亀甲" },
	{ type: 'kamon', "id": "2036", "name": "反り亀甲" },
	{ type: 'kamon', "id": "2037", "name": "出雲出雲大社" },
	{ type: 'kamon', "id": "2038", "name": "三つ盛り亀甲に花菱" },
	{ type: 'kamon', "id": "2039", "name": "京都三宝院" },
	{ type: 'kamon', "id": "2040", "name": "亀甲" },
	{ type: 'kamon', "id": "2041", "name": "三つ盛り鉄砲亀甲" },
	{ type: 'kamon', "id": "2042", "name": "違い一重亀甲" },
	{ type: 'kamon', "id": "2043", "name": "毘沙門亀甲" },
	{ type: 'kamon', "id": "2044", "name": "積み日向亀甲" },
	{ type: 'kamon', "id": "2045", "name": "三つ組み合い一重亀甲" },
	{ type: 'kamon', "id": "2046", "name": "三つ盛り一重割り亀甲崩し" },
	{ type: 'kamon', "id": "2047", "name": "三つ鉄砲亀甲崩し" },
	{ type: 'kamon', "id": "2048", "name": "丸に三つ盛り一重亀甲崩し" },
	{ type: 'kamon', "id": "2049", "name": "三方亀甲花菱" },
	{ type: 'kamon', "id": "2050", "name": "六方亀甲形" },
	{ type: 'kamon', "id": "2051", "name": "六つ組み合い亀甲" },
	{ type: 'kamon', "id": "2052", "name": "亀甲崩し" },
	{ type: 'kamon', "id": "2053", "name": "変わり亀甲崩し" },
	{ type: 'kamon', "id": "2054", "name": "亀甲崩し片喰" },
	{ type: 'kamon', "id": "2055", "name": "花亀甲崩し" },
	{ type: 'kamon', "id": "2056", "name": "三つ割り亀甲に花菱" },
	{ type: 'kamon', "id": "2057", "name": "持ち合い三つ割り亀甲に花菱" },
	{ type: 'kamon', "id": "2058", "name": "糸菱に覗き亀甲花形" },
	{ type: 'kamon', "id": "2059", "name": "三つ子持ち亀甲に瓜の花" },
	{ type: 'kamon', "id": "2060", "name": "一重亀甲" },
	{ type: 'kamon', "id": "2061", "name": "十六菊" },
	{ type: 'kamon', "id": "2062", "name": "石持ち地抜き十六菊" },
	{ type: 'kamon', "id": "2063", "name": "十二菊" },
	{ type: 'kamon', "id": "2064", "name": "八菊" },
	{ type: 'kamon', "id": "2065", "name": "菱菊" },
	{ type: 'kamon', "id": "2066", "name": "半菊に一の文字" },
	{ type: 'kamon', "id": "2067", "name": "中輪に菊菱一の文字" },
	{ type: 'kamon', "id": "2068", "name": "菊水" },
	{ type: 'kamon', "id": "2069", "name": "中輪に半菊" },
	{ type: 'kamon', "id": "2070", "name": "三つ割り菊" },
	{ type: 'kamon', "id": "2071", "name": "中輪に三つ割り菊" },
	{ type: 'kamon', "id": "2072", "name": "三つ割り菊に剣片喰" },
	{ type: 'kamon', "id": "2073", "name": "四つ割り菊に花角" },
	{ type: 'kamon', "id": "2074", "name": "割り菊" },
	{ type: 'kamon', "id": "2075", "name": "抱き菊の葉" },
	{ type: 'kamon', "id": "2076", "name": "杏葉菊" },
	{ type: 'kamon', "id": "2077", "name": "青山菊" },
	{ type: 'kamon', "id": "2078", "name": "抱き菊の葉に菊" },
	{ type: 'kamon', "id": "2079", "name": "菊輪に金の文字" },
	{ type: 'kamon', "id": "2080", "name": "神戸湊川神社" },
	{ type: 'kamon', "id": "2081", "name": "神道教会金光教" },
	{ type: 'kamon', "id": "2082", "name": "十六裏菊" },
	{ type: 'kamon', "id": "2083", "name": "有栖川菊" },
	{ type: 'kamon', "id": "2084", "name": "菊浮線稜" },
	{ type: 'kamon', "id": "2085", "name": "板倉菊" },
	{ type: 'kamon', "id": "2086", "name": "菊枝丸" },
	{ type: 'kamon', "id": "2087", "name": "閑院菊" },
	{ type: 'kamon', "id": "2088", "name": "水戸菊" },
	{ type: 'kamon', "id": "2089", "name": "浮線花菊" },
	{ type: 'kamon', "id": "2090", "name": "大宮菊" },
	{ type: 'kamon', "id": "2091", "name": "菊輪に三つ星" },
	{ type: 'kamon', "id": "2092", "name": "葉敷き菊水" },
	{ type: 'kamon', "id": "2093", "name": "変わり菊水" },
	{ type: 'kamon', "id": "2094", "name": "浮線稜菊" },
	{ type: 'kamon', "id": "2095", "name": "桔梗" },
	{ type: 'kamon', "id": "2096", "name": "丸に桔梗" },
	{ type: 'kamon', "id": "2097", "name": "糸輪に豆桔梗" },
	{ type: 'kamon', "id": "2098", "name": "陰桔梗" },
	{ type: 'kamon', "id": "2099", "name": "中陰桔梗" },
	{ type: 'kamon', "id": "2100", "name": "丸に中陰桔梗" },
	{ type: 'kamon', "id": "2101", "name": "石持ち地抜き桔梗" },
	{ type: 'kamon', "id": "2102", "name": "剣香い桔梗" },
	{ type: 'kamon', "id": "2103", "name": "土佐桔梗" },
	{ type: 'kamon', "id": "2104", "name": "蔓桔梗" },
	{ type: 'kamon', "id": "2105", "name": "剣形桔梗" },
	{ type: 'kamon', "id": "2106", "name": "大田桔梗" },
	{ type: 'kamon', "id": "2107", "name": "裏桔梗" },
	{ type: 'kamon', "id": "2108", "name": "丸に裏桔梗" },
	{ type: 'kamon', "id": "2109", "name": "沼田桔梗" },
	{ type: 'kamon', "id": "2110", "name": "三つ割り桔梗" },
	{ type: 'kamon', "id": "2111", "name": "丸に細桔梗" },
	{ type: 'kamon', "id": "2112", "name": "剣桔梗" },
	{ type: 'kamon', "id": "2113", "name": "八重桔梗" },
	{ type: 'kamon', "id": "2114", "name": "丸に八重桔梗" },
	{ type: 'kamon', "id": "2115", "name": "桔梗桐" },
	{ type: 'kamon', "id": "2116", "name": "中陰桔梗桐" },
	{ type: 'kamon', "id": "2117", "name": "細晴明桔梗" },
	{ type: 'kamon', "id": "2118", "name": "千葉法華経寺" },
	{ type: 'kamon', "id": "2119", "name": "八重花桔梗" },
	{ type: 'kamon', "id": "2120", "name": "比翼桔梗" },
	{ type: 'kamon', "id": "2121", "name": "浮線桔梗" },
	{ type: 'kamon', "id": "2122", "name": "利休桔梗" },
	{ type: 'kamon', "id": "2123", "name": "桔梗蝶" },
	{ type: 'kamon', "id": "2124", "name": "沼田桔梗" },
	{ type: 'kamon', "id": "2125", "name": "鉄砲桔梗" },
	{ type: 'kamon', "id": "2126", "name": "盃桔梗" },
	{ type: 'kamon', "id": "2127", "name": "三つ盛り桔梗" },
	{ type: 'kamon', "id": "2128", "name": "台桔梗" },
	{ type: 'kamon', "id": "2129", "name": "丸に桔梗（２）" },
	{ type: 'kamon', "id": "2130", "name": "薄輪に豆桔梗" },
	{ type: 'kamon', "id": "2131", "name": "手杵" },
	{ type: 'kamon', "id": "2132", "name": "丸に並び杵" },
	{ type: 'kamon', "id": "2133", "name": "三つ並び杵" },
	{ type: 'kamon', "id": "2134", "name": "違い杵" },
	{ type: 'kamon', "id": "2135", "name": "糸輪に十字杵" },
	{ type: 'kamon', "id": "2136", "name": "丸に一つ杵" },
	{ type: 'kamon', "id": "2137", "name": "丸に違い杵" },
	{ type: 'kamon', "id": "2138", "name": "月に杵" },
	{ type: 'kamon', "id": "2139", "name": "五三桐" },
	{ type: 'kamon', "id": "2140", "name": "丸に五三桐" },
	{ type: 'kamon', "id": "2141", "name": "総陰五三桐" },
	{ type: 'kamon', "id": "2142", "name": "葉陰五三桐" },
	{ type: 'kamon', "id": "2143", "name": "中陰五三桐" },
	{ type: 'kamon', "id": "2144", "name": "石持ち地抜き五三桐" },
	{ type: 'kamon', "id": "2145", "name": "五七桐" },
	{ type: 'kamon', "id": "2146", "name": "糸輪に五七桐" },
	{ type: 'kamon', "id": "2147", "name": "五三鬼桐" },
	{ type: 'kamon', "id": "2148", "name": "五七鬼桐" },
	{ type: 'kamon', "id": "2149", "name": "枝桐" },
	{ type: 'kamon', "id": "2150", "name": "中陰枝桐" },
	{ type: 'kamon', "id": "2151", "name": "変わり枝桐" },
	{ type: 'kamon', "id": "2152", "name": "光琳枝桐" },
	{ type: 'kamon', "id": "2153", "name": "二つ花鬼桐" },
	{ type: 'kamon', "id": "2154", "name": "割り桐" },
	{ type: 'kamon', "id": "2155", "name": "中陰割り桐" },
	{ type: 'kamon', "id": "2156", "name": "割り鬼桐" },
	{ type: 'kamon', "id": "2157", "name": "鶴割り桐" },
	{ type: 'kamon', "id": "2158", "name": "有馬割り桐" },
	{ type: 'kamon', "id": "2159", "name": "桐菱" },
	{ type: 'kamon', "id": "2160", "name": "入れ違い割り桐" },
	{ type: 'kamon', "id": "2161", "name": "入れ違い桐崩し" },
	{ type: 'kamon', "id": "2162", "name": "入れ違い花割り桐" },
	{ type: 'kamon', "id": "2163", "name": "入れ違い崩し鬼桐" },
	{ type: 'kamon', "id": "2164", "name": "三つ割り五七の桐" },
	{ type: 'kamon', "id": "2165", "name": "三つ割り光琳桐" },
	{ type: 'kamon', "id": "2166", "name": "三つ割り花合わせ桐" },
	{ type: 'kamon', "id": "2167", "name": "三つ割り桐に二つ引" },
	{ type: 'kamon', "id": "2168", "name": "三つ割り五七の桐に違い鷹の羽" },
	{ type: 'kamon', "id": "2169", "name": "三つ割り桐に三階菱" },
	{ type: 'kamon', "id": "2170", "name": "糸輪に桐菱" },
	{ type: 'kamon', "id": "2171", "name": "三つ割り桐に違い桐" },
	{ type: 'kamon', "id": "2172", "name": "三つ割り五七の桐に万字" },
	{ type: 'kamon', "id": "2173", "name": "三つ割り踊り桐に四つ目" },
	{ type: 'kamon', "id": "2174", "name": "三つ割り桐に茶の実" },
	{ type: 'kamon', "id": "2175", "name": "四つ割り桐" },
	{ type: 'kamon', "id": "2176", "name": "四つ割り切りに三階菱" },
	{ type: 'kamon', "id": "2177", "name": "外向き三つ割り桐" },
	{ type: 'kamon', "id": "2178", "name": "頭合わせ三つ桐" },
	{ type: 'kamon', "id": "2179", "name": "頭合わせ三つ鬼桐" },
	{ type: 'kamon', "id": "2180", "name": "尻合わせ三つ花桐" },
	{ type: 'kamon', "id": "2181", "name": "対かい桐菱" },
	{ type: 'kamon', "id": "2182", "name": "頭合わせ三つ光琳桐" },
	{ type: 'kamon', "id": "2183", "name": "七宝桐" },
	{ type: 'kamon', "id": "2184", "name": "六つ唐桐" },
	{ type: 'kamon', "id": "2185", "name": "中陰六つ唐桐" },
	{ type: 'kamon', "id": "2186", "name": "桐車" },
	{ type: 'kamon', "id": "2187", "name": "陰桐車" },
	{ type: 'kamon', "id": "2188", "name": "中陰桐車" },
	{ type: 'kamon', "id": "2189", "name": "変わり花桐車" },
	{ type: 'kamon', "id": "2190", "name": "中輪に変わり桐車" },
	{ type: 'kamon', "id": "2191", "name": "変わり桐車" },
	{ type: 'kamon', "id": "2192", "name": "浮線桐" },
	{ type: 'kamon', "id": "2193", "name": "細桐車" },
	{ type: 'kamon', "id": "2194", "name": "細捻じ桐車" },
	{ type: 'kamon', "id": "2195", "name": "桐車崩し" },
	{ type: 'kamon', "id": "2196", "name": "捻じ桐車" },
	{ type: 'kamon', "id": "2197", "name": "変わり花桐車（２）" },
	{ type: 'kamon', "id": "2198", "name": "変わり桐車（２）" },
	{ type: 'kamon', "id": "2199", "name": "桐の花車" },
	{ type: 'kamon', "id": "2200", "name": "花桐" },
	{ type: 'kamon', "id": "2201", "name": "踊り桐" },
	{ type: 'kamon', "id": "2202", "name": "中陰踊り桐" },
	{ type: 'kamon', "id": "2203", "name": "三つ割り桐" },
	{ type: 'kamon', "id": "2204", "name": "陰踊り桐" },
	{ type: 'kamon', "id": "2205", "name": "変わり踊り桐" },
	{ type: 'kamon', "id": "2206", "name": "五三の踊り桐" },
	{ type: 'kamon', "id": "2207", "name": "五七の踊り桐" },
	{ type: 'kamon', "id": "2208", "name": "踊り鬼桐" },
	{ type: 'kamon', "id": "2209", "name": "乱れ桐" },
	{ type: 'kamon', "id": "2210", "name": "鷺桐" },
	{ type: 'kamon', "id": "2211", "name": "桐揚羽蝶" },
	{ type: 'kamon', "id": "2212", "name": "中陰桐蝶" },
	{ type: 'kamon', "id": "2213", "name": "飛び蝶桐" },
	{ type: 'kamon', "id": "2214", "name": "五七割り桐" },
	{ type: 'kamon', "id": "2215", "name": "変わり中陰飛び蝶桐" },
	{ type: 'kamon', "id": "2216", "name": "中陰胡蝶桐" },
	{ type: 'kamon', "id": "2217", "name": "変わり胡蝶桐" },
	{ type: 'kamon', "id": "2218", "name": "蝙蝠桐" },
	{ type: 'kamon', "id": "2219", "name": "中陰三つ葉鬼桐" },
	{ type: 'kamon', "id": "2220", "name": "桐の枝丸" },
	{ type: 'kamon', "id": "2221", "name": "丸に桐車" },
	{ type: 'kamon', "id": "2222", "name": "変り五七桐" },
	{ type: 'kamon', "id": "2223", "name": "上田陰桐" },
	{ type: 'kamon', "id": "2224", "name": "光琳桐" },
	{ type: 'kamon', "id": "2225", "name": "中陰光琳桐" },
	{ type: 'kamon', "id": "2226", "name": "尾張熱田神社" },
	{ type: 'kamon', "id": "2227", "name": "京都豊国神社" },
	{ type: 'kamon', "id": "2228", "name": "京都建仁寺" },
	{ type: 'kamon', "id": "2229", "name": "大閤桐" },
	{ type: 'kamon', "id": "2230", "name": "上杉桐" },
	{ type: 'kamon', "id": "2231", "name": "佐竹桐" },
	{ type: 'kamon', "id": "2232", "name": "変わり五七桐" },
	{ type: 'kamon', "id": "2233", "name": "上田陰桐" },
	{ type: 'kamon', "id": "2234", "name": "芋桐" },
	{ type: 'kamon', "id": "2235", "name": "利休桐" },
	{ type: 'kamon', "id": "2236", "name": "金輪桐" },
	{ type: 'kamon', "id": "2237", "name": "三つ盛り五三の桐" },
	{ type: 'kamon', "id": "2238", "name": "丸に三本花桐" },
	{ type: 'kamon', "id": "2239", "name": "糸輪に五三の桐" },
	{ type: 'kamon', "id": "2240", "name": "太陰変わり五三の桐" },
	{ type: 'kamon', "id": "2241", "name": "細中陰五三の桐" },
	{ type: 'kamon', "id": "2242", "name": "福井桐" },
	{ type: 'kamon', "id": "2243", "name": "仙石桐" },
	{ type: 'kamon', "id": "2244", "name": "上田桐" },
	{ type: 'kamon', "id": "2245", "name": "変わり上田桐" },
	{ type: 'kamon', "id": "2246", "name": "太桐" },
	{ type: 'kamon', "id": "2247", "name": "変わり芋桐" },
	{ type: 'kamon', "id": "2248", "name": "変わり花桐" },
	{ type: 'kamon', "id": "2249", "name": "飛翔桐" },
	{ type: 'kamon', "id": "2250", "name": "五三の桐に鷹の羽" },
	{ type: 'kamon', "id": "2251", "name": "葉陰五三の鬼桐" },
	{ type: 'kamon', "id": "2252", "name": "総陰五三の鬼桐" },
	{ type: 'kamon', "id": "2253", "name": "中陰五三の鬼桐" },
	{ type: 'kamon', "id": "2254", "name": "石持ち地抜き五三の鬼桐" },
	{ type: 'kamon', "id": "2255", "name": "新田桐" },
	{ type: 'kamon', "id": "2256", "name": "石持ち地抜き五七の桐" },
	{ type: 'kamon', "id": "2257", "name": "有馬桐" },
	{ type: 'kamon', "id": "2258", "name": "内藤桐" },
	{ type: 'kamon', "id": "2259", "name": "対州桐" },
	{ type: 'kamon', "id": "2260", "name": "多度桐" },
	{ type: 'kamon', "id": "2261", "name": "文晁桐" },
	{ type: 'kamon', "id": "2262", "name": "川越桐" },
	{ type: 'kamon', "id": "2263", "name": "土佐桐" },
	{ type: 'kamon', "id": "2264", "name": "嵯峨桐" },
	{ type: 'kamon', "id": "2265", "name": "松江桐" },
	{ type: 'kamon', "id": "2266", "name": "三井桐" },
	{ type: 'kamon', "id": "2267", "name": "光琳唐桐" },
	{ type: 'kamon', "id": "2268", "name": "鐶桐" },
	{ type: 'kamon', "id": "2269", "name": "光琳鐶桐" },
	{ type: 'kamon', "id": "2270", "name": "中陰利休桐" },
	{ type: 'kamon', "id": "2271", "name": "陰利休桐" },
	{ type: 'kamon', "id": "2272", "name": "中陰五三の桐に轡" },
	{ type: 'kamon', "id": "2273", "name": "小判桐" },
	{ type: 'kamon', "id": "2274", "name": "揚げ巻き桐" },
	{ type: 'kamon', "id": "2275", "name": "埋み桐" },
	{ type: 'kamon', "id": "2276", "name": "糸輪に覗き五三の桐" },
	{ type: 'kamon', "id": "2277", "name": "雪輪に五三の桐" },
	{ type: 'kamon', "id": "2278", "name": "糸輪に豆五三の桐" },
	{ type: 'kamon', "id": "2279", "name": "糸菱に覗き五三の桐" },
	{ type: 'kamon', "id": "2280", "name": "細中陰五三の菱桐" },
	{ type: 'kamon', "id": "2281", "name": "変わり中陰抱き菱桐" },
	{ type: 'kamon', "id": "2282", "name": "陰対い菱桐" },
	{ type: 'kamon', "id": "2283", "name": "入れ違い割り菱桐" },
	{ type: 'kamon', "id": "2284", "name": "桐枝菱" },
	{ type: 'kamon', "id": "2285", "name": "松葉菱に五三の桐" },
	{ type: 'kamon', "id": "2286", "name": "松野桐" },
	{ type: 'kamon', "id": "2287", "name": "変わり桐" },
	{ type: 'kamon', "id": "2288", "name": "崩し桐" },
	{ type: 'kamon', "id": "2289", "name": "変わり崩し桐" },
	{ type: 'kamon', "id": "2290", "name": "陰浮線桐" },
	{ type: 'kamon', "id": "2291", "name": "中陰浮線桐" },
	{ type: 'kamon', "id": "2292", "name": "下がり花桐" },
	{ type: 'kamon', "id": "2293", "name": "花下がり桐" },
	{ type: 'kamon', "id": "2294", "name": "有職桐" },
	{ type: 'kamon', "id": "2295", "name": "花切り崩しの丸" },
	{ type: 'kamon', "id": "2296", "name": "変わり桐枝丸" },
	{ type: 'kamon', "id": "2297", "name": "三つ葉桐枝丸" },
	{ type: 'kamon', "id": "2298", "name": "二つ桐枝丸" },
	{ type: 'kamon', "id": "2299", "name": "青桐の丸" },
	{ type: 'kamon', "id": "2300", "name": "三月桐" },
	{ type: 'kamon', "id": "2301", "name": "蔓桐" },
	{ type: 'kamon', "id": "2302", "name": "丸に独楽" },
	{ type: 'kamon', "id": "2303", "name": "紐付き独楽" },
	{ type: 'kamon', "id": "2304", "name": "並び独楽" },
	{ type: 'kamon', "id": "2305", "name": "丸に三つ独楽" },
	{ type: 'kamon', "id": "2306", "name": "丸に琴柱" },
	{ type: 'kamon', "id": "2307", "name": "三つ琴柱" },
	{ type: 'kamon', "id": "2308", "name": "一つ琴柱" },
	{ type: 'kamon', "id": "2309", "name": "琴柱に四つ目" },
	{ type: 'kamon', "id": "2310", "name": "並び琴柱" },
	{ type: 'kamon', "id": "2311", "name": "三つ組み琴柱" },
	{ type: 'kamon', "id": "2312", "name": "四つ組み琴柱" },
	{ type: 'kamon', "id": "2313", "name": "丸に笄" },
	{ type: 'kamon', "id": "2314", "name": "細輪に並び笄" },
	{ type: 'kamon', "id": "2315", "name": "丸に違い笄" },
	{ type: 'kamon', "id": "2316", "name": "三つ笄" },
	{ type: 'kamon', "id": "2317", "name": "六つ笄車" },
	{ type: 'kamon', "id": "2318", "name": "八つ笄" },
	{ type: 'kamon', "id": "2319", "name": "二つ蔓河骨" },
	{ type: 'kamon', "id": "2320", "name": "三つ河骨" },
	{ type: 'kamon', "id": "2321", "name": "丸に頭合わせ三つ河骨" },
	{ type: 'kamon', "id": "2322", "name": "割り河骨" },
	{ type: 'kamon', "id": "2323", "name": "河骨に水" },
	{ type: 'kamon', "id": "2324", "name": "二つ河骨菱" },
	{ type: 'kamon', "id": "2325", "name": "三つ割り河骨" },
	{ type: 'kamon', "id": "2326", "name": "三つ追い蔓河骨" },
	{ type: 'kamon', "id": "2327", "name": "頭合わせ五つ河骨" },
	{ type: 'kamon', "id": "2328", "name": "中輪に五つ裏河骨" },
	{ type: 'kamon', "id": "2329", "name": "蝙蝠" },
	{ type: 'kamon', "id": "2330", "name": "光琳蝙蝠" },
	{ type: 'kamon', "id": "2331", "name": "格子" },
	{ type: 'kamon', "id": "2332", "name": "花散る里" },
	{ type: 'kamon', "id": "2333", "name": "初音" },
	{ type: 'kamon', "id": "2334", "name": "柏木" },
	{ type: 'kamon', "id": "2335", "name": "末摘花" },
	{ type: 'kamon', "id": "2336", "name": "竹川" },
	{ type: 'kamon', "id": "2337", "name": "釘抜き" },
	{ type: 'kamon', "id": "2338", "name": "丸に釘抜き" },
	{ type: 'kamon', "id": "2339", "name": "陰釘抜き" },
	{ type: 'kamon', "id": "2340", "name": "釘抜き菱" },
	{ type: 'kamon', "id": "2341", "name": "違い釘抜き" },
	{ type: 'kamon', "id": "2342", "name": "丸に違い釘抜き" },
	{ type: 'kamon', "id": "2343", "name": "陰違い釘抜き" },
	{ type: 'kamon', "id": "2344", "name": "陰陽違い釘抜き" },
	{ type: 'kamon', "id": "2345", "name": "一柳釘抜き" },
	{ type: 'kamon', "id": "2346", "name": "重ね釘抜き" },
	{ type: 'kamon', "id": "2347", "name": "釘抜きに梃子" },
	{ type: 'kamon', "id": "2348", "name": "丸に三つ割り釘抜き" },
	{ type: 'kamon', "id": "2349", "name": "三つ盛り釘抜き" },
	{ type: 'kamon', "id": "2350", "name": "釘抜き崩し" },
	{ type: 'kamon', "id": "2351", "name": "三つ釘抜閂" },
	{ type: 'kamon', "id": "2352", "name": "丸に一つ釘抜" },
	{ type: 'kamon', "id": "2353", "name": "埼玉氷川神社" },
	{ type: 'kamon', "id": "2354", "name": "瑞雲" },
	{ type: 'kamon', "id": "2355", "name": "東寺雲" },
	{ type: 'kamon', "id": "2356", "name": "金雲形雲" },
	{ type: 'kamon', "id": "2357", "name": "雲に剣" },
	{ type: 'kamon', "id": "2358", "name": "興正寺雲" },
	{ type: 'kamon', "id": "2359", "name": "一つ雲" },
	{ type: 'kamon', "id": "2360", "name": "丸に雲" },
	{ type: 'kamon', "id": "2361", "name": "雲に御幣" },
	{ type: 'kamon', "id": "2362", "name": "三つ重ね雲" },
	{ type: 'kamon', "id": "2363", "name": "五つ雲" },
	{ type: 'kamon', "id": "2364", "name": "雲菱" },
	{ type: 'kamon', "id": "2365", "name": "降り雲" },
	{ type: 'kamon', "id": "2366", "name": "雨雲" },
	{ type: 'kamon', "id": "2367", "name": "五つ雲（２）" },
	{ type: 'kamon', "id": "2368", "name": "雲丸に御幣" },
	{ type: 'kamon', "id": "2369", "name": "丸に三つ栗" },
	{ type: 'kamon', "id": "2370", "name": "十字久留子" },
	{ type: 'kamon', "id": "2371", "name": "切り竹久留子" },
	{ type: 'kamon', "id": "2372", "name": "中川久留子" },
	{ type: 'kamon', "id": "2373", "name": "花久留子" },
	{ type: 'kamon', "id": "2374", "name": "丸に十字久留子" },
	{ type: 'kamon', "id": "2375", "name": "久留子" },
	{ type: 'kamon', "id": "2376", "name": "細切り竹久留子" },
	{ type: 'kamon', "id": "2377", "name": "丸に出十字久留子" },
	{ type: 'kamon', "id": "2378", "name": "内田久留子" },
	{ type: 'kamon', "id": "2379", "name": "梔子" },
	{ type: 'kamon', "id": "2380", "name": "石持ち地抜き梔子" },
	{ type: 'kamon', "id": "2381", "name": "三つ割り梔子" },
	{ type: 'kamon', "id": "2382", "name": "陰三つ割り梔子" },
	{ type: 'kamon', "id": "2383", "name": "三つ横見梔子" },
	{ type: 'kamon', "id": "2384", "name": "轡" },
	{ type: 'kamon', "id": "2385", "name": "大轡" },
	{ type: 'kamon', "id": "2386", "name": "丸に出轡" },
	{ type: 'kamon', "id": "2387", "name": "八角轡" },
	{ type: 'kamon', "id": "2388", "name": "轡菱" },
	{ type: 'kamon', "id": "2389", "name": "三つ轡菱" },
	{ type: 'kamon', "id": "2390", "name": "内田轡" },
	{ type: 'kamon', "id": "2391", "name": "花轡" },
	{ type: 'kamon', "id": "2392", "name": "持ち合い轡" },
	{ type: 'kamon', "id": "2393", "name": "寺阪轡" },
	{ type: 'kamon', "id": "2394", "name": "月輪に豆轡" },
	{ type: 'kamon', "id": "2395", "name": "雪持ち轡" },
	{ type: 'kamon', "id": "2396", "name": "角轡" },
	{ type: 'kamon', "id": "2397", "name": "星付き鍬形" },
	{ type: 'kamon', "id": "2398", "name": "三つ鍬形" },
	{ type: 'kamon', "id": "2399", "name": "三つ組み鍬形" },
	{ type: 'kamon', "id": "2400", "name": "五つ鍬形崩し" },
	{ type: 'kamon', "id": "2401", "name": "丸に鍬形" },
	{ type: 'kamon', "id": "2402", "name": "二つ違い鍬形" },
	{ type: 'kamon', "id": "2403", "name": "丸に頭合わせ星付き鍬形" },
	{ type: 'kamon', "id": "2404", "name": "丸に三つ剣に鍬形" },
	{ type: 'kamon', "id": "2405", "name": "鍬形菱" },
	{ type: 'kamon', "id": "2406", "name": "横見葛の花" },
	{ type: 'kamon', "id": "2407", "name": "葛の花" },
	{ type: 'kamon', "id": "2408", "name": "三つ割り葛の花" },
	{ type: 'kamon', "id": "2409", "name": "三つ寄せ横見葛の花" },
	{ type: 'kamon', "id": "2410", "name": "三つ葛の花" },
	{ type: 'kamon', "id": "2411", "name": "三つ寄せ葛の花" },
	{ type: 'kamon', "id": "2412", "name": "六つ日足" },
	{ type: 'kamon', "id": "2413", "name": "十二日足" },
	{ type: 'kamon', "id": "2414", "name": "旭光" },
	{ type: 'kamon', "id": "2415", "name": "丸に旭光" },
	{ type: 'kamon', "id": "2416", "name": "八つ日足" },
	{ type: 'kamon', "id": "2417", "name": "木下日足" },
	{ type: 'kamon', "id": "2418", "name": "大村日足" },
	{ type: 'kamon', "id": "2419", "name": "細輪に尖り日足に水" },
	{ type: 'kamon', "id": "2420", "name": "日の字崩し" },
	{ type: 'kamon', "id": "2421", "name": "変わり十二日足" },
	{ type: 'kamon', "id": "2422", "name": "変わり十六日足" },
	{ type: 'kamon', "id": "2423", "name": "尖り十六日足" },
	{ type: 'kamon', "id": "2424", "name": "祇園守" },
	{ type: 'kamon', "id": "2425", "name": "立花家守" },
	{ type: 'kamon', "id": "2426", "name": "陽祇園守" },
	{ type: 'kamon', "id": "2427", "name": "因州守" },
	{ type: 'kamon', "id": "2428", "name": "竪祇園守" },
	{ type: 'kamon', "id": "2429", "name": "変わり二つ寺" },
	{ type: 'kamon', "id": "2430", "name": "平角祇園守" },
	{ type: 'kamon', "id": "2431", "name": "祇園守崩し" },
	{ type: 'kamon', "id": "2432", "name": "御札守丸" },
	{ type: 'kamon', "id": "2433", "name": "祇園守鶴" },
	{ type: 'kamon', "id": "2434", "name": "五つ割り万字" },
	{ type: 'kamon', "id": "2435", "name": "丸に左万字" },
	{ type: 'kamon', "id": "2436", "name": "丸に右万字" },
	{ type: 'kamon', "id": "2437", "name": "丸に隅立て右万字" },
	{ type: 'kamon', "id": "2438", "name": "万字菱" },
	{ type: 'kamon', "id": "2439", "name": "信濃戸隠神社" },
	{ type: 'kamon', "id": "2440", "name": "丸に鞠鋏み" },
	{ type: 'kamon', "id": "2441", "name": "皮手鞠" },
	{ type: 'kamon', "id": "2442", "name": "蹴鞠" },
	{ type: 'kamon', "id": "2443", "name": "鞠鋏" },
	{ type: 'kamon', "id": "2444", "name": "違い鞠鋏" },
	{ type: 'kamon', "id": "2445", "name": "鞠鋏に丸に二引両" },
	{ type: 'kamon', "id": "2446", "name": "太輪に鉞" },
	{ type: 'kamon', "id": "2447", "name": "丸に違い鉞" },
	{ type: 'kamon', "id": "2448", "name": "鉞菱" },
	{ type: 'kamon', "id": "2449", "name": "頭合わせ三つ鉞" },
	{ type: 'kamon', "id": "2450", "name": "五つ鉞車" },
	{ type: 'kamon', "id": "2451", "name": "枡" },
	{ type: 'kamon', "id": "2452", "name": "丸に枡" },
	{ type: 'kamon', "id": "2453", "name": "丸に二重枡" },
	{ type: 'kamon', "id": "2454", "name": "重ね枡" },
	{ type: 'kamon', "id": "2455", "name": "枡崩し" },
	{ type: 'kamon', "id": "2456", "name": "三つ入れ子枡" },
	{ type: 'kamon', "id": "2457", "name": "三つ入れ子枡に蔦" },
	{ type: 'kamon', "id": "2458", "name": "三つ寄せ枡" },
	{ type: 'kamon', "id": "2459", "name": "隅合わせ枡" },
	{ type: 'kamon', "id": "2460", "name": "三階斗枡" },
	{ type: 'kamon', "id": "2461", "name": "的" },
	{ type: 'kamon', "id": "2462", "name": "丸に的" },
	{ type: 'kamon', "id": "2463", "name": "的に当たり矢" },
	{ type: 'kamon', "id": "2464", "name": "丸的に当たり矢" },
	{ type: 'kamon', "id": "2465", "name": "的角" },
	{ type: 'kamon', "id": "2466", "name": "一つ松" },
	{ type: 'kamon', "id": "2467", "name": "丸に一つ松" },
	{ type: 'kamon', "id": "2468", "name": "櫛松" },
	{ type: 'kamon', "id": "2469", "name": "光琳松" },
	{ type: 'kamon', "id": "2470", "name": "対かい二つ松" },
	{ type: 'kamon', "id": "2471", "name": "三つ重ね松" },
	{ type: 'kamon', "id": "2472", "name": "丸に三つ重ね松" },
	{ type: 'kamon', "id": "2473", "name": "丸に右三階松" },
	{ type: 'kamon', "id": "2474", "name": "丸に左三階松" },
	{ type: 'kamon', "id": "2475", "name": "丸に荒枝付き右三階松" },
	{ type: 'kamon', "id": "2476", "name": "荒枝付き左三階松" },
	{ type: 'kamon', "id": "2477", "name": "丸に荒枝付き左三階松" },
	{ type: 'kamon', "id": "2478", "name": "三つ松" },
	{ type: 'kamon', "id": "2479", "name": "丸に三つ松" },
	{ type: 'kamon', "id": "2480", "name": "頭合わせ三つ松" },
	{ type: 'kamon', "id": "2481", "name": "三つ追い松葉の丸" },
	{ type: 'kamon', "id": "2482", "name": "松葉菱" },
	{ type: 'kamon', "id": "2483", "name": "三本松" },
	{ type: 'kamon', "id": "2484", "name": "割り若松" },
	{ type: 'kamon', "id": "2485", "name": "六角松葉" },
	{ type: 'kamon', "id": "2486", "name": "松葉柏" },
	{ type: 'kamon', "id": "2487", "name": "三つ松に花菱" },
	{ type: 'kamon', "id": "2488", "name": "丸に並び松" },
	{ type: 'kamon', "id": "2489", "name": "竹輪に二階松" },
	{ type: 'kamon', "id": "2490", "name": "中輪に中陰櫛松" },
	{ type: 'kamon', "id": "2491", "name": "糸輪に違い松葉" },
	{ type: 'kamon', "id": "2492", "name": "松葉桜" },
	{ type: 'kamon', "id": "2493", "name": "丸に左三階松に切り竹笹" },
	{ type: 'kamon', "id": "2494", "name": "巴松葉" },
	{ type: 'kamon', "id": "2495", "name": "三つ目" },
	{ type: 'kamon', "id": "2496", "name": "丸に三つ目" },
	{ type: 'kamon', "id": "2497", "name": "三つ目に一つ引き" },
	{ type: 'kamon', "id": "2498", "name": "平四つ目" },
	{ type: 'kamon', "id": "2499", "name": "丸に平四つ目" },
	{ type: 'kamon', "id": "2500", "name": "丸に七つ割平四つ目" },
	{ type: 'kamon', "id": "2501", "name": "隅立て四つ目" },
	{ type: 'kamon', "id": "2502", "name": "丸に隅立て四つ目" },
	{ type: 'kamon', "id": "2503", "name": "四つ目菱" },
	{ type: 'kamon', "id": "2504", "name": "丸に四つ目菱" },
	{ type: 'kamon', "id": "2505", "name": "糸輪に結び四つ目" },
	{ type: 'kamon', "id": "2506", "name": "院捻じ四つ目" },
	{ type: 'kamon', "id": "2507", "name": "堀尾目結" },
	{ type: 'kamon', "id": "2508", "name": "十六目" },
	{ type: 'kamon', "id": "2509", "name": "四つ目車" },
	{ type: 'kamon', "id": "2510", "name": "雪輪に覗き四つ目車" },
	{ type: 'kamon', "id": "2511", "name": "京都乃木神社" },
	{ type: 'kamon', "id": "2512", "name": "蔓反り四つ目菱" },
	{ type: 'kamon', "id": "2513", "name": "九つ目" },
	{ type: 'kamon', "id": "2514", "name": "重ね四つ目" },
	{ type: 'kamon', "id": "2515", "name": "剣四つ目" },
	{ type: 'kamon', "id": "2516", "name": "糸輪に割り四つ目" },
	{ type: 'kamon', "id": "2517", "name": "隅立て一つ目" },
	{ type: 'kamon', "id": "2518", "name": "丸に繋ぎ二つ目" },
	{ type: 'kamon', "id": "2519", "name": "丸に二つ目" },
	{ type: 'kamon', "id": "2520", "name": "陰三つ目" },
	{ type: 'kamon', "id": "2521", "name": "丸に三つ目に桜" },
	{ type: 'kamon', "id": "2522", "name": "三つ目菱" },
	{ type: 'kamon', "id": "2523", "name": "菅沼三つ目" },
	{ type: 'kamon', "id": "2524", "name": "陰隅立て四つ目" },
	{ type: 'kamon', "id": "2525", "name": "石持ち地抜き隅立て四つ目" },
	{ type: 'kamon', "id": "2526", "name": "丸に陰隅立て四つ目" },
	{ type: 'kamon', "id": "2527", "name": "糸輪に隅立て四つ目" },
	{ type: 'kamon', "id": "2528", "name": "太輪に隅立て四つ目" },
	{ type: 'kamon', "id": "2529", "name": "陰丸輪に隅立て四つ目" },
	{ type: 'kamon', "id": "2530", "name": "総陰丸に隅立て四つ目" },
	{ type: 'kamon', "id": "2531", "name": "細松皮菱に隅立て四つ目" },
	{ type: 'kamon', "id": "2532", "name": "糸輪に豆四つ目" },
	{ type: 'kamon', "id": "2533", "name": "丸に覗き四つ目" },
	{ type: 'kamon', "id": "2534", "name": "十一割り隅立て四つ目" },
	{ type: 'kamon', "id": "2535", "name": "七つ割り隅立て四つ目" },
	{ type: 'kamon', "id": "2536", "name": "七つ割り平四つ目" },
	{ type: 'kamon', "id": "2537", "name": "反り隅立て四つ目" },
	{ type: 'kamon', "id": "2538", "name": "丸に反り四つ目" },
	{ type: 'kamon', "id": "2539", "name": "中輪に陰反り四つ目" },
	{ type: 'kamon', "id": "2540", "name": "反り四つ目菱" },
	{ type: 'kamon', "id": "2541", "name": "丸に蔓四つ目" },
	{ type: 'kamon', "id": "2542", "name": "中陰四つ目菱" },
	{ type: 'kamon', "id": "2543", "name": "澪標" },
	{ type: 'kamon', "id": "2544", "name": "御簾" },
	{ type: 'kamon', "id": "2545", "name": "丸に御簾" },
	{ type: 'kamon', "id": "2546", "name": "丸に一の字" },
	{ type: 'kamon', "id": "2547", "name": "細輪に一の字" },
	{ type: 'kamon', "id": "2548", "name": "丸に三の文字" },
	{ type: 'kamon', "id": "2549", "name": "丸の八の文字" },
	{ type: 'kamon', "id": "2550", "name": "丸に十の文字" },
	{ type: 'kamon', "id": "2551", "name": "丸に井の文字" },
	{ type: 'kamon', "id": "2552", "name": "丸に上の字" },
	{ type: 'kamon', "id": "2553", "name": "丸に中の字" },
	{ type: 'kamon', "id": "2554", "name": "細輪に太の字" },
	{ type: 'kamon', "id": "2555", "name": "丸に本の字" },
	{ type: 'kamon', "id": "2556", "name": "丸に利の字" },
	{ type: 'kamon', "id": "2557", "name": "丸に木の字" },
	{ type: 'kamon', "id": "2558", "name": "丸に生の字" },
	{ type: 'kamon', "id": "2559", "name": "丸に一の角字" },
	{ type: 'kamon', "id": "2560", "name": "丸に本の角字" },
	{ type: 'kamon', "id": "2561", "name": "丸に福の角字" },
	{ type: 'kamon', "id": "2562", "name": "讃岐金比羅神社" },
	{ type: 'kamon', "id": "2563", "name": "角居" },
	{ type: 'kamon', "id": "2564", "name": "角藤" },
	{ type: 'kamon', "id": "2565", "name": "角王" },
	{ type: 'kamon', "id": "2566", "name": "角吉" },
	{ type: 'kamon', "id": "2567", "name": "角福" },
	{ type: 'kamon', "id": "2568", "name": "丸に三つ大の字" },
	{ type: 'kamon', "id": "2569", "name": "木瓜" },
	{ type: 'kamon', "id": "2570", "name": "丸に木瓜" },
	{ type: 'kamon', "id": "2571", "name": "石持ち地抜き木瓜" },
	{ type: 'kamon', "id": "2572", "name": "蔓木瓜" },
	{ type: 'kamon', "id": "2573", "name": "剣木瓜" },
	{ type: 'kamon', "id": "2574", "name": "丸に剣木瓜" },
	{ type: 'kamon', "id": "2575", "name": "唐木瓜" },
	{ type: 'kamon', "id": "2576", "name": "木瓜に二引き両" },
	{ type: 'kamon', "id": "2577", "name": "木瓜に片喰" },
	{ type: 'kamon', "id": "2578", "name": "木瓜菱" },
	{ type: 'kamon', "id": "2579", "name": "三つ盛り木瓜" },
	{ type: 'kamon', "id": "2580", "name": "丸に三つ盛り木瓜" },
	{ type: 'kamon', "id": "2581", "name": "三つ割り木瓜" },
	{ type: 'kamon', "id": "2582", "name": "剣四方木瓜" },
	{ type: 'kamon', "id": "2583", "name": "堀田木瓜" },
	{ type: 'kamon', "id": "2584", "name": "中津木瓜" },
	{ type: 'kamon', "id": "2585", "name": "庵木瓜" },
	{ type: 'kamon', "id": "2586", "name": "木瓜崩し" },
	{ type: 'kamon', "id": "2587", "name": "大田瓜" },
	{ type: 'kamon', "id": "2588", "name": "米田瓜" },
	{ type: 'kamon', "id": "2589", "name": "三つ組み合い木瓜" },
	{ type: 'kamon', "id": "2590", "name": "中陰五瓜に蔦" },
	{ type: 'kamon', "id": "2591", "name": "雲木瓜" },
	{ type: 'kamon', "id": "2592", "name": "木瓜形" },
	{ type: 'kamon', "id": "2593", "name": "三つ木瓜形" },
	{ type: 'kamon', "id": "2594", "name": "三つ結び木瓜" },
	{ type: 'kamon', "id": "2595", "name": "金輪木瓜" },
	{ type: 'kamon', "id": "2596", "name": "木瓜胡蝶" },
	{ type: 'kamon', "id": "2597", "name": "一つ桃" },
	{ type: 'kamon', "id": "2598", "name": "丸に桃" },
	{ type: 'kamon', "id": "2599", "name": "丸に葉付き桃" },
	{ type: 'kamon', "id": "2600", "name": "丸に葉敷き桃" },
	{ type: 'kamon', "id": "2601", "name": "抱き割り桃" },
	{ type: 'kamon', "id": "2602", "name": "三つ葉桃" },
	{ type: 'kamon', "id": "2603", "name": "丸に三つ盛り桃" },
	{ type: 'kamon', "id": "2604", "name": "丸に三つ剣桃" },
	{ type: 'kamon', "id": "2605", "name": "白餅" },
	{ type: 'kamon', "id": "2606", "name": "黒餅" },
	{ type: 'kamon', "id": "2607", "name": "菱餅" },
	{ type: 'kamon', "id": "2608", "name": "細輪に重ね餅" },
	{ type: 'kamon', "id": "2609", "name": "一つ百足の丸" },
	{ type: 'kamon', "id": "2610", "name": "石持ち地抜き百足の丸" },
	{ type: 'kamon', "id": "2611", "name": "子持ち百足" },
	{ type: 'kamon', "id": "2612", "name": "二つ追い百足" },
	{ type: 'kamon', "id": "2613", "name": "村濃" },
	{ type: 'kamon', "id": "2614", "name": "二階堂村濃" },
	{ type: 'kamon', "id": "2615", "name": "抱き茗荷" },
	{ type: 'kamon', "id": "2616", "name": "丸に抱き茗荷" },
	{ type: 'kamon', "id": "2617", "name": "陰抱き茗荷" },
	{ type: 'kamon', "id": "2618", "name": "抱き茗荷に片喰" },
	{ type: 'kamon', "id": "2619", "name": "稲垣茗荷" },
	{ type: 'kamon', "id": "2620", "name": "田村茗荷" },
	{ type: 'kamon', "id": "2621", "name": "一つ茗荷の丸" },
	{ type: 'kamon', "id": "2622", "name": "茗荷菱" },
	{ type: 'kamon', "id": "2623", "name": "外雪輪に抱き茗荷" },
	{ type: 'kamon', "id": "2624", "name": "入り込み抱き茗荷" },
	{ type: 'kamon', "id": "2625", "name": "立ち茗荷" },
	{ type: 'kamon', "id": "2626", "name": "三つ茗荷巴" },
	{ type: 'kamon', "id": "2627", "name": "三つ茗荷" },
	{ type: 'kamon', "id": "2628", "name": "頭合わせ六つ茗荷" },
	{ type: 'kamon', "id": "2629", "name": "中陰変わり抱き茗荷" },
	{ type: 'kamon', "id": "2630", "name": "根違い茗荷" },
	{ type: 'kamon', "id": "2631", "name": "一の関茗荷" },
	{ type: 'kamon', "id": "2632", "name": "入れ違い茗荷" },
	{ type: 'kamon', "id": "2633", "name": "二枚違い茗荷" },
	{ type: 'kamon', "id": "2634", "name": "江戸撫子" },
	{ type: 'kamon', "id": "2635", "name": "丸に江戸撫子" },
	{ type: 'kamon', "id": "2636", "name": "四つ葉撫子" },
	{ type: 'kamon', "id": "2637", "name": "秋月撫子" },
	{ type: 'kamon', "id": "2638", "name": "三つ割り撫子" },
	{ type: 'kamon', "id": "2639", "name": "撫子蝶" },
	{ type: 'kamon', "id": "2640", "name": "山口撫子" },
	{ type: 'kamon', "id": "2641", "name": "捻じ撫子" },
	{ type: 'kamon', "id": "2642", "name": "二つ盛り撫子" },
	{ type: 'kamon', "id": "2643", "name": "浮線撫子" },
	{ type: 'kamon', "id": "2644", "name": "対い割り撫子菱" },
	{ type: 'kamon', "id": "2645", "name": "丸に抱きなぎの葉" },
	{ type: 'kamon', "id": "2646", "name": "一つなぎの葉" },
	{ type: 'kamon', "id": "2647", "name": "二つなぎの葉" },
	{ type: 'kamon', "id": "2648", "name": "違いなぎの葉" },
	{ type: 'kamon', "id": "2649", "name": "丸に二つなぎの葉" },
	{ type: 'kamon', "id": "2650", "name": "丸に三つなぎの葉" },
	{ type: 'kamon', "id": "2651", "name": "青山浪" },
	{ type: 'kamon', "id": "2652", "name": "白浪" },
	{ type: 'kamon', "id": "2653", "name": "対かい浪" },
	{ type: 'kamon', "id": "2654", "name": "丸に対かい浪" },
	{ type: 'kamon', "id": "2655", "name": "七つ対かい浪" },
	{ type: 'kamon', "id": "2656", "name": "対浪に千鳥" },
	{ type: 'kamon', "id": "2657", "name": "波の丸" },
	{ type: 'kamon', "id": "2658", "name": "松田波" },
	{ type: 'kamon', "id": "2659", "name": "総陰波に源氏車" },
	{ type: 'kamon', "id": "2660", "name": "波巴" },
	{ type: 'kamon', "id": "2661", "name": "丸に青海波" },
	{ type: 'kamon', "id": "2662", "name": "立ち波" },
	{ type: 'kamon', "id": "2663", "name": "三つ割り波" },
	{ type: 'kamon', "id": "2664", "name": "追い波" },
	{ type: 'kamon', "id": "2665", "name": "丸に波" },
	{ type: 'kamon', "id": "2666", "name": "波輪" },
	{ type: 'kamon', "id": "2667", "name": "遠巻き波" },
	{ type: 'kamon', "id": "2668", "name": "糸輪に波の丸" },
	{ type: 'kamon', "id": "2669", "name": "中輪に波の丸" },
	{ type: 'kamon', "id": "2670", "name": "三つ波巴" },
	{ type: 'kamon', "id": "2671", "name": "三つ波巴（２）" },
	{ type: 'kamon', "id": "2672", "name": "波に兎" },
	{ type: 'kamon', "id": "2673", "name": "波に燕" },
	{ type: 'kamon', "id": "2674", "name": "丸に波に水車" },
	{ type: 'kamon', "id": "2675", "name": "立ち波に兎" },
	{ type: 'kamon', "id": "2676", "name": "一つ波丸" },
	{ type: 'kamon', "id": "2677", "name": "波巴（２）" },
	{ type: 'kamon', "id": "2678", "name": "一つ波巴" },
	{ type: 'kamon', "id": "2679", "name": "細菱に覗き青海波" },
	{ type: 'kamon', "id": "2680", "name": "対かい波に三つ葉" },
	{ type: 'kamon', "id": "2681", "name": "丸に三枚葉南天" },
	{ type: 'kamon', "id": "2682", "name": "南天桐" },
	{ type: 'kamon', "id": "2683", "name": "抱き南天" },
	{ type: 'kamon', "id": "2684", "name": "枝南天" },
	{ type: 'kamon', "id": "2685", "name": "南天車" },
	{ type: 'kamon', "id": "2686", "name": "亀甲三つ南天" },
	{ type: 'kamon', "id": "2687", "name": "雪輪に南天" },
	{ type: 'kamon', "id": "2688", "name": "南天揚羽蝶" },
	{ type: 'kamon', "id": "2689", "name": "対い南天" },
	{ type: 'kamon', "id": "2690", "name": "三つ葉南天" },
	{ type: 'kamon', "id": "2691", "name": "丸に三つ葉南天" },
	{ type: 'kamon', "id": "2692", "name": "三つ割り南天" },
	{ type: 'kamon', "id": "2693", "name": "三つ割り追い南天" },
	{ type: 'kamon', "id": "2694", "name": "南天胡蝶" },
	{ type: 'kamon', "id": "2695", "name": "梨の花" },
	{ type: 'kamon', "id": "2696", "name": "丸に梨の切り口" },
	{ type: 'kamon', "id": "2697", "name": "丸に葉付き茄子" },
	{ type: 'kamon', "id": "2698", "name": "三つ葉茄子" },
	{ type: 'kamon', "id": "2699", "name": "五つ茄子" },
	{ type: 'kamon', "id": "2700", "name": "茄子桐" },
	{ type: 'kamon', "id": "2701", "name": "茄子枝丸" },
	{ type: 'kamon', "id": "2702", "name": "三つ割り追い茄子" },
	{ type: 'kamon', "id": "2703", "name": "三つ割り茄子" },
	{ type: 'kamon', "id": "2704", "name": "変わり茄子桐" },
	{ type: 'kamon', "id": "2705", "name": "鶏の丸" },
	{ type: 'kamon', "id": "2706", "name": "対かい熨斗" },
	{ type: 'kamon', "id": "2707", "name": "抱き結び熨斗" },
	{ type: 'kamon', "id": "2708", "name": "紀伊日前国懸神社" },
	{ type: 'kamon', "id": "2709", "name": "熨斗輪に三つ鱗" },
	{ type: 'kamon', "id": "2710", "name": "三つ寄せ熨斗" },
	{ type: 'kamon', "id": "2711", "name": "丸に立ち束ね熨斗" },
	{ type: 'kamon', "id": "2712", "name": "分銅熨斗" },
	{ type: 'kamon', "id": "2713", "name": "三つ組み折り熨斗" },
	{ type: 'kamon', "id": "2714", "name": "二つ引通し抜簾" },
	{ type: 'kamon', "id": "2715", "name": "引違い抜簾" },
	{ type: 'kamon', "id": "2716", "name": "丸に違い櫂" },
	{ type: 'kamon', "id": "2717", "name": "三本並び櫂" },
	{ type: 'kamon', "id": "2718", "name": "三つ追い重ね櫂" },
	{ type: 'kamon', "id": "2719", "name": "三つ重ね櫂" },
	{ type: 'kamon', "id": "2720", "name": "櫂井桁" },
	{ type: 'kamon', "id": "2721", "name": "三つ追い重ね櫂に三星" },
	{ type: 'kamon', "id": "2722", "name": "中輪に三つ組み櫂" },
	{ type: 'kamon', "id": "2723", "name": "三つ組み折り櫂" },
	{ type: 'kamon', "id": "2724", "name": "立ち沢瀉" },
	{ type: 'kamon', "id": "2725", "name": "丸に立ち沢瀉" },
	{ type: 'kamon', "id": "2726", "name": "石持ち地抜き立ち沢瀉" },
	{ type: 'kamon', "id": "2727", "name": "向こう花沢瀉" },
	{ type: 'kamon', "id": "2728", "name": "抱き沢瀉" },
	{ type: 'kamon', "id": "2729", "name": "丸に抱き沢瀉" },
	{ type: 'kamon', "id": "2730", "name": "陰抱き沢瀉" },
	{ type: 'kamon', "id": "2731", "name": "丸に変わり抱き沢瀉" },
	{ type: 'kamon', "id": "2732", "name": "毛利家長門沢瀉" },
	{ type: 'kamon', "id": "2733", "name": "三つ追い葉沢瀉" },
	{ type: 'kamon', "id": "2734", "name": "変わり立ち沢瀉" },
	{ type: 'kamon', "id": "2735", "name": "沢瀉に水" },
	{ type: 'kamon', "id": "2736", "name": "浮線沢瀉" },
	{ type: 'kamon', "id": "2737", "name": "八重向沢瀉" },
	{ type: 'kamon', "id": "2738", "name": "福島沢瀉" },
	{ type: 'kamon', "id": "2739", "name": "一つ沢瀉の丸" },
	{ type: 'kamon', "id": "2740", "name": "沢瀉菱" },
	{ type: 'kamon', "id": "2741", "name": "三本沢瀉" },
	{ type: 'kamon', "id": "2742", "name": "三つ追い重ね葉沢瀉" },
	{ type: 'kamon', "id": "2743", "name": "丸に陰立ち沢瀉" },
	{ type: 'kamon', "id": "2744", "name": "沢瀉蝶" },
	{ type: 'kamon', "id": "2745", "name": "違い葉沢瀉" },
	{ type: 'kamon', "id": "2746", "name": "沢瀉巴" },
	{ type: 'kamon', "id": "2747", "name": "沢瀉七宝" },
	{ type: 'kamon', "id": "2748", "name": "水野沢瀉" },
	{ type: 'kamon', "id": "2749", "name": "総陰丸に立ち沢瀉" },
	{ type: 'kamon', "id": "2750", "name": "雪輪に立ち沢瀉" },
	{ type: 'kamon', "id": "2751", "name": "鉄砲菱に立ち沢瀉" },
	{ type: 'kamon', "id": "2752", "name": "糸輪に立ち沢瀉" },
	{ type: 'kamon', "id": "2753", "name": "一つ沢瀉菱" },
	{ type: 'kamon', "id": "2754", "name": "糸菱に陰覗き沢瀉" },
	{ type: 'kamon', "id": "2755", "name": "三つ沢瀉の丸" },
	{ type: 'kamon', "id": "2756", "name": "変わり抱き葉沢瀉" },
	{ type: 'kamon', "id": "2757", "name": "丸に葉沢瀉" },
	{ type: 'kamon', "id": "2758", "name": "入れ違い葉沢瀉" },
	{ type: 'kamon', "id": "2759", "name": "子持ち抱き沢瀉" },
	{ type: 'kamon', "id": "2760", "name": "変わり抱き沢瀉" },
	{ type: 'kamon', "id": "2761", "name": "細抱き沢瀉" },
	{ type: 'kamon', "id": "2762", "name": "石持ち地抜き抱き沢瀉" },
	{ type: 'kamon', "id": "2763", "name": "変わり長門沢瀉" },
	{ type: 'kamon', "id": "2764", "name": "入れ違い葉沢瀉" },
	{ type: 'kamon', "id": "2765", "name": "抱き沢瀉菱" },
	{ type: 'kamon', "id": "2766", "name": "変わり抱き沢瀉菱" },
	{ type: 'kamon', "id": "2767", "name": "隅立て角沢瀉" },
	{ type: 'kamon', "id": "2768", "name": "軸違い並び葉沢瀉" },
	{ type: 'kamon', "id": "2769", "name": "抱き葉沢瀉に木瓜" },
	{ type: 'kamon', "id": "2770", "name": "三つ盛り沢瀉" },
	{ type: 'kamon', "id": "2771", "name": "三つ寄せ沢瀉" },
	{ type: 'kamon', "id": "2772", "name": "中津沢瀉" },
	{ type: 'kamon', "id": "2773", "name": "三つ花沢瀉" },
	{ type: 'kamon', "id": "2774", "name": "三つ寄せ変わり沢瀉" },
	{ type: 'kamon', "id": "2775", "name": "沢瀉桐" },
	{ type: 'kamon', "id": "2776", "name": "変わり沢瀉桐" },
	{ type: 'kamon', "id": "2777", "name": "三つ割り追い沢瀉" },
	{ type: 'kamon', "id": "2778", "name": "変わり沢瀉巴" },
	{ type: 'kamon', "id": "2779", "name": "三つ追い葉沢瀉（２）" },
	{ type: 'kamon', "id": "2780", "name": "変わり三つ追い沢瀉" },
	{ type: 'kamon', "id": "2781", "name": "三つ花沢瀉の丸" },
	{ type: 'kamon', "id": "2782", "name": "沢瀉の丸" },
	{ type: 'kamon', "id": "2783", "name": "三つ割り追い葉沢瀉に桔梗" },
	{ type: 'kamon', "id": "2784", "name": "四つ追い葉沢瀉菱" },
	{ type: 'kamon', "id": "2785", "name": "五つ寄せ変わり沢瀉" },
	{ type: 'kamon', "id": "2786", "name": "五つ捻じ沢瀉" },
	{ type: 'kamon', "id": "2787", "name": "六つ蔓沢瀉" },
	{ type: 'kamon', "id": "2788", "name": "沢瀉桔梗" },
	{ type: 'kamon', "id": "2789", "name": "沢瀉車" },
	{ type: 'kamon', "id": "2790", "name": "一つ花沢瀉" },
	{ type: 'kamon', "id": "2791", "name": "変わり向こう花沢瀉" },
	{ type: 'kamon', "id": "2792", "name": "向こう蔓沢瀉" },
	{ type: 'kamon', "id": "2793", "name": "六角に葉沢瀉" },
	{ type: 'kamon', "id": "2794", "name": "変わり木瓜沢瀉" },
	{ type: 'kamon', "id": "2795", "name": "割り沢瀉" },
	{ type: 'kamon', "id": "2796", "name": "沢瀉胡蝶" },
	{ type: 'kamon', "id": "2797", "name": "変わり沢瀉蝶" },
	{ type: 'kamon', "id": "2798", "name": "大関沢瀉" },
	{ type: 'kamon', "id": "2799", "name": "沢瀉折り鶴" },
	{ type: 'kamon', "id": "2800", "name": "沢瀉鶴" },
	{ type: 'kamon', "id": "2801", "name": "尾長鳥" },
	{ type: 'kamon', "id": "2802", "name": "伊達鴛鴦丸" },
	{ type: 'kamon', "id": "2803", "name": "対い鴛鴦丸" },
	{ type: 'kamon', "id": "2804", "name": "五本骨扇" },
	{ type: 'kamon', "id": "2805", "name": "丸に五本骨扇" },
	{ type: 'kamon', "id": "2806", "name": "日の丸扇" },
	{ type: 'kamon', "id": "2807", "name": "丸に日の丸扇" },
	{ type: 'kamon', "id": "2808", "name": "大房扇" },
	{ type: 'kamon', "id": "2809", "name": "丸に房付き扇" },
	{ type: 'kamon', "id": "2810", "name": "三つ扇" },
	{ type: 'kamon', "id": "2811", "name": "丸に三つ扇" },
	{ type: 'kamon', "id": "2812", "name": "陰三つ扇" },
	{ type: 'kamon', "id": "2813", "name": "中輪に三本骨扇" },
	{ type: 'kamon', "id": "2814", "name": "七本骨扇" },
	{ type: 'kamon', "id": "2815", "name": "七本骨扇丸に九曜" },
	{ type: 'kamon', "id": "2816", "name": "丸に重ね扇" },
	{ type: 'kamon', "id": "2817", "name": "三つ反り扇" },
	{ type: 'kamon', "id": "2818", "name": "七本骨雁木扇" },
	{ type: 'kamon', "id": "2819", "name": "高崎扇" },
	{ type: 'kamon', "id": "2820", "name": "扇蝶" },
	{ type: 'kamon', "id": "2821", "name": "檜扇" },
	{ type: 'kamon', "id": "2822", "name": "陰檜扇" },
	{ type: 'kamon', "id": "2823", "name": "丹波扇" },
	{ type: 'kamon', "id": "2824", "name": "雁木檜扇" },
	{ type: 'kamon', "id": "2825", "name": "並び扇" },
	{ type: 'kamon', "id": "2826", "name": "丸に並び扇" },
	{ type: 'kamon', "id": "2827", "name": "丸に違い扇" },
	{ type: 'kamon', "id": "2828", "name": "三本扇" },
	{ type: 'kamon', "id": "2829", "name": "三本組み扇" },
	{ type: 'kamon', "id": "2830", "name": "立花扇" },
	{ type: 'kamon', "id": "2831", "name": "島原扇" },
	{ type: 'kamon', "id": "2832", "name": "渡辺扇" },
	{ type: 'kamon', "id": "2833", "name": "四本扇菱" },
	{ type: 'kamon', "id": "2834", "name": "四つ扇丸" },
	{ type: 'kamon', "id": "2835", "name": "石持ち地抜き檜扇" },
	{ type: 'kamon', "id": "2836", "name": "秋田扇" },
	{ type: 'kamon', "id": "2837", "name": "山崎扇" },
	{ type: 'kamon', "id": "2838", "name": "房丸檜扇" },
	{ type: 'kamon', "id": "2839", "name": "糸輪に尻合わせ檜扇" },
	{ type: 'kamon', "id": "2840", "name": "扇に蔦" },
	{ type: 'kamon', "id": "2841", "name": "三つ盛り扇" },
	{ type: 'kamon', "id": "2842", "name": "菱扇" },
	{ type: 'kamon', "id": "2843", "name": "八本扇車" },
	{ type: 'kamon', "id": "2844", "name": "三つ雁木扇菱" },
	{ type: 'kamon', "id": "2845", "name": "鱗形三つ扇" },
	{ type: 'kamon', "id": "2846", "name": "中開き三本扇" },
	{ type: 'kamon', "id": "2847", "name": "房蝶花形" },
	{ type: 'kamon', "id": "2848", "name": "浮線扇" },
	{ type: 'kamon', "id": "2849", "name": "変わり扇蝶" },
	{ type: 'kamon', "id": "2850", "name": "陰扇揚羽蝶" },
	{ type: 'kamon', "id": "2851", "name": "陰松扇蝶" },
	{ type: 'kamon', "id": "2852", "name": "沢瀉扇" },
	{ type: 'kamon', "id": "2853", "name": "重ね扇に片喰" },
	{ type: 'kamon', "id": "2854", "name": "丸に房付き日の丸扇" },
	{ type: 'kamon', "id": "2855", "name": "五本扇の骨" },
	{ type: 'kamon', "id": "2856", "name": "中輪に七本扇の骨" },
	{ type: 'kamon', "id": "2857", "name": "十本扇の骨" },
	{ type: 'kamon', "id": "2858", "name": "保田大洲流し" },
	{ type: 'kamon', "id": "2859", "name": "蛇籠大洲流し" },
	{ type: 'kamon', "id": "2860", "name": "蘭" },
	{ type: 'kamon', "id": "2861", "name": "抱き蘭の丸" },
	{ type: 'kamon', "id": "2862", "name": "三つ割り蘭" },
	{ type: 'kamon', "id": "2863", "name": "裏蘭" },
	{ type: 'kamon', "id": "2864", "name": "枝蘭" },
	{ type: 'kamon', "id": "2865", "name": "対い蘭" },
	{ type: 'kamon', "id": "2866", "name": "抱き蘭" },
	{ type: 'kamon', "id": "2867", "name": "花蘭丸" },
	{ type: 'kamon', "id": "2868", "name": "落ち蘭" },
	{ type: 'kamon', "id": "2869", "name": "真向き蘭の花" },
	{ type: 'kamon', "id": "2870", "name": "折れ枝蘭" },
	{ type: 'kamon', "id": "2871", "name": "三つ集め蘭" },
	{ type: 'kamon', "id": "2872", "name": "三つ割り蘭に片喰" },
	{ type: 'kamon', "id": "2873", "name": "笹竜胆" },
	{ type: 'kamon', "id": "2874", "name": "丸に笹竜胆" },
	{ type: 'kamon', "id": "2875", "name": "陰笹竜胆" },
	{ type: 'kamon', "id": "2876", "name": "石持ち地抜き竜胆" },
	{ type: 'kamon', "id": "2877", "name": "三つ葉竜胆" },
	{ type: 'kamon', "id": "2878", "name": "竜胆車" },
	{ type: 'kamon', "id": "2879", "name": "丸に竜胆車" },
	{ type: 'kamon', "id": "2880", "name": "変わり竜胆蝶" },
	{ type: 'kamon', "id": "2881", "name": "三つ割り三つ葉竜胆" },
	{ type: 'kamon', "id": "2882", "name": "笹竜胆菱" },
	{ type: 'kamon', "id": "2883", "name": "石川竜胆" },
	{ type: 'kamon', "id": "2884", "name": "頭合わせ三つ笹竜胆" },
	{ type: 'kamon', "id": "2885", "name": "三つ折り葉竜胆" },
	{ type: 'kamon', "id": "2886", "name": "曹洞宗越前永平寺" },
	{ type: 'kamon', "id": "2887", "name": "雪輪に龍胆" },
	{ type: 'kamon', "id": "2888", "name": "亀山龍胆" },
	{ type: 'kamon', "id": "2889", "name": "抱き龍胆" },
	{ type: 'kamon', "id": "2890", "name": "七宝龍胆" },
	{ type: 'kamon', "id": "2891", "name": "二つ葉龍胆" },
	{ type: 'kamon', "id": "2892", "name": "三枚笹龍胆" },
	{ type: 'kamon', "id": "2893", "name": "慈光寺龍胆" },
	{ type: 'kamon', "id": "2894", "name": "久我龍胆車" },
	{ type: 'kamon', "id": "2895", "name": "埋み龍胆" },
	{ type: 'kamon', "id": "2896", "name": "枝龍胆" },
	{ type: 'kamon', "id": "2897", "name": "丸に輪鼓" },
	{ type: 'kamon', "id": "2898", "name": "丸に中陰輪鼓" },
	{ type: 'kamon', "id": "2899", "name": "立つ字輪鼓" },
	{ type: 'kamon', "id": "2900", "name": "並び輪鼓" },
	{ type: 'kamon', "id": "2901", "name": "三つ並び輪鼓" },
	{ type: 'kamon', "id": "2902", "name": "三つ盛り輪鼓" },
	{ type: 'kamon', "id": "2903", "name": "輪鼓" },
	{ type: 'kamon', "id": "2904", "name": "花形輪鼓" },
	{ type: 'kamon', "id": "2905", "name": "変わり輪鼓" },
	{ type: 'kamon', "id": "2906", "name": "三つ寄せ輪鼓" },
	{ type: 'kamon', "id": "2907", "name": "四つ合い輪鼓" },
	{ type: 'kamon', "id": "2908", "name": "三つ輪鼓" },
	{ type: 'kamon', "id": "2909", "name": "四つ輪鼓" },
	{ type: 'kamon', "id": "2910", "name": "亀甲輪鼓" },
	{ type: 'kamon', "id": "2911", "name": "反り輪鼓" },
	{ type: 'kamon', "id": "2912", "name": "輪鼓に手鞠" },
	{ type: 'kamon', "id": "2913", "name": "輪宝" },
	{ type: 'kamon', "id": "2914", "name": "三つ又輪宝" },
	{ type: 'kamon', "id": "2915", "name": "六つ輪宝" },
	{ type: 'kamon', "id": "2916", "name": "大日輪宝" },
	{ type: 'kamon', "id": "2917", "name": "行者輪宝" },
	{ type: 'kamon', "id": "2918", "name": "三宅輪宝" },
	{ type: 'kamon', "id": "2919", "name": "下総成田山新勝寺" },
	{ type: 'kamon', "id": "2920", "name": "真言宗輪宝" },
	{ type: 'kamon', "id": "2921", "name": "加納輪宝" },
	{ type: 'kamon', "id": "2922", "name": "天台宗菊輪宝" },
	{ type: 'kamon', "id": "2923", "name": "筆形輪宝" },
	{ type: 'kamon', "id": "2924", "name": "輪宝菱" },
	{ type: 'kamon', "id": "2925", "name": "五つ輪宝" },
	{ type: 'kamon', "id": "2926", "name": "丸に三つ輪宝" },
	{ type: 'kamon', "id": "2927", "name": "三つ割り輪宝" },
	{ type: 'kamon', "id": "2928", "name": "変わり六つ輪宝" },
	{ type: 'kamon', "id": "2929", "name": "陰三つ輪宝" },
	{ type: 'kamon', "id": "2930", "name": "丸に一つ蝋燭" },
	{ type: 'kamon', "id": "2931", "name": "石持ち地抜き蝋燭" },
	{ type: 'kamon', "id": "2932", "name": "細輪に三つ蝋燭" },
	{ type: 'kamon', "id": "2933", "name": "三つ割り蝋燭" },
	{ type: 'kamon', "id": "2934", "name": "丸に二つ蝋燭" },
	{ type: 'kamon', "id": "2935", "name": "糸輪に重ね蝋燭" },
	{ type: 'kamon', "id": "2936", "name": "中太輪に違い蝋燭" },
	{ type: 'kamon', "id": "2937", "name": "丸に三つ盛り蝋燭" },
	{ type: 'kamon', "id": "2938", "name": "中輪に舵" },
	{ type: 'kamon', "id": "2939", "name": "丸に違い舵" },
	{ type: 'kamon', "id": "2940", "name": "三つ寄せ舵" },
	{ type: 'kamon', "id": "2941", "name": "龍の丸" },
	{ type: 'kamon', "id": "2942", "name": "龍剣の丸" },
	{ type: 'kamon', "id": "2943", "name": "日蓮宗龍の丸" },
	{ type: 'kamon', "id": "2944", "name": "雨龍菱" },
	{ type: 'kamon', "id": "2945", "name": "龍の爪" },
	{ type: 'kamon', "id": "2946", "name": "雨龍" },
	{ type: 'kamon', "id": "2947", "name": "角雨龍" },
	{ type: 'kamon', "id": "2948", "name": "違い龍の爪" },
	{ type: 'kamon', "id": "2949", "name": "龍の爪に宝珠" },
	{ type: 'kamon', "id": "2950", "name": "丸に龍剣に一文字" },
	{ type: 'kamon', "id": "2951", "name": "天龍の丸" },
	{ type: 'kamon', "id": "2952", "name": "隅立て雨龍" },
	{ type: 'kamon', "id": "2953", "name": "松皮雨龍" },
	{ type: 'kamon', "id": "2954", "name": "上り下り雨龍" },
	{ type: 'kamon', "id": "2955", "name": "違い龍の角" },
	{ type: 'kamon', "id": "2956", "name": "龍の鱗" },
	{ type: 'kamon', "id": "2957", "name": "三つ重ね杯" },
	{ type: 'kamon', "id": "2958", "name": "三つ杯" },
	{ type: 'kamon', "id": "2959", "name": "杯に水" },
	{ type: 'kamon', "id": "2960", "name": "桜" },
	{ type: 'kamon', "id": "2961", "name": "丸に桜" },
	{ type: 'kamon', "id": "2962", "name": "糸輪に豆桜" },
	{ type: 'kamon', "id": "2963", "name": "陰桜" },
	{ type: 'kamon', "id": "2964", "name": "中陰桜" },
	{ type: 'kamon', "id": "2965", "name": "石持ち地抜き桜" },
	{ type: 'kamon', "id": "2966", "name": "八重桜" },
	{ type: 'kamon', "id": "2967", "name": "左近桜" },
	{ type: 'kamon', "id": "2968", "name": "細川桜" },
	{ type: 'kamon', "id": "2969", "name": "三つ割り桜" },
	{ type: 'kamon', "id": "2970", "name": "山桜" },
	{ type: 'kamon', "id": "2971", "name": "丸に山桜" },
	{ type: 'kamon', "id": "2972", "name": "陰山桜" },
	{ type: 'kamon', "id": "2973", "name": "向こう山桜" },
	{ type: 'kamon', "id": "2974", "name": "八重大和桜" },
	{ type: 'kamon', "id": "2975", "name": "丸に細川桜" },
	{ type: 'kamon', "id": "2976", "name": "三つ割り山桜" },
	{ type: 'kamon', "id": "2977", "name": "桜浮線綾に平四つ目" },
	{ type: 'kamon', "id": "2978", "name": "短冊に桜" },
	{ type: 'kamon', "id": "2979", "name": "陸前塩釜神社" },
	{ type: 'kamon', "id": "2980", "name": "京都平野神社" },
	{ type: 'kamon', "id": "2981", "name": "大和吉野神宮" },
	{ type: 'kamon', "id": "2982", "name": "桜井桜" },
	{ type: 'kamon', "id": "2983", "name": "裏桜" },
	{ type: 'kamon', "id": "2984", "name": "月落ち桜" },
	{ type: 'kamon', "id": "2985", "name": "雪月桜" },
	{ type: 'kamon', "id": "2986", "name": "結び桜" },
	{ type: 'kamon', "id": "2987", "name": "陰裏桜" },
	{ type: 'kamon', "id": "2988", "name": "抱き桜" },
	{ type: 'kamon', "id": "2989", "name": "三つ横見桜" },
	{ type: 'kamon', "id": "2991", "name": "桜に巴" },
	{ type: 'kamon', "id": "2993", "name": "鐶桜" },
	{ type: 'kamon', "id": "2994", "name": "丸に一つ算木" },
	{ type: 'kamon', "id": "2995", "name": "丸に二つ算木" },
	{ type: 'kamon', "id": "2996", "name": "丸に三つ算木" },
	{ type: 'kamon', "id": "2997", "name": "石持ち地抜き算木" },
	{ type: 'kamon', "id": "2998", "name": "丸に十の文字算木" },
	{ type: 'kamon', "id": "2999", "name": "丸に縱橫算木" },
	{ type: 'kamon', "id": "3000", "name": "丸に中陰十の算木" },
	{ type: 'kamon', "id": "3001", "name": "算木" },
	{ type: 'kamon', "id": "3002", "name": "丸に立て算木" },
	{ type: 'kamon', "id": "3003", "name": "二つ算木" },
	{ type: 'kamon', "id": "3004", "name": "丸に二つ割り二つ算木" },
	{ type: 'kamon', "id": "3005", "name": "糸輪に括り猿" },
	{ type: 'kamon', "id": "3006", "name": "三つ寄り括り猿" },
	{ type: 'kamon', "id": "3007", "name": "三枚笹" },
	{ type: 'kamon', "id": "3008", "name": "五枚笹" },
	{ type: 'kamon', "id": "3009", "name": "九枚笹" },
	{ type: 'kamon', "id": "3010", "name": "丸に九枚笹" },
	{ type: 'kamon', "id": "3011", "name": "陰九枚笹" },
	{ type: 'kamon', "id": "3012", "name": "六角九枚笹" },
	{ type: 'kamon', "id": "3013", "name": "丸に頭合わせ九枚笹" },
	{ type: 'kamon', "id": "3014", "name": "丸に十五笹" },
	{ type: 'kamon', "id": "3015", "name": "対かい笹菱" },
	{ type: 'kamon', "id": "3016", "name": "丸に篠笹" },
	{ type: 'kamon', "id": "3017", "name": "根笹" },
	{ type: 'kamon', "id": "3018", "name": "丸に根笹" },
	{ type: 'kamon', "id": "3019", "name": "丸に三本足篠笹" },
	{ type: 'kamon', "id": "3020", "name": "丸に七五三篠笹" },
	{ type: 'kamon', "id": "3021", "name": "丸に変わり根笹" },
	{ type: 'kamon', "id": "3022", "name": "丸に変わり篠笹" },
	{ type: 'kamon', "id": "3023", "name": "雪持ち笹" },
	{ type: 'kamon', "id": "3024", "name": "雪持ち根笹" },
	{ type: 'kamon', "id": "3025", "name": "割り篠笹" },
	{ type: 'kamon', "id": "3027", "name": "切り竹に笹" },
	{ type: 'kamon', "id": "3028", "name": "丸に切り竹に笹" },
	{ type: 'kamon', "id": "3029", "name": "丸に変わり竹に笹" },
	{ type: 'kamon', "id": "3030", "name": "丸に二本竹笹" },
	{ type: 'kamon', "id": "3031", "name": "丸に五枚竹笹に雀" },
	{ type: 'kamon', "id": "3032", "name": "鳥居笹" },
	{ type: 'kamon', "id": "3033", "name": "十五枚笹に対かい雀" },
	{ type: 'kamon', "id": "3034", "name": "竹輪笹に対かい雀" },
	{ type: 'kamon', "id": "3035", "name": "米沢笹" },
	{ type: 'kamon', "id": "3036", "name": "仙台笹" },
	{ type: 'kamon', "id": "3037", "name": "丸に切り竹笹に笠" },
	{ type: 'kamon', "id": "3038", "name": "柳生笹" },
	{ type: 'kamon', "id": "3039", "name": "上杉笹" },
	{ type: 'kamon', "id": "3040", "name": "笹の丸" },
	{ type: 'kamon', "id": "3041", "name": "岩村笹" },
	{ type: 'kamon', "id": "3042", "name": "熊笹" },
	{ type: 'kamon', "id": "3043", "name": "丸に三枚笹" },
	{ type: 'kamon', "id": "3044", "name": "抱き竹笹に対かい雀" },
	{ type: 'kamon', "id": "3045", "name": "抱き竹笹に木瓜" },
	{ type: 'kamon', "id": "3046", "name": "石竹" },
	{ type: 'kamon', "id": "3047", "name": "丸に石竹" },
	{ type: 'kamon', "id": "3048", "name": "陰石竹" },
	{ type: 'kamon', "id": "3049", "name": "糸輪に覗き石竹" },
	{ type: 'kamon', "id": "3050", "name": "三味駒" },
	{ type: 'kamon', "id": "3051", "name": "糸輪に三味駒" },
	{ type: 'kamon', "id": "3052", "name": "対い三味駒" },
	{ type: 'kamon', "id": "3053", "name": "三つ盛り三味駒" },
	{ type: 'kamon', "id": "3054", "name": "四つ三味駒" },
	{ type: 'kamon', "id": "3055", "name": "丸に三味駒" },
	{ type: 'kamon', "id": "3056", "name": "丸に二つ三味駒" },
	{ type: 'kamon', "id": "3057", "name": "丸に三つ盛り三味駒" },
	{ type: 'kamon', "id": "3058", "name": "三味駒菱" },
	{ type: 'kamon', "id": "3059", "name": "将棋駒" },
	{ type: 'kamon', "id": "3060", "name": "丸に並び将棋駒" },
	{ type: 'kamon', "id": "3061", "name": "丸に駒" },
	{ type: 'kamon', "id": "3062", "name": "並び駒" },
	{ type: 'kamon', "id": "3063", "name": "三つ並び駒" },
	{ type: 'kamon', "id": "3064", "name": "三つ盛り駒" },
	{ type: 'kamon', "id": "3065", "name": "糸輪に頭合わせ四つ駒" },
	{ type: 'kamon', "id": "3066", "name": "歯朶の丸" },
	{ type: 'kamon', "id": "3067", "name": "下がり歯朶の丸" },
	{ type: 'kamon', "id": "3068", "name": "三つ歯朶" },
	{ type: 'kamon', "id": "3069", "name": "歯朶巴" },
	{ type: 'kamon', "id": "3070", "name": "歯朶蝶" },
	{ type: 'kamon', "id": "3071", "name": "歯朶蝶（２）" },
	{ type: 'kamon', "id": "3072", "name": "実付き歯朶" },
	{ type: 'kamon', "id": "3073", "name": "浮線歯朶" },
	{ type: 'kamon', "id": "3074", "name": "歯朶菱" },
	{ type: 'kamon', "id": "3075", "name": "七宝" },
	{ type: 'kamon', "id": "3076", "name": "丸に七宝" },
	{ type: 'kamon', "id": "3077", "name": "七宝に花菱" },
	{ type: 'kamon', "id": "3078", "name": "丸に七宝花菱" },
	{ type: 'kamon', "id": "3079", "name": "陰七宝" },
	{ type: 'kamon', "id": "3080", "name": "陰七宝に桔梗" },
	{ type: 'kamon', "id": "3081", "name": "星付き七宝" },
	{ type: 'kamon', "id": "3082", "name": "大岡七宝" },
	{ type: 'kamon', "id": "3083", "name": "七宝に木瓜" },
	{ type: 'kamon', "id": "3084", "name": "星付き七宝に州浜" },
	{ type: 'kamon', "id": "3085", "name": "秋月七宝" },
	{ type: 'kamon', "id": "3086", "name": "四つ割り七宝花菱" },
	{ type: 'kamon', "id": "3087", "name": "持ち合い三つ七宝" },
	{ type: 'kamon', "id": "3088", "name": "持ち合い四つ七宝" },
	{ type: 'kamon', "id": "3089", "name": "持ち合い四つ七宝に花角" },
	{ type: 'kamon', "id": "3090", "name": "七宝菱に花菱" },
	{ type: 'kamon', "id": "3091", "name": "割り七宝菱に花菱" },
	{ type: 'kamon', "id": "3092", "name": "陰割り七宝菱に花菱" },
	{ type: 'kamon', "id": "3093", "name": "中陰切り七宝に剣片喰" },
	{ type: 'kamon', "id": "3094", "name": "九つ繋ぎ七宝" },
	{ type: 'kamon', "id": "3095", "name": "丸に一本杉" },
	{ type: 'kamon', "id": "3096", "name": "二本杉" },
	{ type: 'kamon', "id": "3097", "name": "丸に二本杉" },
	{ type: 'kamon', "id": "3098", "name": "三つ盛り杉" },
	{ type: 'kamon', "id": "3099", "name": "三本杉" },
	{ type: 'kamon', "id": "3100", "name": "丸に三本杉" },
	{ type: 'kamon', "id": "3101", "name": "丸に重ね三本杉" },
	{ type: 'kamon', "id": "3102", "name": "丸に三つ鱗杉" },
	{ type: 'kamon', "id": "3103", "name": "一本杉" },
	{ type: 'kamon', "id": "3104", "name": "光琳一本杉" },
	{ type: 'kamon', "id": "3105", "name": "杉巴" },
	{ type: 'kamon', "id": "3106", "name": "丸に一つ鱗杉" },
	{ type: 'kamon', "id": "3107", "name": "社頭の杉" },
	{ type: 'kamon', "id": "3108", "name": "陰一本杉" },
	{ type: 'kamon', "id": "3109", "name": "重ね三本杉" },
	{ type: 'kamon', "id": "3110", "name": "抱き割り杉" },
	{ type: 'kamon', "id": "3111", "name": "追い三つ割り杉" },
	{ type: 'kamon', "id": "3112", "name": "尻合わせ五本杉" },
	{ type: 'kamon', "id": "3113", "name": "州浜" },
	{ type: 'kamon', "id": "3114", "name": "丸に州浜" },
	{ type: 'kamon', "id": "3115", "name": "中陰州浜" },
	{ type: 'kamon', "id": "3116", "name": "石持ち地抜き州浜" },
	{ type: 'kamon', "id": "3117", "name": "丸に蔓三つ州浜" },
	{ type: 'kamon', "id": "3118", "name": "秋津州浜" },
	{ type: 'kamon', "id": "3119", "name": "雪輪に州浜" },
	{ type: 'kamon', "id": "3120", "name": "三つ盛り州浜" },
	{ type: 'kamon', "id": "3121", "name": "丸に剣三つ州浜" },
	{ type: 'kamon', "id": "3122", "name": "州浜団子" },
	{ type: 'kamon', "id": "3123", "name": "総陰丸に州浜" },
	{ type: 'kamon', "id": "3124", "name": "光琳州浜" },
	{ type: 'kamon', "id": "3125", "name": "花形州浜" },
	{ type: 'kamon', "id": "3126", "name": "朧州浜" },
	{ type: 'kamon', "id": "3127", "name": "花州浜" },
	{ type: 'kamon', "id": "3128", "name": "原形州浜" },
	{ type: 'kamon', "id": "3129", "name": "桔梗州浜" },
	{ type: 'kamon', "id": "3130", "name": "蔓州浜" },
	{ type: 'kamon', "id": "3131", "name": "月輪に豆州浜" },
	{ type: 'kamon', "id": "3132", "name": "丸に一文字に州浜" },
	{ type: 'kamon', "id": "3133", "name": "丸に州浜に釘抜き" },
	{ type: 'kamon', "id": "3134", "name": "陰州浜に木瓜" },
	{ type: 'kamon', "id": "3135", "name": "陰州浜に剣片喰" },
	{ type: 'kamon', "id": "3136", "name": "糸菱に覗き州浜" },
	{ type: 'kamon', "id": "3137", "name": "州浜崩し" },
	{ type: 'kamon', "id": "3138", "name": "比翼州浜" },
	{ type: 'kamon', "id": "3139", "name": "違い州浜" },
	{ type: 'kamon', "id": "3140", "name": "丸に頭合わせ州浜" },
	{ type: 'kamon', "id": "3141", "name": "丸に組み州浜" },
	{ type: 'kamon', "id": "3142", "name": "丸に持ち合い州浜" },
	{ type: 'kamon', "id": "3143", "name": "頭合わせ三つ州浜" },
	{ type: 'kamon', "id": "3144", "name": "尻合わせ三つ州浜" },
	{ type: 'kamon', "id": "3145", "name": "三つ盛り変わり州浜" },
	{ type: 'kamon', "id": "3146", "name": "丸に頭合わせ三つ州浜" },
	{ type: 'kamon', "id": "3147", "name": "丸に尻合わせ三つ州浜" },
	{ type: 'kamon', "id": "3148", "name": "三つ割り州浜" },
	{ type: 'kamon', "id": "3149", "name": "五つ州浜崩し" },
	{ type: 'kamon', "id": "3150", "name": "台州浜" },
	{ type: 'kamon', "id": "3151", "name": "州浜桐" },
	{ type: 'kamon', "id": "3152", "name": "中陰浮線州浜" },
	{ type: 'kamon', "id": "3153", "name": "水仙" },
	{ type: 'kamon', "id": "3154", "name": "水仙の丸" },
	{ type: 'kamon', "id": "3155", "name": "五つ水仙車" },
	{ type: 'kamon', "id": "3156", "name": "葉敷き向こう水仙" },
	{ type: 'kamon', "id": "3157", "name": "水仙に水" },
	{ type: 'kamon', "id": "3158", "name": "変わり水仙の丸" },
	{ type: 'kamon', "id": "3159", "name": "抱き水仙" },
	{ type: 'kamon', "id": "3160", "name": "五つ鐶に抱き水仙" },
	{ type: 'kamon', "id": "3161", "name": "篠輪に変わり抱き水仙" },
	{ type: 'kamon', "id": "3162", "name": "根引き水仙" },
	{ type: 'kamon', "id": "3163", "name": "六つ水車" },
	{ type: 'kamon', "id": "3164", "name": "八つ水車" },
	{ type: 'kamon', "id": "3165", "name": "中輪に水車に水" },
	{ type: 'kamon', "id": "3166", "name": "直違" },
	{ type: 'kamon', "id": "3167", "name": "丸に直違" },
	{ type: 'kamon', "id": "3168", "name": "三つ組み直違" },
	{ type: 'kamon', "id": "3169", "name": "組み直違" },
	{ type: 'kamon', "id": "3170", "name": "四つ組み直違" },
	{ type: 'kamon', "id": "3171", "name": "六つ組み直違" },
	{ type: 'kamon', "id": "3172", "name": "変わり直違" },
	{ type: 'kamon', "id": "3173", "name": "六つ組み直違（２）" },
	{ type: 'kamon', "id": "3174", "name": "遠山九の字直違菱" },
	{ type: 'kamon', "id": "3175", "name": "丹羽直違" },
	{ type: 'kamon', "id": "3176", "name": "丸に子持ち三つ組み直違" },
	{ type: 'kamon', "id": "3177", "name": "九の字直違菱" },
	{ type: 'kamon', "id": "3178", "name": "炭の切り口" },
	{ type: 'kamon', "id": "3179", "name": "三つ盛り炭の切り口" },
	{ type: 'kamon', "id": "3180", "name": "一つ菫" },
	{ type: 'kamon', "id": "3181", "name": "抱き菫" },
	{ type: 'kamon', "id": "3182", "name": "三つ葉菫" },
	{ type: 'kamon', "id": "3183", "name": "菫車" },
	{ type: 'kamon', "id": "3184", "name": "菫胡蝶" },
	{ type: 'kamon', "id": "3185", "name": "薄の丸" },
	{ type: 'kamon', "id": "3186", "name": "花薄の丸" },
	{ type: 'kamon', "id": "3187", "name": "薄に露の丸" },
	{ type: 'kamon', "id": "3188", "name": "雪輪に薄の露" },
	{ type: 'kamon', "id": "3189", "name": "抱き薄" },
	{ type: 'kamon', "id": "3190", "name": "丸に二つ鈴" },
	{ type: 'kamon', "id": "3191", "name": "中輪に三つ鈴" },
	{ type: 'kamon', "id": "3192", "name": "太輪に鈴" },
	{ type: 'kamon', "id": "3193", "name": "丸に重ね鈴" },
	{ type: 'kamon', "id": "3194", "name": "立ち神楽鈴" },
	{ type: 'kamon', "id": "3195", "name": "神楽鈴" },
	{ type: 'kamon', "id": "3196", "name": "雀の丸" },
	{ type: 'kamon', "id": "3197", "name": "三つ集め雀" },
	{ type: 'kamon', "id": "3198", "name": "丸に飛び雀" },
	{ type: 'kamon', "id": "3199", "name": "上下対い雀" },
	{ type: 'kamon', "id": "3200", "name": "六角三つ雀" },
	{ type: 'kamon', "id": "3201", "name": "雪輪に膨ら雀菱" },
	{ type: 'kamon', "id": "3202", "name": "二羽膨ら雀菱" },
	{ type: 'kamon', "id": "3203", "name": "丸に対い雀" },
	{ type: 'kamon', "id": "3204", "name": "膨ら雀" },
	{ type: 'kamon', "id": "3205", "name": "雪輪に雛雀" },
	{ type: 'kamon', "id": "3206", "name": "重ね膨ら雀" },
	{ type: 'kamon', "id": "3207", "name": "変わり雀" },
	{ type: 'kamon', "id": "3208", "name": "三つ追い雀" },
	{ type: 'kamon', "id": "3209", "name": "三つ飛び雀" },
	{ type: 'kamon', "id": "3210", "name": "鐶菱に膨ら雀" },
	{ type: 'kamon', "id": "3211", "name": "対い膨ら雀" },
	{ type: 'kamon', "id": "3212", "name": "陰対い膨ら雀菱" },
	{ type: 'kamon', "id": "3213", "name": "霧に飛び雀" },
	{ type: 'kamon', "id": "3214", "name": "中陰三つ寄せ膨ら雀" },
	{ type: 'kamon', "id": "3215", "name": "竹輪に尻合わせ変わり三つ雀" },
	{ type: 'kamon', "id": "3216", "name": "錫杖" },
	{ type: 'kamon', "id": "3217", "name": "棕櫚" },
	{ type: 'kamon', "id": "3218", "name": "光琳棕櫚" },
	{ type: 'kamon', "id": "3219", "name": "抱き棕櫚" },
	{ type: 'kamon', "id": "3220", "name": "三つ割り棕櫚" },
	{ type: 'kamon', "id": "3221", "name": "丸に変わり棕櫚" },
	{ type: 'kamon', "id": "3222", "name": "加納抱き棕櫚" },
	{ type: 'kamon', "id": "3223", "name": "入れ違い棕櫚" },
	{ type: 'kamon', "id": "3224", "name": "対い棕櫚菱" },
	{ type: 'kamon', "id": "3225", "name": "棕櫚枝の丸" },
	{ type: 'kamon', "id": "3226", "name": "太丸に一つ鷹の羽" },
	{ type: 'kamon', "id": "3227", "name": "丸に並び鷹の羽" },
	{ type: 'kamon', "id": "3228", "name": "違い鷹の羽" },
	{ type: 'kamon', "id": "3229", "name": "丸に違い鷹の羽" },
	{ type: 'kamon', "id": "3230", "name": "中輪に細違い鷹の羽" },
	{ type: 'kamon', "id": "3231", "name": "中輪に陰の違い鷹の羽" },
	{ type: 'kamon', "id": "3232", "name": "丸に右重ね違い鷹の羽" },
	{ type: 'kamon', "id": "3233", "name": "浅野鷹の羽" },
	{ type: 'kamon', "id": "3234", "name": "阿部鷹の羽" },
	{ type: 'kamon', "id": "3235", "name": "高木鷹の羽" },
	{ type: 'kamon', "id": "3236", "name": "井上鷹の羽" },
	{ type: 'kamon', "id": "3237", "name": "抱き鷹の羽" },
	{ type: 'kamon', "id": "3238", "name": "八つ鷹の羽車" },
	{ type: 'kamon', "id": "3239", "name": "鷹の丸" },
	{ type: 'kamon', "id": "3240", "name": "豊前英彦山神社" },
	{ type: 'kamon', "id": "3241", "name": "並び鷹の羽" },
	{ type: 'kamon', "id": "3242", "name": "一つの引両に違い鷹の羽" },
	{ type: 'kamon', "id": "3243", "name": "月輪に総覗き違い鷹の羽" },
	{ type: 'kamon', "id": "3244", "name": "三つ並び鷹の羽" },
	{ type: 'kamon', "id": "3245", "name": "五つ鷹の羽丸に違い鷹の羽" },
	{ type: 'kamon', "id": "3246", "name": "一つ折れ鷹の羽" },
	{ type: 'kamon', "id": "3247", "name": "違い割り鷹の羽" },
	{ type: 'kamon', "id": "3248", "name": "陰陽違い鷹の羽" },
	{ type: 'kamon', "id": "3249", "name": "白川鷹の羽" },
	{ type: 'kamon', "id": "3250", "name": "三つ反り鷹の羽" },
	{ type: 'kamon', "id": "3251", "name": "繋ぎ鷹" },
	{ type: 'kamon', "id": "3252", "name": "中輪に鷹" },
	{ type: 'kamon', "id": "3253", "name": "一本鷹の羽" },
	{ type: 'kamon', "id": "3254", "name": "陰痩せ折れ鷹の羽" },
	{ type: 'kamon', "id": "3255", "name": "中輪に並び鷹の羽" },
	{ type: 'kamon', "id": "3256", "name": "久世鷹の羽" },
	{ type: 'kamon', "id": "3257", "name": "反り違い鷹の羽" },
	{ type: 'kamon', "id": "3258", "name": "中輪に割り違い鷹の羽" },
	{ type: 'kamon', "id": "3259", "name": "二つ折れ鷹の羽" },
	{ type: 'kamon', "id": "3260", "name": "割り折れ鷹の羽菱" },
	{ type: 'kamon', "id": "3261", "name": "石持ち地抜き違い鷹の羽" },
	{ type: 'kamon', "id": "3262", "name": "細輪に陰違い鷹の羽" },
	{ type: 'kamon', "id": "3263", "name": "陰陽畑違い鷹の羽" },
	{ type: 'kamon', "id": "3264", "name": "中村鷹の羽" },
	{ type: 'kamon', "id": "3265", "name": "糸輪に斑入り違い鷹の羽" },
	{ type: 'kamon', "id": "3266", "name": "芸州鷹の羽" },
	{ type: 'kamon', "id": "3267", "name": "柳井鷹の羽" },
	{ type: 'kamon', "id": "3268", "name": "団扇に違い鷹の羽" },
	{ type: 'kamon', "id": "3269", "name": "違い鷹の羽に一つ巴" },
	{ type: 'kamon', "id": "3270", "name": "雪輪菱に違い鷹の羽" },
	{ type: 'kamon', "id": "3271", "name": "丸に割り鷹の羽井桁" },
	{ type: 'kamon', "id": "3272", "name": "糸菱に豆違い鷹の羽" },
	{ type: 'kamon', "id": "3273", "name": "一つ折れ鷹の羽丸" },
	{ type: 'kamon', "id": "3274", "name": "斑入り一つ鷹の羽巴" },
	{ type: 'kamon', "id": "3275", "name": "対い割り鷹の羽" },
	{ type: 'kamon', "id": "3276", "name": "細抱き鷹の羽" },
	{ type: 'kamon', "id": "3277", "name": "抱き鷹の羽に釘抜き" },
	{ type: 'kamon', "id": "3278", "name": "外抱き鷹の羽に立花" },
	{ type: 'kamon', "id": "3279", "name": "並び鷹の羽に割り鷹の羽" },
	{ type: 'kamon', "id": "3280", "name": "三つ違い鷹の羽" },
	{ type: 'kamon', "id": "3281", "name": "丸に三つ鷹の羽" },
	{ type: 'kamon', "id": "3282", "name": "丸に覗き鷹の羽" },
	{ type: 'kamon', "id": "3283", "name": "鷹の羽蛇の目" },
	{ type: 'kamon', "id": "3284", "name": "剣三つ折れ鷹の羽" },
	{ type: 'kamon', "id": "3285", "name": "三つ折れ鷹の羽丸" },
	{ type: 'kamon', "id": "3286", "name": "鷹の羽井桁" },
	{ type: 'kamon', "id": "3287", "name": "糸菱に覗き違い鷹の羽" },
	{ type: 'kamon', "id": "3288", "name": "五本鷹の羽" },
	{ type: 'kamon', "id": "3289", "name": "丸に五つ鷹の羽車" },
	{ type: 'kamon', "id": "3290", "name": "五つ鷹の羽丸に桔梗" },
	{ type: 'kamon', "id": "3291", "name": "六つ割り鷹の羽車" },
	{ type: 'kamon', "id": "3292", "name": "六つ鷹の羽車" },
	{ type: 'kamon', "id": "3293", "name": "石持ち地抜き八つ鷹の羽車" },
	{ type: 'kamon', "id": "3294", "name": "浮線鷹の羽" },
	{ type: 'kamon', "id": "3295", "name": "割り浮線鷹の羽" },
	{ type: 'kamon', "id": "3296", "name": "鷹の羽揚羽蝶" },
	{ type: 'kamon', "id": "3297", "name": "変わり鷹の羽蝶" },
	{ type: 'kamon', "id": "3298", "name": "宝結び" },
	{ type: 'kamon', "id": "3299", "name": "陰輪に宝結び" },
	{ type: 'kamon', "id": "3300", "name": "宝結び蝶" },
	{ type: 'kamon', "id": "3301", "name": "角宝結び" },
	{ type: 'kamon', "id": "3302", "name": "石持ち地抜き角宝結び" },
	{ type: 'kamon', "id": "3303", "name": "三つ寄せ角宝結び" },
	{ type: 'kamon', "id": "3304", "name": "華蔓宝結び" },
	{ type: 'kamon', "id": "3305", "name": "変わり華蔓宝結び" },
	{ type: 'kamon', "id": "3306", "name": "三つ寄せ一つ宝結び" },
	{ type: 'kamon', "id": "3307", "name": "蝶形宝結び" },
	{ type: 'kamon', "id": "3308", "name": "鍬形宝結び" },
	{ type: 'kamon', "id": "3309", "name": "丸に並び切り竹" },
	{ type: 'kamon', "id": "3310", "name": "糸輪に三つ切り竹" },
	{ type: 'kamon', "id": "3311", "name": "竹林竹" },
	{ type: 'kamon', "id": "3312", "name": "丸に切り竹十字" },
	{ type: 'kamon', "id": "3313", "name": "丸に横並び切り竹" },
	{ type: 'kamon', "id": "3314", "name": "違い切り竹" },
	{ type: 'kamon', "id": "3315", "name": "筍桐" },
	{ type: 'kamon', "id": "3316", "name": "稲荷玉" },
	{ type: 'kamon', "id": "3317", "name": "光琳玉" },
	{ type: 'kamon', "id": "3318", "name": "一つ宝珠" },
	{ type: 'kamon', "id": "3319", "name": "丸に宝珠" },
	{ type: 'kamon', "id": "3320", "name": "尻合わせ三つ宝珠" },
	{ type: 'kamon', "id": "3321", "name": "五つ捻じ宝珠" },
	{ type: 'kamon', "id": "3322", "name": "三つ割り追い宝珠" },
	{ type: 'kamon', "id": "3323", "name": "橘" },
	{ type: 'kamon', "id": "3324", "name": "丸に橘" },
	{ type: 'kamon', "id": "3325", "name": "陰橘" },
	{ type: 'kamon', "id": "3326", "name": "石持ち地抜き橘" },
	{ type: 'kamon', "id": "3327", "name": "久世橘" },
	{ type: 'kamon', "id": "3328", "name": "彦根橘" },
	{ type: 'kamon', "id": "3329", "name": "丸に三つ橘" },
	{ type: 'kamon', "id": "3330", "name": "菊座橘" },
	{ type: 'kamon', "id": "3331", "name": "丸に菊座橘" },
	{ type: 'kamon', "id": "3332", "name": "中陰橘" },
	{ type: 'kamon', "id": "3333", "name": "浮線橘" },
	{ type: 'kamon', "id": "3334", "name": "抱き橘" },
	{ type: 'kamon', "id": "3335", "name": "対かい橘" },
	{ type: 'kamon', "id": "3336", "name": "三つ盛り橘" },
	{ type: 'kamon', "id": "3337", "name": "丸に三つ盛り橘" },
	{ type: 'kamon', "id": "3338", "name": "三つ橘" },
	{ type: 'kamon', "id": "3339", "name": "丸に三つ橘" },
	{ type: 'kamon', "id": "3340", "name": "三つ葉橘" },
	{ type: 'kamon', "id": "3341", "name": "黒田橘" },
	{ type: 'kamon', "id": "3342", "name": "菱に覗き橘" },
	{ type: 'kamon', "id": "3343", "name": "日蓮宗橘" },
	{ type: 'kamon', "id": "3344", "name": "橘枝丸" },
	{ type: 'kamon', "id": "3345", "name": "向こう裏橘" },
	{ type: 'kamon', "id": "3346", "name": "京都平安神社" },
	{ type: 'kamon', "id": "3347", "name": "大和大和神社" },
	{ type: 'kamon', "id": "3348", "name": "平安橋" },
	{ type: 'kamon', "id": "3349", "name": "菱に橘" },
	{ type: 'kamon', "id": "3350", "name": "割り橘" },
	{ type: 'kamon', "id": "3351", "name": "橘桐" },
	{ type: 'kamon', "id": "3352", "name": "橘鶴" },
	{ type: 'kamon', "id": "3353", "name": "向こう橘" },
	{ type: 'kamon', "id": "3354", "name": "団扇橘" },
	{ type: 'kamon', "id": "3355", "name": "利休橘" },
	{ type: 'kamon', "id": "3356", "name": "橘菱" },
	{ type: 'kamon', "id": "3357", "name": "橘に水" },
	{ type: 'kamon', "id": "3358", "name": "丸に橘（２）" },
	{ type: 'kamon', "id": "3359", "name": "六つ鉄線" },
	{ type: 'kamon', "id": "3360", "name": "丸に鉄線" },
	{ type: 'kamon', "id": "3361", "name": "鉄線の花" },
	{ type: 'kamon', "id": "3362", "name": "三つ鉄線" },
	{ type: 'kamon', "id": "3363", "name": "五つ鉄線" },
	{ type: 'kamon', "id": "3364", "name": "永井鉄線" },
	{ type: 'kamon', "id": "3365", "name": "八つ鉄線" },
	{ type: 'kamon', "id": "3366", "name": "三つ割り鉄線" },
	{ type: 'kamon', "id": "3367", "name": "三つ葉鉄線" },
	{ type: 'kamon', "id": "3368", "name": "捻じ鉄線" },
	{ type: 'kamon', "id": "3369", "name": "光琳鉄線" },
	{ type: 'kamon', "id": "3370", "name": "花鉄線崩し" },
	{ type: 'kamon', "id": "3371", "name": "杏葉鉄線" },
	{ type: 'kamon', "id": "3372", "name": "丸に千鳥" },
	{ type: 'kamon', "id": "3373", "name": "浪輪に陰千鳥" },
	{ type: 'kamon', "id": "3374", "name": "陰三つ千鳥" },
	{ type: 'kamon', "id": "3375", "name": "陰飛び千鳥" },
	{ type: 'kamon', "id": "3376", "name": "波に千鳥" },
	{ type: 'kamon', "id": "3377", "name": "千木" },
	{ type: 'kamon', "id": "3378", "name": "千木堅魚木" },
	{ type: 'kamon', "id": "3379", "name": "千切り" },
	{ type: 'kamon', "id": "3380", "name": "丸に千切り" },
	{ type: 'kamon', "id": "3381", "name": "中陰千切り" },
	{ type: 'kamon', "id": "3382", "name": "陰千切り" },
	{ type: 'kamon', "id": "3383", "name": "千切り崩し" },
	{ type: 'kamon', "id": "3384", "name": "四方千切り" },
	{ type: 'kamon', "id": "3385", "name": "丸に変わり違い千切り" },
	{ type: 'kamon', "id": "3386", "name": "隅切り千切り" },
	{ type: 'kamon', "id": "3387", "name": "千切り菱" },
	{ type: 'kamon', "id": "3388", "name": "結び千切り" },
	{ type: 'kamon', "id": "3389", "name": "三つ寄せ千切り" },
	{ type: 'kamon', "id": "3390", "name": "中輪に唐辛子" },
	{ type: 'kamon', "id": "3391", "name": "丸に違い唐辛子" },
	{ type: 'kamon', "id": "3392", "name": "唐辛子巴" },
	{ type: 'kamon', "id": "3393", "name": "三つ唐辛子巴" },
	{ type: 'kamon', "id": "3394", "name": "五つ唐辛子車" },
	{ type: 'kamon', "id": "3395", "name": "右一つ巴" },
	{ type: 'kamon', "id": "3396", "name": "丸に右一つ巴" },
	{ type: 'kamon', "id": "3397", "name": "左一つ巴" },
	{ type: 'kamon', "id": "3398", "name": "丸に左一つ巴" },
	{ type: 'kamon', "id": "3399", "name": "右二つ巴" },
	{ type: 'kamon', "id": "3400", "name": "丸に右二つ巴" },
	{ type: 'kamon', "id": "3401", "name": "左二つ巴" },
	{ type: 'kamon', "id": "3402", "name": "丸に左二つ巴" },
	{ type: 'kamon', "id": "3403", "name": "中陰左二つ巴" },
	{ type: 'kamon', "id": "3404", "name": "右三つ巴" },
	{ type: 'kamon', "id": "3405", "name": "丸に右三つ巴" },
	{ type: 'kamon', "id": "3406", "name": "左三つ巴" },
	{ type: 'kamon', "id": "3407", "name": "丸に左三つ巴" },
	{ type: 'kamon', "id": "3408", "name": "陰左三つ巴" },
	{ type: 'kamon', "id": "3409", "name": "板倉巴" },
	{ type: 'kamon', "id": "3410", "name": "二つ巴菱" },
	{ type: 'kamon', "id": "3411", "name": "まが玉巴" },
	{ type: 'kamon', "id": "3412", "name": "陰陽まが玉巴" },
	{ type: 'kamon', "id": "3413", "name": "三つ巴文字の丸" },
	{ type: 'kamon', "id": "3414", "name": "紀伊熊野生神社" },
	{ type: 'kamon', "id": "3415", "name": "子持ち左三つ巴" },
	{ type: 'kamon', "id": "3416", "name": "二葉巴" },
	{ type: 'kamon', "id": "3417", "name": "抱き波巴" },
	{ type: 'kamon', "id": "3418", "name": "浮線巴" },
	{ type: 'kamon', "id": "3419", "name": "違い蔓巴" },
	{ type: 'kamon', "id": "3420", "name": "結び巴" },
	{ type: 'kamon', "id": "3421", "name": "巴の字丸" },
	{ type: 'kamon', "id": "3422", "name": "降り一つ巴" },
	{ type: 'kamon', "id": "3423", "name": "陰左一つ巴" },
	{ type: 'kamon', "id": "3424", "name": "左金輪巴" },
	{ type: 'kamon', "id": "3425", "name": "中輪に右金輪巴" },
	{ type: 'kamon', "id": "3426", "name": "五つ金輪巴" },
	{ type: 'kamon', "id": "3427", "name": "巴の字桐" },
	{ type: 'kamon', "id": "3428", "name": "持ち合い巴の字丸" },
	{ type: 'kamon', "id": "3429", "name": "三つ巴の字菱" },
	{ type: 'kamon', "id": "3430", "name": "隅入り角持ちに一つ巴" },
	{ type: 'kamon', "id": "3431", "name": "対い蜻蛉" },
	{ type: 'kamon', "id": "3432", "name": "丸に対い蜻蛉" },
	{ type: 'kamon', "id": "3433", "name": "三つ蜻蛉" },
	{ type: 'kamon', "id": "3434", "name": "中輪に三つ竹蜻蛉" },
	{ type: 'kamon', "id": "3435", "name": "鳥居" },
	{ type: 'kamon', "id": "3436", "name": "丸に神宮鳥居" },
	{ type: 'kamon', "id": "3437", "name": "中輪に鳥居" },
	{ type: 'kamon', "id": "3438", "name": "三つ組鳥居" },
	{ type: 'kamon', "id": "3439", "name": "糸輪に三つ鳥居" },
	{ type: 'kamon', "id": "3440", "name": "鳥居に三つ石" },
	{ type: 'kamon', "id": "3441", "name": "鳥居垣に巴" },
	{ type: 'kamon', "id": "3442", "name": "鳥居に竹" },
	{ type: 'kamon', "id": "3443", "name": "鳥居の上に鳥二羽に石畳" },
	{ type: 'kamon', "id": "3444", "name": "鳥居に大文字" },
	{ type: 'kamon', "id": "3445", "name": "月に星" },
	{ type: 'kamon', "id": "3446", "name": "月に霞" },
	{ type: 'kamon', "id": "3447", "name": "三日月" },
	{ type: 'kamon', "id": "3448", "name": "黒田枡形に月" },
	{ type: 'kamon', "id": "3449", "name": "黒田月に水" },
	{ type: 'kamon', "id": "3450", "name": "大関朧月" },
	{ type: 'kamon', "id": "3451", "name": "岩城れんじに月" },
	{ type: 'kamon', "id": "3452", "name": "真向くき月星" },
	{ type: 'kamon', "id": "3453", "name": "月輪に豆七曜" },
	{ type: 'kamon', "id": "3454", "name": "月に三つ星" },
	{ type: 'kamon', "id": "3455", "name": "三つ寄せ月星" },
	{ type: 'kamon', "id": "3456", "name": "月に八曜" },
	{ type: 'kamon', "id": "3457", "name": "半月" },
	{ type: 'kamon', "id": "3458", "name": "陰月" },
	{ type: 'kamon', "id": "3459", "name": "真向き月" },
	{ type: 'kamon', "id": "3460", "name": "繋ぎ月" },
	{ type: 'kamon', "id": "3461", "name": "月に雲" },
	{ type: 'kamon', "id": "3462", "name": "右向き陰日向月星" },
	{ type: 'kamon', "id": "3463", "name": "月に北斗星" },
	{ type: 'kamon', "id": "3464", "name": "抱き角" },
	{ type: 'kamon', "id": "3465", "name": "丸に抱き角" },
	{ type: 'kamon', "id": "3466", "name": "糸輪に覗き抱き角" },
	{ type: 'kamon', "id": "3467", "name": "変わり抱き角" },
	{ type: 'kamon', "id": "3468", "name": "三つ割り角" },
	{ type: 'kamon', "id": "3469", "name": "陰抱き角" },
	{ type: 'kamon', "id": "3470", "name": "丸に違い袋角" },
	{ type: 'kamon', "id": "3471", "name": "六つ袋角" },
	{ type: 'kamon', "id": "3472", "name": "石持ち地抜き抱き角" },
	{ type: 'kamon', "id": "3473", "name": "違い角" },
	{ type: 'kamon', "id": "3474", "name": "抱き角に日足" },
	{ type: 'kamon', "id": "3475", "name": "外割り角に蔦" },
	{ type: 'kamon', "id": "3476", "name": "角万字" },
	{ type: 'kamon', "id": "3477", "name": "細抱き角" },
	{ type: 'kamon', "id": "3478", "name": "四つ又抱き角" },
	{ type: 'kamon', "id": "3479", "name": "違い抱き角" },
	{ type: 'kamon', "id": "3480", "name": "細輪に三つ又抱き角" },
	{ type: 'kamon', "id": "3481", "name": "抱き割り角" },
	{ type: 'kamon', "id": "3482", "name": "太抱き割り角" },
	{ type: 'kamon', "id": "3483", "name": "抱き角に一つ巴" },
	{ type: 'kamon', "id": "3484", "name": "抱き角に五三の桐" },
	{ type: 'kamon', "id": "3485", "name": "三つ追い角に揚羽蝶" },
	{ type: 'kamon', "id": "3486", "name": "丸に抱き角（２）" },
	{ type: 'kamon', "id": "3487", "name": "丸に抱き角（３）" },
	{ type: 'kamon', "id": "3488", "name": "抱き角に楓" },
	{ type: 'kamon', "id": "3489", "name": "鶴の丸" },
	{ type: 'kamon', "id": "3490", "name": "丸に鶴の丸" },
	{ type: 'kamon', "id": "3491", "name": "陰鶴の丸" },
	{ type: 'kamon', "id": "3492", "name": "舞い鶴" },
	{ type: 'kamon', "id": "3493", "name": "諏訪鶴" },
	{ type: 'kamon', "id": "3494", "name": "丸に飛び鶴" },
	{ type: 'kamon', "id": "3495", "name": "対かい鶴" },
	{ type: 'kamon', "id": "3496", "name": "喰い合い対かい鶴" },
	{ type: 'kamon', "id": "3497", "name": "南部鶴" },
	{ type: 'kamon', "id": "3498", "name": "三羽鶴" },
	{ type: 'kamon', "id": "3499", "name": "光琳鶴の丸" },
	{ type: 'kamon', "id": "3500", "name": "光琳亀甲鶴" },
	{ type: 'kamon', "id": "3501", "name": "有職鶴" },
	{ type: 'kamon', "id": "3502", "name": "光琳向かい鶴菱" },
	{ type: 'kamon', "id": "3503", "name": "三羽光琳鶴" },
	{ type: 'kamon', "id": "3504", "name": "長の文字鶴" },
	{ type: 'kamon', "id": "3505", "name": "静岡光長寺" },
	{ type: 'kamon', "id": "3506", "name": "折り鶴の丸" },
	{ type: 'kamon', "id": "3507", "name": "鶴亀" },
	{ type: 'kamon', "id": "3508", "name": "上下対い鶴" },
	{ type: 'kamon', "id": "3509", "name": "折り鶴" },
	{ type: 'kamon', "id": "3510", "name": "鶴菱" },
	{ type: 'kamon', "id": "3511", "name": "降り光琳鶴" },
	{ type: 'kamon', "id": "3512", "name": "丸に光琳造り鶴" },
	{ type: 'kamon', "id": "3513", "name": "対い白鶴" },
	{ type: 'kamon', "id": "3514", "name": "光琳細鶴菱" },
	{ type: 'kamon', "id": "3515", "name": "三つ鱗鶴" },
	{ type: 'kamon', "id": "3516", "name": "鳥居鶴の丸" },
	{ type: 'kamon', "id": "3517", "name": "佐伯鶴の丸" },
	{ type: 'kamon', "id": "3518", "name": "宝慈院鶴の丸" },
	{ type: 'kamon', "id": "3519", "name": "中輪に立ち鶴" },
	{ type: 'kamon', "id": "3520", "name": "対い鶴に若松" },
	{ type: 'kamon', "id": "3521", "name": "飛び鶴" },
	{ type: 'kamon', "id": "3522", "name": "真向き光琳鶴" },
	{ type: 'kamon', "id": "3523", "name": "上下対い鶴菱" },
	{ type: 'kamon', "id": "3524", "name": "金輪光琳鶴" },
	{ type: 'kamon', "id": "3525", "name": "光琳鶴蔦" },
	{ type: 'kamon', "id": "3526", "name": "三つ真向き折り鶴" },
	{ type: 'kamon', "id": "3527", "name": "日蓮宗鶴の丸" },
	{ type: 'kamon', "id": "3528", "name": "中陰光琳鳥" },
	{ type: 'kamon', "id": "3529", "name": "糸輪に対い鶴" },
	{ type: 'kamon', "id": "3530", "name": "糸菱に二つ折り鶴" },
	{ type: 'kamon', "id": "3531", "name": "変わり三つ光琳鶴" },
	{ type: 'kamon', "id": "3532", "name": "桔梗形光琳鶴" },
	{ type: 'kamon', "id": "3533", "name": "丸に梅形光琳鶴" },
	{ type: 'kamon', "id": "3534", "name": "三つ鶴" },
	{ type: 'kamon', "id": "3535", "name": "三つ折り鶴" },
	{ type: 'kamon', "id": "3536", "name": "蔦" },
	{ type: 'kamon', "id": "3537", "name": "丸に蔦" },
	{ type: 'kamon', "id": "3538", "name": "糸輪に蔦" },
	{ type: 'kamon', "id": "3539", "name": "雪輪に蔦" },
	{ type: 'kamon', "id": "3540", "name": "陰蔦" },
	{ type: 'kamon', "id": "3541", "name": "糸輪に陰蔦" },
	{ type: 'kamon', "id": "3542", "name": "石持ち地抜き蔦" },
	{ type: 'kamon', "id": "3543", "name": "細中陰蔦" },
	{ type: 'kamon', "id": "3544", "name": "中陰蔦" },
	{ type: 'kamon', "id": "3545", "name": "丸に中陰蔦" },
	{ type: 'kamon', "id": "3546", "name": "中陰光琳蔦" },
	{ type: 'kamon', "id": "3547", "name": "三つ割り蔦" },
	{ type: 'kamon', "id": "3548", "name": "丸に三つ蔦" },
	{ type: 'kamon', "id": "3549", "name": "蔦菱" },
	{ type: 'kamon', "id": "3550", "name": "鬼蔦" },
	{ type: 'kamon', "id": "3551", "name": "丸に鬼蔦" },
	{ type: 'kamon', "id": "3552", "name": "陰鬼蔦" },
	{ type: 'kamon', "id": "3553", "name": "丸に陰鬼蔦" },
	{ type: 'kamon', "id": "3554", "name": "中陰鬼蔦" },
	{ type: 'kamon', "id": "3555", "name": "丸に三つ鬼蔦" },
	{ type: 'kamon', "id": "3556", "name": "利休蔦" },
	{ type: 'kamon', "id": "3557", "name": "浮線蔦" },
	{ type: 'kamon', "id": "3558", "name": "蔦の花" },
	{ type: 'kamon', "id": "3559", "name": "中陰蔦に水" },
	{ type: 'kamon', "id": "3560", "name": "三河蔦" },
	{ type: 'kamon', "id": "3561", "name": "糸菱に蔦" },
	{ type: 'kamon', "id": "3562", "name": "朧蔦" },
	{ type: 'kamon', "id": "3563", "name": "鶉蔦" },
	{ type: 'kamon', "id": "3564", "name": "対い割り蔦" },
	{ type: 'kamon', "id": "3565", "name": "三つ盛り蔦" },
	{ type: 'kamon', "id": "3566", "name": "中陰蔦に隅立て四つ目" },
	{ type: 'kamon', "id": "3567", "name": "一つ槌" },
	{ type: 'kamon', "id": "3568", "name": "木目槌" },
	{ type: 'kamon', "id": "3569", "name": "三つ横槌" },
	{ type: 'kamon', "id": "3570", "name": "六つ槌車" },
	{ type: 'kamon', "id": "3571", "name": "八つ槌車" },
	{ type: 'kamon', "id": "3572", "name": "丸に水に槌車" },
	{ type: 'kamon', "id": "3573", "name": "打出の小槌" },
	{ type: 'kamon', "id": "3574", "name": "丸に三つ槌" },
	{ type: 'kamon', "id": "3575", "name": "丸に剣三つ槌" },
	{ type: 'kamon', "id": "3576", "name": "四つ横槌" },
	{ type: 'kamon', "id": "3577", "name": "中陰槌" },
	{ type: 'kamon', "id": "3578", "name": "三つ槌" },
	{ type: 'kamon', "id": "3579", "name": "五つ槌" },
	{ type: 'kamon', "id": "3580", "name": "房鼓" },
	{ type: 'kamon', "id": "3581", "name": "並び鼓銅" },
	{ type: 'kamon', "id": "3582", "name": "違い鼓胴" },
	{ type: 'kamon', "id": "3583", "name": "三つ並び鼓胴" },
	{ type: 'kamon', "id": "3584", "name": "真向き鼓" },
	{ type: 'kamon', "id": "3585", "name": "置き鼓" },
	{ type: 'kamon', "id": "3586", "name": "二枚鼓" },
	{ type: 'kamon', "id": "3587", "name": "三つ鼓銅" },
	{ type: 'kamon', "id": "3588", "name": "解き鼓" },
	{ type: 'kamon', "id": "3589", "name": "三つ違い鼓" },
	{ type: 'kamon', "id": "3590", "name": "細輪に馬" },
	{ type: 'kamon', "id": "3591", "name": "放れ馬" },
	{ type: 'kamon', "id": "3592", "name": "相馬繋ぎ馬" },
	{ type: 'kamon', "id": "3593", "name": "橘繋ぎ馬" },
	{ type: 'kamon', "id": "3594", "name": "方杭覇馬" },
	{ type: 'kamon', "id": "3595", "name": "不和神馬" },
	{ type: 'kamon', "id": "3596", "name": "梅の花" },
	{ type: 'kamon', "id": "3597", "name": "丸に梅の花" },
	{ type: 'kamon', "id": "3598", "name": "陰梅の花" },
	{ type: 'kamon', "id": "3599", "name": "捻じ梅" },
	{ type: 'kamon', "id": "3600", "name": "中陰捻じ梅" },
	{ type: 'kamon', "id": "3601", "name": "変わり捻じ梅" },
	{ type: 'kamon', "id": "3602", "name": "向こう梅" },
	{ type: 'kamon', "id": "3603", "name": "八重梅" },
	{ type: 'kamon', "id": "3604", "name": "八重向こう梅" },
	{ type: 'kamon', "id": "3605", "name": "裏梅" },
	{ type: 'kamon', "id": "3606", "name": "横見梅" },
	{ type: 'kamon', "id": "3607", "name": "三つ盛り梅" },
	{ type: 'kamon', "id": "3608", "name": "三つ割梅" },
	{ type: 'kamon', "id": "3609", "name": "三つ割向こう梅" },
	{ type: 'kamon', "id": "3610", "name": "梅輪" },
	{ type: 'kamon', "id": "3611", "name": "白銀梅" },
	{ type: 'kamon', "id": "3612", "name": "梅に水" },
	{ type: 'kamon', "id": "3613", "name": "利休梅" },
	{ type: 'kamon', "id": "3614", "name": "梅枝丸" },
	{ type: 'kamon', "id": "3615", "name": "梅桐" },
	{ type: 'kamon', "id": "3616", "name": "匂い梅" },
	{ type: 'kamon', "id": "3617", "name": "光琳梅" },
	{ type: 'kamon', "id": "3618", "name": "梅枝" },
	{ type: 'kamon', "id": "3619", "name": "結び梅" },
	{ type: 'kamon', "id": "3620", "name": "梅餅" },
	{ type: 'kamon', "id": "3621", "name": "重ね梅" },
	{ type: 'kamon', "id": "3622", "name": "八重裏梅" },
	{ type: 'kamon', "id": "3623", "name": "糸輪に捻じ裏梅" },
	{ type: 'kamon', "id": "3624", "name": "中陰裏梅" },
	{ type: 'kamon', "id": "3625", "name": "陰八重梅" },
	{ type: 'kamon', "id": "3626", "name": "陰八重裏梅" },
	{ type: 'kamon', "id": "3627", "name": "中陰捻じ裏梅" },
	{ type: 'kamon', "id": "3628", "name": "台地抜き梅" },
	{ type: 'kamon', "id": "3629", "name": "陰陽裏梅" },
	{ type: 'kamon', "id": "3630", "name": "向こう捻じ梅" },
	{ type: 'kamon', "id": "3631", "name": "八重唐梅" },
	{ type: 'kamon', "id": "3632", "name": "中陰向こう梅" },
	{ type: 'kamon', "id": "3633", "name": "中陰八重向こう梅" },
	{ type: 'kamon', "id": "3634", "name": "陰八重向こう梅" },
	{ type: 'kamon', "id": "3635", "name": "糸輪に陰匂い梅" },
	{ type: 'kamon', "id": "3636", "name": "三つ盛り匂い梅" },
	{ type: 'kamon', "id": "3637", "name": "尻合わせ三つ匂い梅" },
	{ type: 'kamon', "id": "3638", "name": "三つ裏梅" },
	{ type: 'kamon', "id": "3639", "name": "尻合わせ三つ梅" },
	{ type: 'kamon', "id": "3640", "name": "三つ横見梅" },
	{ type: 'kamon', "id": "3641", "name": "三つ寄せ横見梅" },
	{ type: 'kamon', "id": "3642", "name": "葉付き三つ横見梅" },
	{ type: 'kamon', "id": "3643", "name": "三つ葉向こう梅" },
	{ type: 'kamon', "id": "3644", "name": "箙梅" },
	{ type: 'kamon', "id": "3645", "name": "陰箙梅" },
	{ type: 'kamon', "id": "3646", "name": "軒端梅" },
	{ type: 'kamon', "id": "3647", "name": "雪輪に向こう梅" },
	{ type: 'kamon', "id": "3648", "name": "糸輪に覗き八重向こう梅" },
	{ type: 'kamon', "id": "3649", "name": "糸輪に覗き変わり梅" },
	{ type: 'kamon', "id": "3650", "name": "糸輪に変わり六つ梅" },
	{ type: 'kamon', "id": "3651", "name": "細菱に覗き梅" },
	{ type: 'kamon', "id": "3652", "name": "割り梅菱" },
	{ type: 'kamon', "id": "3653", "name": "雪輪に三つ割り梅" },
	{ type: 'kamon', "id": "3654", "name": "中陰三つ割り向こう梅" },
	{ type: 'kamon', "id": "3655", "name": "五曜梅" },
	{ type: 'kamon', "id": "3656", "name": "梅に五つ雁金" },
	{ type: 'kamon', "id": "3657", "name": "光琳向こう梅" },
	{ type: 'kamon', "id": "3658", "name": "陰光琳向こう梅" },
	{ type: 'kamon', "id": "3659", "name": "中陰光琳梅" },
	{ type: 'kamon', "id": "3660", "name": "爪形梅" },
	{ type: 'kamon', "id": "3661", "name": "月輪に総覗き爪形梅" },
	{ type: 'kamon', "id": "3662", "name": "雪菱に地抜き光琳梅" },
	{ type: 'kamon', "id": "3663", "name": "陰利休梅" },
	{ type: 'kamon', "id": "3664", "name": "陰八重利休梅" },
	{ type: 'kamon', "id": "3665", "name": "糸輪に豆梅" },
	{ type: 'kamon', "id": "3666", "name": "一筆梅" },
	{ type: 'kamon', "id": "3667", "name": "木村梅" },
	{ type: 'kamon', "id": "3668", "name": "香包みに梅" },
	{ type: 'kamon', "id": "3669", "name": "丸に古木梅" },
	{ type: 'kamon', "id": "3670", "name": "変わり梅枝丸" },
	{ type: 'kamon', "id": "3671", "name": "枝丸の梅" },
	{ type: 'kamon', "id": "3672", "name": "三つ追い枝梅の丸" },
	{ type: 'kamon', "id": "3673", "name": "中陰浮線梅" },
	{ type: 'kamon', "id": "3674", "name": "中陰裏梅胡蝶" },
	{ type: 'kamon', "id": "3675", "name": "匂い梅飛び蝶" },
	{ type: 'kamon', "id": "3676", "name": "中陰蝶梅" },
	{ type: 'kamon', "id": "3677", "name": "中陰梅胡蝶" },
	{ type: 'kamon', "id": "3678", "name": "梅鶴" },
	{ type: 'kamon', "id": "3679", "name": "足無し梅鶴" },
	{ type: 'kamon', "id": "3680", "name": "中陰梅鶴" },
	{ type: 'kamon', "id": "3681", "name": "陰梅鶴" },
	{ type: 'kamon', "id": "3682", "name": "五つ横見裏梅" },
	{ type: 'kamon', "id": "3683", "name": "朧梅輪" },
	{ type: 'kamon', "id": "3684", "name": "梅鉢" },
	{ type: 'kamon', "id": "3685", "name": "丸に梅鉢" },
	{ type: 'kamon', "id": "3686", "name": "陰梅鉢" },
	{ type: 'kamon', "id": "3687", "name": "加賀梅鉢" },
	{ type: 'kamon', "id": "3688", "name": "大聖寺梅鉢" },
	{ type: 'kamon', "id": "3689", "name": "星梅鉢" },
	{ type: 'kamon', "id": "3690", "name": "丸に星梅鉢" },
	{ type: 'kamon', "id": "3691", "name": "丸に剣梅鉢" },
	{ type: 'kamon', "id": "3692", "name": "光琳梅鉢" },
	{ type: 'kamon', "id": "3693", "name": "大和天理教廰" },
	{ type: 'kamon', "id": "3694", "name": "台梅鉢" },
	{ type: 'kamon', "id": "3695", "name": "糸輪に覗き梅鉢" },
	{ type: 'kamon', "id": "3696", "name": "石持ち地抜き星梅鉢" },
	{ type: 'kamon', "id": "3697", "name": "中陰星梅鉢" },
	{ type: 'kamon', "id": "3698", "name": "陰星梅鉢" },
	{ type: 'kamon', "id": "3699", "name": "石持ち地抜き梅鉢" },
	{ type: 'kamon', "id": "3700", "name": "富山梅鉢" },
	{ type: 'kamon', "id": "3701", "name": "豊後梅鉢" },
	{ type: 'kamon', "id": "3702", "name": "実梅鉢" },
	{ type: 'kamon', "id": "3703", "name": "瓜実梅鉢" },
	{ type: 'kamon', "id": "3704", "name": "丁字梅鉢" },
	{ type: 'kamon', "id": "3705", "name": "裏梅鉢" },
	{ type: 'kamon', "id": "3706", "name": "隅切り地抜き梅鉢" },
	{ type: 'kamon', "id": "3707", "name": "朧梅鉢" },
	{ type: 'kamon', "id": "3708", "name": "雪輪に陰梅鉢" },
	{ type: 'kamon', "id": "3709", "name": "細輪に中陰梅鉢" },
	{ type: 'kamon', "id": "3710", "name": "剣梅鉢" },
	{ type: 'kamon', "id": "3711", "name": "割り梅鉢" },
	{ type: 'kamon', "id": "3712", "name": "三つ割り梅鉢" },
	{ type: 'kamon', "id": "3713", "name": "丸に三つ割り梅鉢" },
	{ type: 'kamon', "id": "3714", "name": "鞠挟み星梅鉢" },
	{ type: 'kamon', "id": "3715", "name": "八重梅鉢" },
	{ type: 'kamon', "id": "3716", "name": "丸に三つ梅輪" },
	{ type: 'kamon', "id": "3717", "name": "花梅鉢" },
	{ type: 'kamon', "id": "3718", "name": "四方瓜に花角" },
	{ type: 'kamon', "id": "3719", "name": "五瓜に唐花" },
	{ type: 'kamon', "id": "3720", "name": "丸に五瓜に唐花" },
	{ type: 'kamon', "id": "3721", "name": "陰五瓜に唐花" },
	{ type: 'kamon', "id": "3722", "name": "五瓜に梅鉢" },
	{ type: 'kamon', "id": "3723", "name": "五瓜に三つ梅鉢" },
	{ type: 'kamon', "id": "3724", "name": "織田瓜" },
	{ type: 'kamon', "id": "3725", "name": "五瓜に立ち沢瀉" },
	{ type: 'kamon', "id": "3726", "name": "五瓜に三つ柏" },
	{ type: 'kamon', "id": "3727", "name": "五瓜に桐" },
	{ type: 'kamon', "id": "3728", "name": "五瓜に剣片喰" },
	{ type: 'kamon', "id": "3729", "name": "五瓜に九枚笹" },
	{ type: 'kamon', "id": "3730", "name": "五瓜に違い鷹の羽" },
	{ type: 'kamon', "id": "3731", "name": "五瓜に左三つ巴" },
	{ type: 'kamon', "id": "3732", "name": "五瓜に丸に二つ引き" },
	{ type: 'kamon', "id": "3733", "name": "五瓜に抱き茗荷" },
	{ type: 'kamon', "id": "3734", "name": "割り五瓜" },
	{ type: 'kamon', "id": "3735", "name": "三つ盛り五瓜" },
	{ type: 'kamon', "id": "3736", "name": "瓜桐" },
	{ type: 'kamon', "id": "3737", "name": "瓜の花" },
	{ type: 'kamon', "id": "3738", "name": "糸輪に五つ瓜" },
	{ type: 'kamon', "id": "3739", "name": "丸に三つ蔓瓜" },
	{ type: 'kamon', "id": "3740", "name": "抱き瓜" },
	{ type: 'kamon', "id": "3741", "name": "瓜の枝丸" },
	{ type: 'kamon', "id": "3742", "name": "織田細瓜" },
	{ type: 'kamon', "id": "3743", "name": "五瓜に木瓜" },
	{ type: 'kamon', "id": "3744", "name": "五瓜に蔦" },
	{ type: 'kamon', "id": "3745", "name": "五瓜に揚羽蝶" },
	{ type: 'kamon', "id": "3746", "name": "五瓜崩し" },
	{ type: 'kamon', "id": "3747", "name": "五瓜に亀甲花菱" },
	{ type: 'kamon', "id": "3748", "name": "丸に一つ鱗" },
	{ type: 'kamon', "id": "3749", "name": "三つ鱗" },
	{ type: 'kamon', "id": "3750", "name": "丸に三つ鱗" },
	{ type: 'kamon', "id": "3751", "name": "糸輪に豆三つ鱗" },
	{ type: 'kamon', "id": "3752", "name": "石持ち地抜き三つ鱗" },
	{ type: 'kamon', "id": "3753", "name": "北条鱗" },
	{ type: 'kamon', "id": "3754", "name": "六つ鱗" },
	{ type: 'kamon', "id": "3755", "name": "七つつなぎ鱗" },
	{ type: 'kamon', "id": "3756", "name": "赤垣鱗" },
	{ type: 'kamon', "id": "3757", "name": "頭合わせ二つ鱗" },
	{ type: 'kamon', "id": "3758", "name": "三つ組み合わせ鱗" },
	{ type: 'kamon', "id": "3759", "name": "菱に頭合わせ三つ鱗" },
	{ type: 'kamon', "id": "3760", "name": "剣三つ鱗" },
	{ type: 'kamon', "id": "3761", "name": "一つ鱗" },
	{ type: 'kamon', "id": "3762", "name": "丸に対い鱗" },
	{ type: 'kamon', "id": "3763", "name": "総陰丸に対い鱗" },
	{ type: 'kamon', "id": "3764", "name": "陰輪に対い鱗" },
	{ type: 'kamon', "id": "3765", "name": "糸輪に陰陽重ね鱗" },
	{ type: 'kamon', "id": "3766", "name": "陰北条鱗" },
	{ type: 'kamon', "id": "3767", "name": "六角に三つ鱗" },
	{ type: 'kamon', "id": "3768", "name": "糸輪に陰三つ鱗" },
	{ type: 'kamon', "id": "3769", "name": "丸に一つ引に三つ鱗" },
	{ type: 'kamon', "id": "3770", "name": "雪輪に三つ鱗" },
	{ type: 'kamon', "id": "3771", "name": "五つ鱗車" },
	{ type: 'kamon', "id": "3772", "name": "三つ盛り三つ鱗" },
	{ type: 'kamon', "id": "3773", "name": "細輪に六つ鱗" },
	{ type: 'kamon', "id": "3774", "name": "真向き兎" },
	{ type: 'kamon', "id": "3775", "name": "後ろ向き番い兎" },
	{ type: 'kamon', "id": "3776", "name": "後ろ向き三つ並び兎" },
	{ type: 'kamon', "id": "3777", "name": "後ろ向き三つ兎" },
	{ type: 'kamon', "id": "3778", "name": "波に月に兎" },
	{ type: 'kamon', "id": "3779", "name": "対い兎" },
	{ type: 'kamon', "id": "3780", "name": "三つ兎" },
	{ type: 'kamon', "id": "3781", "name": "糸雪輪に陰兎" },
	{ type: 'kamon', "id": "3782", "name": "花兎" },
	{ type: 'kamon', "id": "3783", "name": "唐団扇" },
	{ type: 'kamon', "id": "3784", "name": "丸に唐団扇" },
	{ type: 'kamon', "id": "3785", "name": "唐丸唐団扇" },
	{ type: 'kamon', "id": "3786", "name": "団扇桐" },
	{ type: 'kamon', "id": "3787", "name": "変わり羽団扇" },
	{ type: 'kamon', "id": "3788", "name": "米津羽団扇" },
	{ type: 'kamon', "id": "3789", "name": "三つ割り羽団扇" },
	{ type: 'kamon', "id": "3790", "name": "静岡浅間神社" },
	{ type: 'kamon', "id": "3791", "name": "丸に団扇" },
	{ type: 'kamon', "id": "3792", "name": "中輪に葵団扇" },
	{ type: 'kamon', "id": "3793", "name": "違い団扇" },
	{ type: 'kamon', "id": "3794", "name": "三つ団扇" },
	{ type: 'kamon', "id": "3795", "name": "団扇梅" },
	{ type: 'kamon', "id": "3796", "name": "内藤団扇" },
	{ type: 'kamon', "id": "3797", "name": "丸に三つ団扇" },
	{ type: 'kamon', "id": "3798", "name": "小幡団扇" },
	{ type: 'kamon', "id": "3799", "name": "三つ唐団扇" },
	{ type: 'kamon', "id": "3800", "name": "抱き割り唐団扇" },
	{ type: 'kamon', "id": "3801", "name": "雪輪に変わり団扇" },
	{ type: 'kamon', "id": "3802", "name": "羽団扇" },
	{ type: 'kamon', "id": "3803", "name": "入れ違い割り羽団扇" },
	{ type: 'kamon', "id": "3804", "name": "房付き団扇" },
	{ type: 'kamon', "id": "3805", "name": "葉団扇蝶" },
	{ type: 'kamon', "id": "3806", "name": "鷹の羽団扇" },
	{ type: 'kamon', "id": "3807", "name": "鷹の羽団扇（２）" },
	{ type: 'kamon', "id": "3808", "name": "丸に団扇に違い鷹の羽" },
	{ type: 'kamon', "id": "3809", "name": "毛輪" },
	{ type: 'kamon', "id": "3810", "name": "糸輪" },
	{ type: 'kamon', "id": "3811", "name": "細輪" },
	{ type: 'kamon', "id": "3812", "name": "中輪" },
	{ type: 'kamon', "id": "3813", "name": "丸輪" },
	{ type: 'kamon', "id": "3814", "name": "中太輪" },
	{ type: 'kamon', "id": "3815", "name": "太輪" },
	{ type: 'kamon', "id": "3816", "name": "蛇の目輪" },
	{ type: 'kamon', "id": "3817", "name": "陰丸輪" },
	{ type: 'kamon', "id": "3818", "name": "三重輪" },
	{ type: 'kamon', "id": "3819", "name": "子持ち輪" },
	{ type: 'kamon', "id": "3820", "name": "おぼろ梅輪" },
	{ type: 'kamon', "id": "3821", "name": "おぼろ輪" },
	{ type: 'kamon', "id": "3822", "name": "雪輪" },
	{ type: 'kamon', "id": "3823", "name": "外雪輪" },
	{ type: 'kamon', "id": "3824", "name": "竹輪" },
	{ type: 'kamon', "id": "3825", "name": "菊輪" },
	{ type: 'kamon', "id": "3826", "name": "三つ車輪" },
	{ type: 'kamon', "id": "3827", "name": "六つ車輪" },
	{ type: 'kamon', "id": "3828", "name": "源氏輪" },
	{ type: 'kamon', "id": "3829", "name": "藤輪" },
	{ type: 'kamon', "id": "3830", "name": "片藤輪" },
	{ type: 'kamon', "id": "3831", "name": "月輪" },
	{ type: 'kamon', "id": "3832", "name": "輪違い" },
	{ type: 'kamon', "id": "3833", "name": "丸に輪違い" },
	{ type: 'kamon', "id": "3834", "name": "三つ輪違い" },
	{ type: 'kamon', "id": "3835", "name": "丸に三つ輪違い" },
	{ type: 'kamon', "id": "3836", "name": "角輪違い" },
	{ type: 'kamon', "id": "3837", "name": "唐草輪" },
	{ type: 'kamon', "id": "3838", "name": "柊輪" },
	{ type: 'kamon', "id": "3839", "name": "薄輪" },
	{ type: 'kamon', "id": "3840", "name": "違い雪輪" },
	{ type: 'kamon', "id": "3841", "name": "雁木輪" },
	{ type: 'kamon', "id": "3842", "name": "結び輪違い" },
	{ type: 'kamon', "id": "3843", "name": "丸に輪違い崩し" },
	{ type: 'kamon', "id": "3844", "name": "繋ぎ輪違い" },
	{ type: 'kamon', "id": "3845", "name": "輪違い崩し" },
	{ type: 'kamon', "id": "3846", "name": "花輪違い" },
	{ type: 'kamon', "id": "3847", "name": "地抜き朧梅輪" },
	{ type: 'kamon', "id": "3848", "name": "鐶輪" },
	{ type: 'kamon', "id": "3849", "name": "八重菊輪" },
	{ type: 'kamon', "id": "3850", "name": "瓜輪" },
	{ type: 'kamon', "id": "3851", "name": "石持ち" },
	{ type: 'kamon', "id": "3852", "name": "厚輪" },
	{ type: 'kamon', "id": "3853", "name": "二重輪" },
	{ type: 'kamon', "id": "3854", "name": "州浜輪" },
	{ type: 'kamon', "id": "3855", "name": "八つ菊輪" },
	{ type: 'kamon', "id": "3856", "name": "変わり唐草輪" },
	{ type: 'kamon', "id": "3857", "name": "石持ち地抜き輪違い" },
	{ type: 'kamon', "id": "3858", "name": "丸に輪違いに一文字" },
	{ type: 'kamon', "id": "3859", "name": "寄せ懸け輪違い" },
	{ type: 'kamon', "id": "3860", "name": "隅切り餅に地抜き三つ輪違い" },
	{ type: 'kamon', "id": "3861", "name": "三つ剣輪違い" },
	{ type: 'kamon', "id": "3862", "name": "鐶三つ編み金輪" },
	{ type: 'kamon', "id": "3863", "name": "三つ輪違いの丸" },
	{ type: 'kamon', "id": "3864", "name": "三つ輪違い崩し" },
	{ type: 'kamon', "id": "3865", "name": "変わり輪違い" },
	{ type: 'kamon', "id": "3866", "name": "輪角繋ぎ" },
	{ type: 'kamon', "id": "3867", "name": "四つ編み輪違い" },
	{ type: 'kamon', "id": "3868", "name": "石持ち地抜き三本蕨" },
	{ type: 'kamon', "id": "3869", "name": "束ね蕨の丸" },
	{ type: 'kamon', "id": "3870", "name": "抱き束ね蕨" },
	{ type: 'kamon', "id": "3871", "name": "蕨巴" },
	{ type: 'kamon', "id": "3872", "name": "蔓蕨の丸" },
	{ type: 'kamon', "id": "3873", "name": "蕨車" },
	{ type: 'kamon', "id": "3874", "name": "花蕨" },
	{ type: 'kamon', "id": "3875", "name": "三つ割り蕨" },
	{ type: 'kamon', "id": "3876", "name": "五つ蕨" },
	{ type: 'kamon', "id": "3877", "name": "蕨桐" },
	{ type: 'kamon', "id": "3878", "name": "対い鷲" },
	{ type: 'kamon', "id": "3879", "name": "結び綿" },
	{ type: 'kamon', "id": "3880", "name": "ひも付き結び綿" },
	{ type: 'kamon', "id": "3881", "name": "丸に対かい結び綿" },
	{ type: 'kamon', "id": "3882", "name": "変わり結綿" },
	{ type: 'kamon', "id": "3883", "name": "二つ結綿" },
	{ type: 'kamon', "id": "3884", "name": "結綿菱" },
	{ type: 'kamon', "id": "3885", "name": "三つ結綿" },
	{ type: 'kamon', "id": "3886", "name": "三つ割り結綿" },
	{ type: 'kamon', "id": "3887", "name": "並び矢" },
	{ type: 'kamon', "id": "3888", "name": "丸に並び矢" },
	{ type: 'kamon', "id": "3889", "name": "違い矢" },
	{ type: 'kamon', "id": "3890", "name": "丸に違い矢" },
	{ type: 'kamon', "id": "3891", "name": "陰違い矢" },
	{ type: 'kamon', "id": "3892", "name": "矢尻付き違い矢" },
	{ type: 'kamon', "id": "3893", "name": "丸に矢尻付き違い矢" },
	{ type: 'kamon', "id": "3894", "name": "丸に三つ矢" },
	{ type: 'kamon', "id": "3895", "name": "六つ矢車" },
	{ type: 'kamon', "id": "3896", "name": "丸に八つ矢車" },
	{ type: 'kamon', "id": "3897", "name": "八つ矢車" },
	{ type: 'kamon', "id": "3898", "name": "中輪に並び矢筈" },
	{ type: 'kamon', "id": "3899", "name": "丸に違い矢筈" },
	{ type: 'kamon', "id": "3900", "name": "一つ矢" },
	{ type: 'kamon', "id": "3901", "name": "折れ矢" },
	{ type: 'kamon', "id": "3902", "name": "片桐違い矢" },
	{ type: 'kamon', "id": "3903", "name": "丸に先割れ矢" },
	{ type: 'kamon', "id": "3904", "name": "三つ矢の丸に花菱" },
	{ type: 'kamon', "id": "3905", "name": "矢筈" },
	{ type: 'kamon', "id": "3906", "name": "折れ矢筈" },
	{ type: 'kamon', "id": "3907", "name": "三つ重ね矢筈" },
	{ type: 'kamon', "id": "3908", "name": "三つ剣矢筈" },
	{ type: 'kamon', "id": "3909", "name": "三つ追い矢筈" },
	{ type: 'kamon', "id": "3910", "name": "違い山形" },
	{ type: 'kamon', "id": "3911", "name": "山に霞" },
	{ type: 'kamon', "id": "3912", "name": "富士山に霞" },
	{ type: 'kamon', "id": "3913", "name": "山に富士山に帆掛船" },
	{ type: 'kamon', "id": "3914", "name": "三つ寄せ山" },
	{ type: 'kamon', "id": "3915", "name": "花山形桐" },
	{ type: 'kamon', "id": "3916", "name": "山形" },
	{ type: 'kamon', "id": "3917", "name": "花山形" },
	{ type: 'kamon', "id": "3918", "name": "三つ盛り山形" },
	{ type: 'kamon', "id": "3919", "name": "違い花山形" },
	{ type: 'kamon', "id": "3920", "name": "山形桜" },
	{ type: 'kamon', "id": "3921", "name": "丸に遠山霞" },
	{ type: 'kamon', "id": "3922", "name": "変わり富士山に霞" },
	{ type: 'kamon', "id": "3923", "name": "三つ遠山" },
	{ type: 'kamon', "id": "3924", "name": "三つ寄せ富士" },
	{ type: 'kamon', "id": "3925", "name": "五つ寄せ山" },
	{ type: 'kamon', "id": "3926", "name": "五つ寄せ富士" },
	{ type: 'kamon', "id": "3927", "name": "入り山形" },
	{ type: 'kamon', "id": "3928", "name": "丸に違い山形" },
	{ type: 'kamon', "id": "3929", "name": "出山形" },
	{ type: 'kamon', "id": "3930", "name": "横違い山形" },
	{ type: 'kamon', "id": "3931", "name": "上下組み合わせ山形" },
	{ type: 'kamon', "id": "3932", "name": "陰山形に二木" },
	{ type: 'kamon', "id": "3933", "name": "組み山形菱" },
	{ type: 'kamon', "id": "3934", "name": "三つ違い山形" },
	{ type: 'kamon', "id": "3935", "name": "三つ組み合わせ山形" },
	{ type: 'kamon', "id": "3936", "name": "頭合わせ三つ山形" },
	{ type: 'kamon', "id": "3937", "name": "三つ組み違い山形" },
	{ type: 'kamon', "id": "3938", "name": "対い花山形" },
	{ type: 'kamon', "id": "3939", "name": "四つ組み合わせ山形" },
	{ type: 'kamon', "id": "3940", "name": "四つ山形菱" },
	{ type: 'kamon', "id": "3941", "name": "五角山形" },
	{ type: 'kamon', "id": "3942", "name": "六角山形" },
	{ type: 'kamon', "id": "3943", "name": "隅立て十二山形" },
	{ type: 'kamon', "id": "3944", "name": "組み合わせ変わり山形" },
	{ type: 'kamon', "id": "3945", "name": "細輪に子持ち山道" },
	{ type: 'kamon', "id": "3946", "name": "向こう山吹" },
	{ type: 'kamon', "id": "3947", "name": "裏八重山吹" },
	{ type: 'kamon', "id": "3948", "name": "三つ寄せ横見山吹" },
	{ type: 'kamon', "id": "3949", "name": "抱き杏葉山吹" },
	{ type: 'kamon', "id": "3950", "name": "大丸山吹" },
	{ type: 'kamon', "id": "3951", "name": "下村山吹" },
	{ type: 'kamon', "id": "3952", "name": "山吹に水" },
	{ type: 'kamon', "id": "3953", "name": "夕顔の花" },
	{ type: 'kamon', "id": "3954", "name": "丸に夕顔の花" },
	{ type: 'kamon', "id": "3955", "name": "夕顔に月" },
	{ type: 'kamon', "id": "3956", "name": "夕顔に三日月" },
	{ type: 'kamon', "id": "3957", "name": "丸に割り夕顔の葉に三日月" },
	{ type: 'kamon', "id": "3958", "name": "蔓夕顔に源氏車" },
	{ type: 'kamon', "id": "3959", "name": "結柴" },
	{ type: 'kamon', "id": "3960", "name": "雪" },
	{ type: 'kamon', "id": "3961", "name": "初雪" },
	{ type: 'kamon', "id": "3962", "name": "吹雪" },
	{ type: 'kamon', "id": "3963", "name": "山谷雪" },
	{ type: 'kamon', "id": "3964", "name": "花形雪" },
	{ type: 'kamon', "id": "3965", "name": "春の雪" },
	{ type: 'kamon', "id": "3966", "name": "氷柱雪" },
	{ type: 'kamon', "id": "3967", "name": "山吹雪" },
	{ type: 'kamon', "id": "3968", "name": "春風雪" },
	{ type: 'kamon', "id": "3969", "name": "石持ち雪" },
	{ type: 'kamon', "id": "3970", "name": "曇り雪" },
	{ type: 'kamon', "id": "3971", "name": "矢雪" },
	{ type: 'kamon', "id": "3972", "name": "山雪" },
	{ type: 'kamon', "id": "3973", "name": "巌敷雪" },
	{ type: 'kamon', "id": "3974", "name": "雪菱" },
	{ type: 'kamon', "id": "3975", "name": "雪輪菱" },
	{ type: 'kamon', "id": "3976", "name": "雪輪菱に橘" },
	{ type: 'kamon', "id": "3977", "name": "雪輪菱に蔦" },
	{ type: 'kamon', "id": "3978", "name": "雪輪菱に桔梗" },
	{ type: 'kamon', "id": "3979", "name": "雪輪" },
	{ type: 'kamon', "id": "3980", "name": "違い雪輪" },
	{ type: 'kamon', "id": "3981", "name": "雪輪に左三巴" },
	{ type: 'kamon', "id": "3982", "name": "雪輪に剣花菱" },
	{ type: 'kamon', "id": "3983", "name": "雪輪に覗き糸輪に三つ鱗" },
	{ type: 'kamon', "id": "3984", "name": "雪輪に覗き陰八重梅" },
	{ type: 'kamon', "id": "3985", "name": "雪輪に覗き瓜に唐花" },
	{ type: 'kamon', "id": "3986", "name": "雪輪に中陰鬼蔦" },
	{ type: 'kamon', "id": "3987", "name": "雪輪に中陰五三桐" },
	{ type: 'kamon', "id": "3988", "name": "雪輪に右三巴" },
	{ type: 'kamon', "id": "3989", "name": "雪輪に四つ目菱" },
	{ type: 'kamon', "id": "3990", "name": "雪輪に木瓜" },
	{ type: 'kamon', "id": "3991", "name": "外雪輪に抱き茗荷" },
	{ type: 'kamon', "id": "3992", "name": "外雪輪に抱き沢瀉" },
	{ type: 'kamon', "id": "3993", "name": "外雪輪に変わり根笹" },
	{ type: 'kamon', "id": "3994", "name": "弓矢" },
	{ type: 'kamon', "id": "3995", "name": "矢つがえの弓" },
	{ type: 'kamon', "id": "3996", "name": "椎名矢つがえの弓" },
	{ type: 'kamon', "id": "3997", "name": "違い弓" },
	{ type: 'kamon', "id": "3998", "name": "対い張り弓" },
	{ type: 'kamon', "id": "3999", "name": "立て二つ弓" },
	{ type: 'kamon', "id": "4000", "name": "丸に二つ弓" },
	{ type: 'kamon', "id": "4001", "name": "四つ弓菱" },
	{ type: 'kamon', "id": "4002", "name": "五つ弓" },
	{ type: 'kamon', "id": "4003", "name": "地抜き永楽銭" },
	{ type: 'kamon', "id": "4004", "name": "寛永銭" },
	{ type: 'kamon', "id": "4005", "name": "天保銭" },
	{ type: 'kamon', "id": "4006", "name": "裏一文字銭" },
	{ type: 'kamon', "id": "4007", "name": "浪銭" },
	{ type: 'kamon', "id": "4008", "name": "六つ並び永楽銭" },
	{ type: 'kamon', "id": "4009", "name": "真田六文銭" },
	{ type: 'kamon', "id": "4010", "name": "長谷部銭" },
	{ type: 'kamon', "id": "4011", "name": "六つ捻じ銭" },
	{ type: 'kamon', "id": "4012", "name": "三文銭" },
	{ type: 'kamon', "id": "4013", "name": "四つ重ね銭" },
	{ type: 'kamon', "id": "4014", "name": "阿部銭" },
	{ type: 'kamon', "id": "4015", "name": "七つ裏銭" },
	{ type: 'kamon', "id": "4016", "name": "八連銭" },
	{ type: 'kamon', "id": "4017", "name": "裏銭" },
	{ type: 'kamon', "id": "4018", "name": "裏表文久銭" },
	{ type: 'kamon', "id": "4019", "name": "三つ盛り文銭" },
	{ type: 'kamon', "id": "4020", "name": "丸に頭巾" },
	{ type: 'kamon', "id": "4021", "name": "三つ頭巾" },
	{ type: 'kamon', "id": "4022", "name": "丸に三つ角頭巾" },
	{ type: 'kamon', "id": "4023", "name": "六つ頭巾" },
	{ type: 'kamon', "id": "9999", "name": "特殊家紋" },
] as const;

export const KAMON_ID_ALIAS: Readonly<Record<string, string>> = {
	'M1': '桜道',
	'M2': '小桜',
	'M3': 'かぶと',
	'M4': 'こいのぼり',
	'M5': 'ふじさん',
} as const;

export function findKamon(id: string): KamonInfo | undefined {
	const canonicalId = KAMON_ID_ALIAS[id] ?? id;
	return KAMON_INFO.find((kamon) => kamon.id === canonicalId);
}

import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from "../../../context";
import GuideMain from './GuideMain';
import GuideNavigation from './GuideNavigation';
import GuideSub from './GuideSub';

interface GuideProps {}
type GuideParams = {
	'*'?: string;
}

export const GUIDE_TITLES = new Map([
	["use_guide/top", "利用ガイド"],
	["registration_setting/use_flow", "登録・設定方法/はじめての方へ"],
	["registration_setting/preparation", "登録・設定方法/事前準備"],
	["registration_setting/rakuten_system_setting", "登録・設定方法/楽天システム設定"],
	["registration_setting/shop_setting", "登録・設定方法/店舗設定"],
	["registration_setting/mail_setting", "登録・設定方法/メール設定"],
	["registration_setting/from_review_privilege_up_to_campaign_register", "登録・設定方法/レビュー特典～キャンペーン登録"],
	["registration_setting/user_account_setting", "登録・設定方法/ユーザーアカウント設定"],
	["registration_setting/password_setting", "登録・設定方法/パスワード設定"],
	["registration_setting/system_alert", "登録・設定方法/システムアラート設定"],
	["registration_setting/two_factor_auth", "2段階認証設定"],
	["operation/top", "操作ガイド/TOP"],
	["operation/review_privilege", "操作ガイド/レビュー特典"],
	["operation/mail_setting", "操作ガイド/メール設定"],
	["operation/analysis", "操作ガイド/レビュー分析"],
	["operation/various_setup", "操作ガイド/各種設定"],
	["operation/csv_format", "操作ガイド/送り先CSV形式"],
]);

const noMarkdownTitles = [
	"use_guide/top",
	"registration_setting/use_flow"
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Guide(props: GuideProps): React.ReactElement {
	const params = useParams<GuideParams>();
	const MARK_DOWN_PATH = "/assets/md/guide/";

	const paramPath = params['*'] || '';
	const lastPath = GUIDE_TITLES.has(paramPath) ? paramPath : "use_guide/top";
	const loadTargetPath = MARK_DOWN_PATH + (lastPath) + '.md';
	const [state] = useAppState();
	const basePath = state.params.basePath
	const folderPath = basePath + "/guide";

	const [isFullSize, setIsFullSize] = React.useState<boolean>(true);

	const isTop = lastPath === "use_guide/top";
	const isMarkdown = noMarkdownTitles.includes(lastPath) === false;

	const markDownTitle = GUIDE_TITLES.get(lastPath) || "利用ガイド情報が読み込めません";


	React.useEffect(() => {
		const handleResize = () => {
			setIsFullSize(window.innerWidth > 1200);
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<>
			<div className="ly_main__guide">
				<div className="bl_row">
					{(isFullSize || !isTop) && <div className="bl_col bl_col__3">
						<GuideNavigation folderPath={folderPath} path={loadTargetPath} paramPath={lastPath} isMarkdown={isMarkdown} />
					</div>}

					<div className="bl_col bl_col__9">
						{isMarkdown &&
							<GuideMain path={loadTargetPath} title={markDownTitle} folderPath={folderPath} basePath={state.params.basePath} />
						}
						{!isMarkdown &&
							<GuideSub path={lastPath} title={markDownTitle} basePath={basePath} folderPath={folderPath} />
						}
					</div>

				</div>
			</div>
		</>
	);
}
export default Guide;
import { PostRateOptions, PostRateSummary } from '@sasagase/types';
// import Chart from 'chart.js';
import { ChartOptions } from 'chart.js';
import ja from 'date-fns/locale/ja';
import dayjs from 'dayjs';
import * as React from 'react';
import Chart from "react-chartjs-2";
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from 'react-hook-form';
import { getPostRate, getPostRateCsv } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import FormErrorMessage from '../FormErrorMessage';
import { saveCSVFile } from '../saveCSVFile';
registerLocale('ja', ja);

interface FormValues {
	reviewType: 'item' | 'shop';
	periodType: 'period' | 'month' | 'day';
	selectPeriod: string;
	monthFrom: string;
	monthTo: string;
	dayFrom: string;
	dayTo: string;
}

interface PostRateProps { }
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function PostRate(props: PostRateProps): React.ReactElement {
	const { register, handleSubmit, getValues, errors, control, watch } = useForm<FormValues>({
		defaultValues: {
			reviewType: 'item',
			periodType: 'period',
			selectPeriod: '12',
		},
	});
	const [state] = useAppState();
	const callAPI = useAPI();
	const [postRates, setPostRates] = React.useState<null | PostRateSummary[]>(null);
	const [loading, setLoading] = React.useState(false);

	const periodType = watch('periodType');

	React.useEffect(() => {
		void handleClickTerm();
	}, []);

	const generateOptions = (values: FormValues, periodType: string): PostRateOptions => {
		const opt: PostRateOptions = {
			reviewType: values.reviewType,
			periodType: periodType,
		};
		if (periodType === 'period') {
			opt.selectPeriod = values.selectPeriod;
		}
		if (periodType === 'month') {
			opt.from = values.monthFrom ? dayjs(values.monthFrom).format('YYYY-MM-DD') : undefined;
			opt.to = values.monthTo ? dayjs(values.monthTo).format('YYYY-MM-DD') : undefined;
		} else if (periodType === 'day') {
			opt.from = values.dayFrom ? dayjs(values.dayFrom).format('YYYY-MM-DD') : undefined;
			opt.to = values.dayTo ? dayjs(values.dayTo).format('YYYY-MM-DD') : undefined;
		}
		return opt;
	};

	const handleClickTerm = handleSubmit(async (values: FormValues) => {
		if (!periodType || loading) {
			return;
		}

		const currentScrollY = window.scrollY;
		setLoading(true);
		const opt = generateOptions(values, periodType);
		callAPI(
			getPostRate(state.params.shopId, opt),
			(err, result) => {
				setLoading(false);
				if (err) {
					console.error('Error fetching review summary:', err);
					alert("データの取得に失敗しました");
					return;
				}
				setPostRates(result.data);

				requestAnimationFrame(() => {
					window.scrollTo(0, currentScrollY);
				});
			}
		);
	});

	const handleTransact = handleSubmit(async (values: FormValues) => {
		if (!periodType || loading) {
			return;
		}

		setLoading(true);
		const opt = generateOptions(values, periodType);

		let result;
		try {
			setLoading(false);
			result = await callAPI(getPostRateCsv(state.params.shopId, opt));
		} catch (err) {
			setLoading(false);
			alert(`CSVのダウンロードに失敗しました`);
			return;
		}
		await saveCSVFile(result.data);
	});


	// レビュー投稿率を計算する関数
	const calculateReviewRate = (reviewNum: number, orderNum: number) => {
		const rateValue = (reviewNum / orderNum) * 100;
		return isNaN(rateValue) ? 0 : Math.round(rateValue * 100) / 100;
	};

	// `chart` コンポーネント
	const chart = () => {
		if (!postRates) {
			return <></>;
		}

		// 仮グラフ
		const options: ChartOptions = {
			responsive: true,
			tooltips: {
				mode: 'index',
			},
			plugins: {
				legend: {
					position: "top",
				},
			},
			scales: {
				xAxes: [{
					stacked: true,
				}],
				yAxes: [{
					stacked: true,
					scaleLabel: {
						display: true,
						labelString: '注文件数(件)',
					},
				}, {
					id: "y2",
					type: "linear",
					position: "right",
					scaleLabel: {
						display: true,
						labelString: 'レビュー投稿率(%)',
					},
				}],
			},
		};

		const period = postRates.map(rate => rate.period);
		const reviewRate = postRates.map(rate => calculateReviewRate(rate.reviewNum, rate.orderNum));
		const notCoveredReviewRate = postRates.map(rate => calculateReviewRate(rate.notCoveredReviewNum, rate.notCoveredOrderNum));
		const allReviewRate = postRates.map(rate => calculateReviewRate(rate.reviewNum + rate.notCoveredReviewNum, rate.orderNum + rate.notCoveredOrderNum));

		const orderNum = postRates.map(rate => rate.orderNum);
		const notCoveredOrderNum = postRates.map(rate => rate.notCoveredOrderNum);

		const data = {
			labels: period,
			datasets: [
				{
					type: "line",
					label: "特典対象 レビュー投稿率",
					borderColor: "rgb(156, 74, 246)",
					borderWidth: 2,
					tension: 0,
					fill: false,
					data: reviewRate,
					yAxisID: "y2",
				},
				{
					type: "line",
					label: "特典対象外 レビュー投稿率",
					borderColor: "rgb(250, 223, 81)",
					borderWidth: 2,
					tension: 0,
					fill: false,
					data: notCoveredReviewRate,
					yAxisID: "y2",
				},
				{
					type: "line",
					label: "全体 レビュー投稿率",
					borderColor: "rgb(220, 114, 173)",
					borderWidth: 2,
					tension: 0,
					fill: false,
					data: allReviewRate,
					yAxisID: "y2",
				},
				{
					type: "bar",
					label: "特典対象 注文件数",
					backgroundColor: "rgba(50, 91, 147, 1)",
					data: orderNum,
					stack: "Stack 0",
					barPercentage: 0.5,
				},
				{
					type: "bar",
					label: "特典対象外 注文件数",
					backgroundColor: "rgba(117, 164, 215, 1)",
					data: notCoveredOrderNum,
					stack: "Stack 0",
					barPercentage: 0.5,
				},
			],
		};

		return (
			<Chart type='bar' options={options} data={data} />
		);
	}

	// `list` コンポーネント
	const list = () => {
		if (!postRates) {
			return <></>;
		}

		return (
			<table className="bl_table bl_postRateTable">
				<thead className="bl_table_head">
					<tr>
						<th>期間</th>
						<th>特典対象<br />注文数</th>
						<th>特典対象外<br />注文数</th>
						<th>特典対象<br />レビュー数</th>
						<th>特典対象外<br />レビュー数</th>
						<th>特典対象<br />レビュー投稿率</th>
						<th>特典対象外<br />レビュー投稿率</th>
						<th>全体<br />レビュー投稿率</th>
					</tr>
				</thead>
				<tbody className="bl_table_body">
					{postRates.map((rate, index) => {
						// レビュー投稿率を計算
						const reviewRate = calculateReviewRate(rate.reviewNum, rate.orderNum);
						const notCoveredReviewRate = calculateReviewRate(rate.notCoveredReviewNum, rate.notCoveredOrderNum);
						const allReviewRate = calculateReviewRate(rate.reviewNum + rate.notCoveredReviewNum, rate.orderNum + rate.notCoveredOrderNum);

						return (
							<tr key={index}>
								<td>{rate.period}</td>
								<td>{rate.orderNum.toLocaleString()}</td>
								<td>{rate.notCoveredOrderNum.toLocaleString()}</td>
								<td>{rate.reviewNum.toLocaleString()}</td>
								<td>{rate.notCoveredReviewNum.toLocaleString()}</td>
								<td>{reviewRate}%</td>
								<td>{notCoveredReviewRate}%</td>
								<td>{allReviewRate}%</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}

	return (
		<>
			<div>
				<h1 className="el_pageTtl">全体レビュー投稿率</h1>
				<p className="el_pageDesc">指定した期間内の全体レビュー投稿率を表示します。同期間の注文件数も確認できます。</p>
				<div className="bl_row">
					<div className="bl_col bl_col__12 bl_col__mobileFullWidth">
						<div className="bl_panel bl_panel__bt">
							<div className="bl_panel_row">
								<h2 className="el_lv3Heading">表示選択</h2>
								<label className="bl_label">
									<input type="radio" name="reviewType" value="item" ref={register} /><span>商品レビュー</span>
								</label>
								<label className="bl_label">
									<input type="radio" name="reviewType" value="shop" ref={register} /><span>ショップレビュー</span>
								</label>
							</div>
							<div className="bl_panel_row">
								<h2 className="el_lv3Heading">対象の期間設定</h2>
								<label className="bl_label">
									<input type="radio" name="periodType" value="period" ref={register} /><span>表示期間</span>
								</label>
								<br />
								{periodType === 'period' && (
									<div className="bl_panel_row bl_panel_row__indent">
										<select name="selectPeriod" ref={register}>
											{[1, 3, 6, 12, 24].map(month =>
												<option key={month} value={month}>{month}ヶ月</option>
											)}
										</select>
									</div>
								)}
								<label className="bl_label">
									<input type="radio" name="periodType" value="month" ref={register} /><span>年月で期間を指定</span>
								</label>
								<br />
								{periodType === 'month' && (
									<>
										<div className="bl_inputFormWrap ml_26">
											<span>開始日</span>
											<Controller
												control={control}
												rules={{
													validate: (date) => {
														return (periodType != 'month' || date) || '開始日を入力してください';
													}
												}}
												name="monthFrom"
												render={({ onChange, value }) => (
													<ReactDatePicker
														popperClassName="datePickerPopper"
														locale="ja"
														onChange={onChange}
														selected={value}
														dateFormat="yyyy/MM"
														shouldCloseOnSelect={true}
														inline={false}
														showMonthYearPicker />
												)} />
											<FormErrorMessage errors={errors} name="monthFrom" />
										</div>
										<div className="bl_inputFormWrap ml_26">
											<span>終了日</span>
											<Controller
												control={control}
												rules={{
													validate: (date) => {
														if (periodType !== 'month') return true;
														if (!date) return '終了日を入力してください';
														const start = dayjs(getValues('monthFrom'));
														const end = dayjs(date);
														if (start.isAfter(end)) {
															return '終了日は開始日以降の日付を指定してください';
														}

														return true;
													}
												}}
												name="monthTo"
												render={({ onChange, value }) => (
													<ReactDatePicker
														popperClassName="datePickerPopper"
														locale="ja"
														onChange={onChange}
														selected={value}
														dateFormat="yyyy/MM"
														shouldCloseOnSelect={true}
														inline={false}
														showMonthYearPicker />
												)} />
											<FormErrorMessage errors={errors} name="monthTo" />
										</div>
									</>
								)}
								<label className="bl_label">
									<input type="radio" name="periodType" value="day" ref={register} /><span>年月日で期間を指定</span>
								</label>
								<br />
								{periodType === 'day' && (
									<>
										<div className="bl_inputFormWrap ml_26 mr_26">
											<span>開始日</span>
											<Controller
												control={control}
												rules={{
													validate: (date) => {
														return (periodType != 'day' || date) || '開始日を入力してください';
													}
												}}
												name="dayFrom"
												render={({ onChange, value }) => (
													<ReactDatePicker
														popperClassName="datePickerPopper"
														locale="ja"
														onChange={onChange}
														selected={value}
														dateFormat="yyyy/MM/dd"
														shouldCloseOnSelect={true}
														inline={false}
													/>
												)} />
											<FormErrorMessage errors={errors} name="dayFrom" />
										</div>
										<div className="bl_inputFormWrap ml_26">
											<span>終了日</span>
											<Controller
												control={control}
												rules={{
													validate: (date) => {
														if (periodType !== 'day') return true;
														if (!date) return '終了日を入力してください';
														const startDate = getValues('dayFrom');
														if (startDate) {
															const start = dayjs(startDate);
															const end = dayjs(date);
															if (start.isAfter(end)) {
																return '終了日は開始日以降の日付を指定してください';
															}

															// 期間が31日を超える場合のエラー
															const daysBetween = end.diff(start, 'days') + 1;
															if (daysBetween > 31) {
																return '期間は31日以内で指定してください';
															}
														}
														return true;
													}
												}}
												name="dayTo"
												render={({ onChange, value }) => (
													<ReactDatePicker
														popperClassName="datePickerPopper"
														locale="ja"
														onChange={onChange}
														selected={value}
														dateFormat="yyyy/MM/dd"
														shouldCloseOnSelect={true}
														inline={false}
													/>
												)} />
											<FormErrorMessage errors={errors} name="dayTo" />
										</div>
									</>
								)}
							</div>
							<div className="bl_panel_headerFooter">
								<button className="el_btnInv" type="button" onClick={handleClickTerm} disabled={loading}>設定</button>
								<button className="el_btn mr_8" type="button" onClick={handleTransact} disabled={loading}>CSVダウンロード</button>
							</div>

							{chart()}
							{list()}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default PostRate;